import { Button, Container, Grid, Link, Typography } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import SellIcon from "@mui/icons-material/Sell";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LoadingButton from "@mui/lab/LoadingButton";
import PaymentsIcon from "@mui/icons-material/Payments";
import AuthService from "../../Layouts/Login/services";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
import ClientRequests from "../../Api/ClientRequests";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "3px solid #0DEDA3",
  boxShadow: "1px 1px 8px #c8c8c8",
  background: "#fff",
  overflow: "auto",
}));

const ContratView = ({ data, sender }) => {
  const currentUser = AuthService.getCurrentUser() || {};
  const navigate = useNavigate();
  const [accepted, setAccepted] = React.useState(data?.paid);
  const [open, setOpen] = React.useState(false);
  const handlePay = (username) => {};
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteContrat = (id) => {
    setOpen(true);
    ClientRequests.delete_token(
      "home/message_single_contrat/" + id + "/",
      currentUser.auth_token
    ).then((response) => console.log(response));
    const timer = setTimeout(() => {
      setOpen(false);
    }, 3000);
    return () => clearTimeout(timer);
  };
  return (
    <Container
      sx={{
        mt: 2,
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container component={Item}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ color: "rgba(0,0,0,0.4)" }}>
            {data?.title}
          </Typography>
          <Typography variant="subtitle2">{data?.description}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Item>
            <Container>
              <Grid
                container
                sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
              >
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <LanguageIcon htmlColor="rgb(0, 192, 217)" />{" "}
                    <Typography
                      component="span"
                      sx={{ fontWeight: 800, ml: 1 }}
                    >
                      Cible
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  {data?.pls.map((item) => (
                    <img
                      src={global.config.infos.image_url + item.logo}
                      width="30"
                      height="30"
                      style={{ margin: "5px" }}
                    />
                  ))}
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
              >
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <PlaceIcon htmlColor="rgb(0, 192, 217)" />{" "}
                    <Typography
                      component="span"
                      sx={{ fontWeight: 800, ml: 1 }}
                    >
                      Lieu
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  <Typography>{data.ville}</Typography>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
              >
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <HourglassBottomIcon htmlColor="rgb(0, 192, 217)" />{" "}
                    <Typography
                      component="span"
                      sx={{ fontWeight: 800, ml: 1 }}
                    >
                      Échéance
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  <Typography>{moment(data.date_fin).format("LL")}</Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mb: "15px", pb: "15px" }}>
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <SellIcon htmlColor="rgb(0, 192, 217)" />{" "}
                    <Typography
                      component="span"
                      sx={{ fontWeight: 800, ml: 1 }}
                    >
                      Budget
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  <Typography>
                    {/* {data.tva === true
                      ? (data.budget * (100 + 20)) / 100 + " € (TTC)"
                      : data.budget + " € (TTC)"} */}
                    {data.budget}
                    {data.tva === true ? (
                      <small>€ (HT)</small>
                    ) : (
                      <small>€ (TTC)</small>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Item>
        </Grid>
        <Grid container justifyContent="flex-end" sx={{ textAlign: "right" }}>
          <Grid
            item
            xs={12}
            md={4}
            justifyContent="flex-end"
            sx={{ textAlign: "right" }}
          >
            {currentUser?.userinfos?.username != sender ? (
              <>
                {accepted ? (
                  <Box sx={{ mt: 2, textAlign: "right" }}>
                    Paiement effectué
                  </Box>
                ) : (
                  <Button
                    onClick={() => navigate("/contrat/" + data.id)}
                    variant="contained"
                    sx={{
                      background: "#0DEDA3",
                      textDecoration: "none",
                      p: 1,
                      mt: 3,
                      fontWeight: 900,
                      width: "100%",
                    }}
                  >
                    Payer
                  </Button>
                )}
              </>
            ) : (
              <>
                {accepted ? (
                  <Box sx={{ mt: 2, textAlign: "right" }}>
                    Paiement effectué
                  </Box>
                ) : (
                  <>
                    <Box sx={{ mt: 2, textAlign: "right" }}>
                      En attente du paiement
                    </Box>
                    <Button
                      variant="contained"
                      sx={{
                        background: "#0DEDA3",
                        textDecoration: "none",
                        p: 1,
                        mt: 3,
                        fontWeight: 900,
                        minWidth: "60%",
                      }}
                      onClick={() => handleDeleteContrat(data.id)}
                    >
                      Annuler
                    </Button>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContratView;
