import React from "react";
import { useParams } from "react-router-dom";
import Edit from "./Influencer/Edit";
import MarqueEdit from "./Marque/MarqueEdit";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import Box from "@mui/material/Box";
import AuthService from "../Layouts/Login/services";
const EdituserProfile = () => {
  const [utilisateur, setUtilisateur] = React.useState(true);

  const { pagename } = useParams();
  const currentUser = AuthService.getCurrentUser();
  return (
    <React.Fragment>
      <Header onUserChange={setUtilisateur} />
      <Box
        sx={{
          marginTop: { md: "100px", xs: "50px" },
          display: { md: "block", xs: "none" },
        }}
      />
      {pagename === "influenceur" && (
        <>{currentUser.profileinfos.role === "Influenceur" && <Edit />}</>
      )}

      {pagename === "marque" && (
        <>{currentUser.profileinfos.role === "Marque" && <MarqueEdit />}</>
      )}

      {pagename === "marque" && (
        <>{currentUser.profileinfos.role === "Particulier" && <MarqueEdit />}</>
      )}

      <Footer />
    </React.Fragment>
  );
};

export default EdituserProfile;
