import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Avatar as Image } from "@mui/material";
import AuthService from "../../../Layouts/Login/services";
import ClientRequests from "../../../Api/ClientRequests";
import Button from "@mui/material/Button";
import AvatarEditor from "react-avatar-editor";
import Slider from "@mui/material/Slider";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
const Avatar = ({ profile, setNextStep }) => {
  const currentUser = AuthService.getCurrentUser();
  var editor = "";
  const [display, setDisplay] = React.useState(false);
  const [picture, setPicture] = React.useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: global.config.infos.image_url + profile.avatar,
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      ClientRequests.put_token("user/profile/", currentUser.auth_token, {
        avatar: croppedImg,
        user: currentUser.userinfos.id,
      }).then((response) => {
        if (response.ok === true) {
          setPicture({
            ...picture,
            img: null,
            cropperOpen: false,
            croppedImg: croppedImg,
          });
          setNextStep((prev) => prev + 1);
        } else {
          console.log(response);
        }
      });
    }
  };

  const handleFileChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    });
  };

  const handleUpload = (data) => {
    console.log(data);
  };
  return (
    <>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Item sx={{ textAlign: "center" }}>
          <Image
            alt={profile.first_name.toUpperCase()}
            src={picture.croppedImg}
            sx={{ m: "auto", mb: 2, width: 100, height: 100 }}
          />
          <>
            <input type="file" accept="image/*" onChange={handleFileChange} />

            {picture.cropperOpen && (
              <Box
                display="block"
                sx={{ maxWidth: "400px", margin: "auto", mt: 3, mb: 3 }}
              >
                <AvatarEditor
                  ref={setEditorRef}
                  image={picture.img}
                  width={200}
                  height={200}
                  border={50}
                  color={[255, 255, 255, 0.6]} // RGBA
                  rotate={0}
                  scale={picture.zoom}
                />
                <br />
                <Typography variant="body1" sx={{ m: 2 }}>
                  Zoom
                </Typography>
                <Slider
                  aria-label="raceSlider"
                  value={picture.zoom}
                  min={1}
                  max={10}
                  step={0.1}
                  onChange={handleSlider}
                ></Slider>
                <Box>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "rgb(0, 192, 217)", mr: 2 }}
                    onClick={handleCancel}
                  >
                    Annuler
                  </Button>
                  <Button
                    onClick={handleSave}
                    variant="contained"
                    sx={{ backgroundColor: "rgb(0, 192, 217)" }}
                  >
                    Enregistrer
                  </Button>
                </Box>
              </Box>
            )}
          </>
        </Item>
      </Grid>
    </>
  );
};

export default Avatar;
