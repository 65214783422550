import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import SellIcon from "@mui/icons-material/Sell";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
const Net = ({ contrat }) => {
  return (
    <Box
      sx={{
        padding: "5px",
        border: "2px solid #d1d1d1",
        borderRadius: "15px",
        width: "fit-content",
      }}
    >
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          columnSpacing={2}
        >
          <Grid item sx={12} md={2}>
            <SellIcon
              sx={{
                color: "#00C0D9",
              }}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography textAlign="center">Budget</Typography>
            <Typography textAlign="center">{contrat.toFixed(2)} €</Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Net;
