import { Button, Container, Grid, Link, Typography } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import SellIcon from "@mui/icons-material/Sell";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LoadingButton from "@mui/lab/LoadingButton";
import PaymentsIcon from "@mui/icons-material/Payments";
import AuthService from "../../Layouts/Login/services";
import { useNavigate } from "react-router-dom";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "2px solid #0DEDA3",
  background: "#fff",

  overflow: "auto",
}));

const Contrat = ({ data }) => {
  const currentUser = AuthService.getCurrentUser() || {};
  const navigate = useNavigate();
  const [accepted, setAccepted] = React.useState(false);
  const handlePay = (username) => {};
  console.log(data);
  return (
    <Container sx={{ p: "0px !important", mb: 2 }}>
      <Grid
        container
        component={Item}
        sx={{ boxShadow: "1px 1px 2px #c8c8c8" }}
      >
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ color: "rgba(0,0,0,0.4)" }}>
            {data?.title}
          </Typography>
          <Typography variant="subtitle2">{data?.description}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Item sx={{ borderColor: "#ddd" }}>
            <Container>
              <Grid
                container
                sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
              >
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <LanguageIcon htmlColor="rgb(0, 192, 217)" />{" "}
                    <Typography
                      component="span"
                      sx={{ fontWeight: 800, ml: 1 }}
                    >
                      Cible
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  {data?.pls?.map((item) => (
                    <img
                      src={global.config.infos.image_url + item.logo}
                      width="30"
                      height="30"
                      style={{ margin: "5px" }}
                    />
                  ))}
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
              >
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <PlaceIcon htmlColor="rgb(0, 192, 217)" />{" "}
                    <Typography
                      component="span"
                      sx={{ fontWeight: 800, ml: 1 }}
                    >
                      Lieu
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  <Typography>{data?.ville}</Typography>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
              >
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <HourglassBottomIcon htmlColor="rgb(0, 192, 217)" />{" "}
                    <Typography
                      component="span"
                      sx={{ fontWeight: 800, ml: 1 }}
                    >
                      Échéance
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  <Typography>{data?.date_fin}</Typography>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
              >
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <SellIcon htmlColor="rgb(0, 192, 217)" />{" "}
                    <Typography
                      component="span"
                      sx={{ fontWeight: 800, ml: 1 }}
                    >
                      Budget <small>(HT)</small>
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  <Typography>{data?.budget?.toFixed(2)}€ </Typography>
                </Grid>
              </Grid>
            </Container>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contrat;
