import React from "react";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import { useParams, useNavigate } from "react-router-dom";
import Complete from "./Influencer/Complete";
import Uncomplete from "./Influencer/Uncomplete";
import Marque from "./Marque/Marque";
import AuthService from "../../Components/Layouts/Login/services";
import InfluencerService from "../Api/Influencer";
import { ConfirmProvider } from "material-ui-confirm";

const Profile = () => {
  const navigate = useNavigate();
  const [complete, SetComplete] = React.useState(null);
  const currentUser = AuthService.getCurrentUser();
  const [utilisateur, setUtilisateur] = React.useState(null);

  let { pagename } = useParams();
  React.useEffect(() => {
    if ("user" in localStorage) {
      setUtilisateur(JSON.parse(localStorage.getItem("user")));
    }
    InfluencerService.fetchData(
      "influencers/hasplatform/",
      currentUser.auth_token
    )
      .then((response) => {
        console.log("respo", response);
        if (response.answer === true) {
          SetComplete(true);
        } else {
          SetComplete(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
      <Header onUserChange={setUtilisateur} />

      {(pagename === "influenceur") &
      (currentUser?.profileinfos?.role === "Influenceur") &
      (complete !== null) ? (
        complete === true ? (
          <Complete />
        ) : (
          <Uncomplete />
        )
      ) : (
        <></>
      )}

      {pagename === "marque" ? (
        <ConfirmProvider>
          <Marque />
        </ConfirmProvider>
      ) : (
        <></>
      )}

      <Footer />
    </React.Fragment>
  );
};

export default Profile;
