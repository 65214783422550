import { Container, Grid, Box, Typography } from "@mui/material";
import React from "react";

const Recap = ({ data, success }) => {
  return (
    <>
      {data != undefined && (
        <Container>
          {!success && (
            <>
              <Grid
                container
                sx={{
                  borderBottom: "5px solid #ddd",
                  mb: "15px",
                  pb: "15px",
                }}
              >
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography component="h4" sx={{ fontWeight: 800 }}>
                      Budget
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  <Typography>
                    {data?.budget.toFixed(2)}€{" "}
                    {data?.tva === true ? "(HT)" : "(TTC)"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
              >
                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <Typography component="h4" sx={{ fontWeight: 800 }}>
                      Frais
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ alignSelf: "center", textAlign: "right" }}
                >
                  <Typography>
                    {data.tva === true ? (
                      <>
                        {" "}
                        {(data?.budget * 1.2 * (1.5 / 100) + 0.25).toFixed(2)}€
                        (TTC)
                      </>
                    ) : (
                      <>
                        {(data?.budget * (1.5 / 100) + 0.25).toFixed(2)}€ (TTC)
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
              >
                {data.tva === true && (
                  <>
                    <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                      <Box sx={{ display: "flex" }}>
                        <Typography component="h4" sx={{ fontWeight: 800 }}>
                          TVA (20%)
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ alignSelf: "center", textAlign: "right" }}
                    >
                      <Typography>
                        <>{(data?.budget * 0.2).toFixed(2)}€</>
                      </Typography>
                    </Grid>
                  </>
                )}

                {data.tva === false && (
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "10px", color: "red" }}
                  >
                    Facture sans TVA (franchise de TVA) La mention : “TVA non
                    applicable, art. 293 B du CGI”.
                  </Typography>
                )}
              </Grid>
            </>
          )}

          <Grid
            container
            sx={{ borderBottom: "5px solid #ddd", mb: "15px", pb: "15px" }}
          >
            <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
              <Box sx={{ display: "flex" }}>
                <Typography component="h4" sx={{ fontWeight: 800 }}>
                  {success ? <> Paiement effectué</> : <> Net à payé</>}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ alignSelf: "center", textAlign: "right" }}
            >
              <Typography>
                {data.tva === true ? (
                  <> {(data?.budget * 1.2 * 1.015 + 0.25).toFixed(2)}€ (TTC)</>
                ) : (
                  <>{(data?.budget * 1.015 + 0.25).toFixed(2)}€ (TTC)</>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default Recap;
