import * as React from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";

import Header from "../Layouts/Header/Header";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClientRequests from "../Api/ClientRequests";
import AuthService from "../Layouts/Login/services";
import Avatar from "@mui/material/Avatar";
import Users from "./Elements/Users";
import Message from "./Elements/Message";
import ReceiverInfo from "./Elements/ReceiverInfo";
import "./Elements/messages.css";
import FileUpload from "react-material-file-upload";
import Rate from "../ProfilePage/Influencer/Parts/Rate";
import Contrat from "./Elements/Contrat";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OrderList from "./Elements/OrderList";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const drawerWidth = 360;

const fileToDataUri = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
const Messages = () => {
  const navigate = useNavigate();
  const messagesBoxRef = React.useRef(null);
  let { username } = useParams();
  const [items, setItems] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [msgcounter, setMsgCounter] = React.useState(0);
  const [users, setUsers] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const [receiver, setReceiver] = React.useState(null);
  const [sent, setSent] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [display, setDisplay] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openContrat, setOpenContrat] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);
  const [meaccepted, setMeAccepted] = React.useState(false);
  const [utilisateur, setUtilisateur] = React.useState(null);
  const currentUser = AuthService.getCurrentUser();
  const [loading, setLoading] = React.useState(false);
  const [orderlist, setOrderList] = React.useState(null);
  const [errors, setErrors] = React.useState({
    rating: "",
    review: "",
  });

  const [snackbaropen, setSnackBarOpen] = React.useState({
    open: false,
    type: "success",
    text: "",
  });

  const handleMessageSend = async (mssg) => {
    setMeAccepted(false);
    setMessage("");
    const msg = await ClientRequests.post_token(
      "home/message/" + username + "/",
      currentUser.auth_token,
      mssg
    );

    if (msg.ok === true) {
      const data = await msg.json();

      if (files !== undefined && files.length > 0) {
        files.map((f) => {
          fileToDataUri(f).then((dataUri) => {
            ClientRequests.post_token(
              "home/message_files/",
              currentUser.auth_token,
              { message: data.id, file: dataUri }
            ).then((file) => {
              if (file.ok === true) {
                setMeAccepted(true);

                console.log("Yes");
              } else {
                setMeAccepted(true);
                console.log(file);
              }
            });
          });
        });
      }
      setDisplay(false);
      setFiles([]);
      setSent(!sent);
    } else {
      console.log(msg);
    }
  };

  const toggleDisplay = () => {
    setDisplay(!display);
  };
  const [MessageProposition, setMessageProposition] = React.useState(null);
  const getMessageProposition = async (username) => {
    try {
      const data = await ClientRequests.get_token(
        "home/message_proposition/" + username + "/",
        currentUser.auth_token
      );
      setMessageProposition(data);
      setAccepted(data?.accepted);
      setMeAccepted(data?.accepted);
    } catch (error) {
      navigate("/profile/influenceur/" + username);
    }
  };

  React.useEffect(() => {
    if (username != undefined) {
      getMessageProposition(username);
      ClientRequests.get_token(
        "home/message/" + username + "/",
        currentUser.auth_token
      ).then((response) => {
        setData(response);
      });
      ClientRequests.get_token(
        "home/orderlisiting/" + username + "/pending/",
        currentUser.auth_token
      ).then((response) => {
        setOrderList(response);
      });
      ClientRequests.get_token(
        "user/profile/" + username + "/",
        currentUser.auth_token
      ).then((response) => setReceiver(response));
    }
    ClientRequests.get_token(
      "home/message_users/",
      currentUser.auth_token
    ).then((response) => setUsers(response));
  }, [username, sent, accepted, meaccepted]);

  React.useEffect(() => {
    if (messagesBoxRef.current) {
      messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
    }
    const interval = setInterval(() => {
      ClientRequests.get_token(
        "home/message_users/",
        currentUser.auth_token
      ).then((response) => setUsers(response));
      if (username != undefined) {
        ClientRequests.get_token(
          "home/message/" + username + "/",
          currentUser.auth_token
        ).then((response) => {
          setData(response);
        });
      }
    }, 3000);

    if (data !== null && msgcounter !== data.length) {
      const elem = document.getElementById("infinite-scroll-wrapper");
      elem?.scroll({ top: elem.scrollHeight, behavior: "smooth" });
      setMsgCounter(data.length);
    }
    if (username === undefined && users !== null && users?.length > 0) {
      navigate("/messages/" + users[0]?.username);
    }
    return () => clearInterval(interval);
  });

  const handleRate = async (data) => {
    data = { ...data, receiver: receiver.id };
    try {
      const rat = await ClientRequests.post_token(
        "influencers/rating/",
        currentUser.auth_token,
        data
      );
      if (rat.ok) {
        setSnackBarOpen({
          open: true,
          type: "success",
          text: "le review a bien été ajouter",
        });
      } else {
        const response = await rat.json();
        if ("validity" in response) {
          setSnackBarOpen({
            open: true,
            type: "error",
            text: response.validity,
          });
        } else {
          setSnackBarOpen({
            open: true,
            type: "error",
            text: "Un erreur est survenu, vous pouvez repeter ultérieurement",
          });
        }
      }
    } catch (err) {
      console.log(err);
    }

    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSneackbarClose = () => {
    setSnackBarOpen(false);
  };

  const [values, setValues] = React.useState({});
  const handleAddContrat = (value) => {
    setLoading(true);
    ClientRequests.post_token(
      "home/message_contrat/" + username + "/",
      currentUser.auth_token,
      value
    )
      .then((result) => {
        if (result.ok === true) {
          setOpenContrat(false);
          setValues({});
          return false;
        }
        return result.json();
      })
      .then((response) => {
        if (openContrat === true) {
          setErrors({ ...errors, contrat: { ...errors?.contrat, response } });
        }
      });

    setLoading(false);
  };
  const handleCloseContrat = () => {
    setOpenContrat(false);
  };
  const [payout, setPayout] = React.useState(false);
  const handleOpenContrat = () => {
    console.log(1);
    ClientRequests.post_token(
      "home/stripe/account_details/",
      currentUser.auth_token
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.details_submitted === true) {
          setOpenContrat(!open);
        } else {
          setPayout(true);
        }
      });
  };
  const handlePayout = () => {
    ClientRequests.post_token("home/stripe/payout/", currentUser.auth_token, {})
      .then((result) => result.json())
      .then((response) => {
        window.location = response.url;
      });
  };
  return (
    <React.Fragment>
      <Rate
        handleRate={handleRate}
        profile={receiver}
        errors={errors}
        handleClose={handleClose}
        open={open}
      />
      {currentUser?.profileinfos?.role === "Influenceur" && (
        <Contrat
          handleCloseContrat={handleCloseContrat}
          handleAddContrat={handleAddContrat}
          loading={loading}
          errors={errors}
          openContrat={openContrat}
          values={values}
          setValues={setValues}
        />
      )}

      <CssBaseline />
      <Header onUserChange={setUtilisateur} />
      <Snackbar
        open={snackbaropen.open}
        autoHideDuration={3000}
        onClose={handleSneackbarClose}
      >
        <Alert severity={snackbaropen.type} sx={{ width: "100%" }}>
          {snackbaropen.text}
        </Alert>
      </Snackbar>
      <Container
        maxWidth={false}
        sx={{
          height: { md: "100%", xs: "84%" },
          pl: "0px !important",
          pr: "0px !important",
          pt: { md: "75px", xs: "0px" },
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            background: "#fdfdfd",
            poistion: "relative",
            p: 0,
          }}
        >
          <Grid
            item
            xs={2}
            sx={{
              border: "1px solid #ddd",
              background: "#fff",
              height: "100%",
              overflow: "auto",
            }}
          >
            <List>
              {users !== false && (
                <>
                  {users?.map((user, index) => (
                    <Users key={index} user={user} username={username} />
                  ))}
                </>
              )}
            </List>
          </Grid>
          <Grid
            item
            md={7}
            xs={10}
            sx={{
              border: "1px solid #ddd",
              height: "100%",
              position: "relative",
            }}
          >
            {receiver != null && (
              <Toolbar
                sx={{
                  position: "absolute",
                  right: 0,
                  left: 0,
                  boxShadow: "0px 0px 4px #ddd",
                  zIndex: "100",
                  display: { xs: "flex", md: "none" },
                  backgroundColor: "#fff",
                }}
              >
                <Container>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                  >
                    <Grid item>
                      <Typography
                        sx={{ fontWeight: "900", cursor: "pointer" }}
                        onClick={
                          receiver?.role === "Influenceur"
                            ? () => navigate("/profile/influenceur/" + username)
                            : () => navigate("/profile/marque/" + username)
                        }
                      >
                        {receiver?.role === "Marque" ? (
                          <>{receiver?.company}</>
                        ) : (
                          <>
                            {receiver?.first_name} {receiver?.last_name}
                          </>
                        )}
                      </Typography>
                    </Grid>

                    {currentUser?.profileinfos?.role === "Influenceur" && (
                      <>
                        <Grid item>
                          <Button
                            onClick={handleOpenContrat}
                            variant="contained"
                            disabled={payout}
                            sx={{
                              backgroundColor: "rgb(0, 192, 217)",
                              ml: 2,

                              padding: "4px",
                              minWidth: "100px",
                              position: "inherit",
                              fontSize: "12px",
                            }}
                          >
                            Contrat
                          </Button>
                        </Grid>
                        {payout === true && (
                          <Grid item xs={12}>
                            <Button
                              onClick={handlePayout}
                              variant="contained"
                              sx={{
                                backgroundColor: "rgb(0, 192, 217)",
                                mt: 2,
                                padding: "4px",
                                width: "100%",
                                position: "inherit",
                                fontSize: "12px",
                              }}
                            >
                              Compte stripe
                            </Button>
                            <Typography
                              sx={{
                                color: "red",
                                mt: 1,
                                mb: 1,
                                fontSize: "11px",
                              }}
                            >
                              Veuillez configurer votre compte stripe avant la
                              creation du contrat
                            </Typography>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Container>
              </Toolbar>
            )}
            {username === undefined ? (
              <Toolbar
                sx={{
                  position: "absolute",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                  boxShadow: "0px 0px 4px #ddd",
                  zIndex: "100",
                  textAlign: "center",
                }}
              >
                {username === undefined && (
                  <>
                    {users?.length === 0 ? (
                      <Typography sx={{ width: "-webkit-fill-available" }}>
                        <strong>Aucun message</strong>
                        <br /> Les nouveaux messages apparaîtront ici...
                      </Typography>
                    ) : (
                      <Box sx={{ display: "flex", margin: "auto" }}>
                        <CircularProgress />
                      </Box>
                    )}
                  </>
                )}
              </Toolbar>
            ) : (
              <Message
                data={data}
                MessageProposition={MessageProposition}
                className="messagescroll"
                username={username}
                accepted={accepted}
                setAccepted={setAccepted}
              />
            )}

            {username !== undefined && (
              <Box
                component="main"
                sx={{
                  bgcolor: "#fff",
                  boxShadow: "0px 0px 3px #ddd",
                  padding: "0px 24px",
                  position: "absolute",
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <TextField
                  id="standard-multiline-static"
                  label="Message"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleMessageSend(message);
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  value={message}
                  defaultValue={message}
                  multiline
                  disabled={!meaccepted}
                  //   helperText={errors.password}
                  onInput={(e) => setMessage(e.target.value)}
                  rows={3}
                  sx={{ width: "100%" }}
                  variant="standard"
                />
                {meaccepted && (
                  <Box sx={{ width: "100%", textAlign: "end" }}>
                    <IconButton aria-label="send" onClick={toggleDisplay}>
                      <AttachFileIcon />
                    </IconButton>
                    <IconButton
                      aria-label="send"
                      onClick={(e) => handleMessageSend(message)}
                    >
                      <SendIcon />
                    </IconButton>
                  </Box>
                )}
                {display && <FileUpload value={files} onChange={setFiles} />}
              </Box>
            )}
          </Grid>
          <Grid
            item
            md={3}
            sx={{
              border: "1px solid #ddd",
              height: "100%",
              display: { md: "block", xs: "none" },
            }}
          >
            {username !== undefined && (
              <>
                <Divider />
                <Box sx={{ p: 1 }}>
                  <ReceiverInfo receiver={receiver} />
                  {/* {orderlist !== null && (
                    <>
                      {orderlist.map((item) => (
                        <OrderList orderlist={item} />
                      ))}
                    </>
                  )} */}

                  {/* <Button
                    variant="contained"
                    sx={{ backgroundColor: "rgb(0, 192, 217)", width: "100%" }}
                    onClick={() => setOpen(!open)}
                  >
                    Avis
                  </Button> */}
                  {currentUser?.profileinfos?.role === "Influenceur" && (
                    <>
                      {accepted && (
                        <>
                          <Button
                            variant="contained"
                            disabled={payout}
                            sx={{
                              backgroundColor: "rgb(0, 192, 217)",
                              width: "100%",
                              mt: 2,
                            }}
                            onClick={handleOpenContrat}
                          >
                            Contrat
                          </Button>
                          {payout === true && (
                            <>
                              <Button
                                onClick={handlePayout}
                                variant="contained"
                                sx={{
                                  backgroundColor: "rgb(0, 192, 217)",
                                  width: "100%",
                                  mt: 2,
                                }}
                              >
                                Compte stripe
                              </Button>
                              <Typography
                                sx={{
                                  color: "red",
                                  mt: 1,
                                  mb: 1,
                                  fontSize: "11px",
                                }}
                              >
                                Veuillez configurer votre compte stripe avant la
                                creation du contrat
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Messages;
