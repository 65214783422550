import React from "react";
import moment from "moment";
import "moment/locale/fr";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import DiscountIcon from "@mui/icons-material/Discount";

import LanguageIcon from "@mui/icons-material/Language";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import AuthService from "../../Layouts/Login/services";
import ClientRequests from "../../Api/ClientRequests";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "15px",
  boxShadow: "1px 1px 6px #bdbdbd",
  border: "3px solid #0DEDA3",
  background: "#fcfafa",
  overflow: "auto",
}));

const PropositionMessage = ({
  proposition,
  data,
  username,
  setAccepted,
  accepted,
}) => {
  const currentUser = AuthService.getCurrentUser();
  console.log(currentUser);
  const [loading, setLoading] = React.useState(false);
  const handleAccept = (username) => {
    setLoading(true);
    ClientRequests.patch_token(
      "home/message_proposition/" + username + "/",
      currentUser.auth_token,
      {
        id: proposition.id,
        accepted: true,
      }
    ).then((res) => {
      if (res.ok) {
        setAccepted((prev) => !prev);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  return (
    <>
      <Item sx={{ p: 2, mt: 2 }}>
        <Typography
          variant="h6"
          sx={{
            p: "0px 10px",
            fontWeight: 900,
            fontSize: { md: "18px", xs: "14px" },
          }}
        >
          Proposition de collaboration
        </Typography>
        <Box
          sx={{
            background: "#fdfdfd",
            border: "2px solid #ddd",
            borderRadius: "15px",
            p: 3,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "800", mb: 3 }}>
            {proposition?.title}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "justify", pb: 5 }}>
            {proposition?.description}
          </Typography>
          <Grid
            container
            xs={12}
            sx={{
              textAlign: "center",
              gap: "10px",
            }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                fontSize: { xs: "0.7rem", md: "0.875rem" },
                background: "#fdfdfd",
                border: "2px solid #ddd",
                borderRadius: "15px",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <DiscountIcon
                    sx={{
                      color: "rgb(0, 192, 217)",
                      fontSize: { xs: "1rem", md: "1.5rem" },
                      mt: 1,
                      marginBottom: "-10px",
                    }}
                  />
                  <Typography>Budget</Typography>
                </Grid>
                <Grid item xs={6} sx={{ pt: 2 }}>
                  <strong style={{ fontWeight: "bolder", color: "black" }}>
                    {proposition?.price} €
                  </strong>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sx={{
                fontSize: { xs: "0.7rem", md: "0.875rem" },
                background: "#fdfdfd",
                border: "2px solid #ddd",
                borderRadius: "15px",
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <LanguageIcon
                    sx={{
                      color: "rgb(0, 192, 217)",
                      fontSize: { xs: "1rem", md: "1.5rem" },
                      marginBottom: "-10px",
                      mt: 1,
                    }}
                  />
                  <Typography>Cible</Typography>
                </Grid>
                <Grid item xs={6} sx={{ pt: 2 }}>
                  {proposition?.pls?.map((pl) => (
                    <img
                      src={global.config.infos.image_url + pl.logo}
                      alt={pl.name}
                      style={{ width: "20px", marginRight: "4px" }}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {currentUser?.profileinfos?.id != proposition?.sender ? (
          <Box sx={{ textAlign: "right" }}>
            <LoadingButton
              endIcon={!accepted && <SendIcon />}
              loading={loading}
              disabled={accepted}
              loadingPosition="end"
              onClick={() => handleAccept(username)}
              variant="contained"
              sx={{
                background: "#0DEDA3",
                mt: 3,
              }}
            >
              {accepted ? "Acceptée" : "Lancer la discussion"}
            </LoadingButton>
          </Box>
        ) : accepted ? (
          <Box sx={{ mt: 2, textAlign: { md: "right", xs: "center" } }}>
            Vous pouvez maintenant commencer la discussion
          </Box>
        ) : (
          <Box sx={{ mt: 2, textAlign: "right" }}>En attente d'acceptation</Box>
        )}
      </Item>
    </>
  );
};

export default PropositionMessage;
