import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ClientRequests from "../../Api/ClientRequests";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AuthService from "../../Layouts/Login/services";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";

const Item = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const aboones = [
  { id: 1, title: "0-1k" },
  { id: 2, title: "1k-10k" },
  { id: 3, title: "10k-50k" },
  { id: 4, title: "50k-100k" },
  { id: 5, title: "100k-500k" },
  { id: 6, title: "+500k" },
];

export default function Filter({
  input,
  setInput,
  infpage,
  handlefilterInput,
}) {
  const currentUser = AuthService.getCurrentUser();

  const [anchorEl, setAnchorEl] = React.useState([false, -1]);

  const [categories, setCategories] = React.useState(null);
  const [platforms, setPlatforms] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [searching, setSearching] = React.useState([]);
  const [display, setDisplay] = React.useState(true);
  React.useEffect(() => {
    ClientRequests.get_("influencers/categories/").then((result) => {
      setCategories(result);
    });
    ClientRequests.get_("home/platforms/").then((result) => {
      setPlatforms(result);
    });
  }, [checked]);

  const handleDisplay = () => {
    setDisplay((prev) => !prev);
  };

  const handleClose = () => {
    if (anchorEl[0] === true) {
      setAnchorEl([false, -1]);
    }
  };
  return (
    <Container className="filters">
      {display === true ? (
        <Grid
          container
          lg={10}
          md={12}
          justifyContent="center"
          sx={{
            border: " 1px solid #0DEDA3",
            borderRadius: { xs: "30px", md: "50px" },
            margin: "auto",
            p: { xs: 2, md: "5px" },
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              mb: { xs: 2, md: 0 },
              p: "2px",
            }}
          >
            <Autocomplete
              multiple
              limitTags={1}
              sx={{
                background: "#eee !important",
                borderRight: "1px solid #ddd",
                borderRadius: "30px",
                textAlign: { xs: "center", md: "left" },
              }}
              forcePopupIcon={false}
              id="size-small-standard-multi"
              size="small"
              fullWidth={true}
              options={platforms || []}
              onChange={(e, v) => handlefilterInput({ ...input, platform: v })}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  focused
                  label="Plateformes"
                  placeholder="Choisir"
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sx={{
              mb: { xs: 2, md: 0 },
              p: "2px",
            }}
          >
            {(currentUser?.profileinfos.role === "Influenceur") &
            (infpage === false) ? (
              <TextField
                id="size-small-standard-multi"
                size="small"
                type="search"
                fullWidth={true}
                sx={{
                  background: "#eee !important",
                  borderRight: "1px solid #ddd",
                  borderRadius: "30px",
                  textAlign: { xs: "center", md: "left" },
                }}
                focused
                label="Ville"
                placeholder="Saisissez une ville..."
                onInput={(e) =>
                  handlefilterInput({ ...input, ville: e.target.value })
                }
                variant="standard"
              />
            ) : (
              <Autocomplete
                multiple
                limitTags={1}
                forcePopupIcon={false}
                id="size-small-standard-multi"
                size="small"
                sx={{
                  background: "#eee !important",
                  borderRight: "1px solid #ddd",
                  borderRadius: "30px",
                  textAlign: { xs: "center", md: "left" },
                }}
                fullWidth={true}
                options={categories || []}
                getOptionLabel={(option) => option?.name}
                onChange={(e, v) =>
                  handlefilterInput({ ...input, categories: v })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    focused
                    label="Catégories"
                    placeholder="Choisir"
                  />
                )}
              />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            sx={{
              mb: { xs: 1, md: 0 },
              p: "2px",
            }}
          >
            <Autocomplete
              multiple={false}
              id="size-small-standard-multi"
              size="small"
              fullWidth={true}
              sx={{
                background: "#eee !important",
                borderRight: "1px solid #ddd",
                borderRadius: "30px",
                textAlign: { xs: "center", md: "left" },
              }}
              forcePopupIcon={false}
              options={aboones}
              getOptionLabel={(option) => option?.title}
              onChange={(e, v) => handlefilterInput({ ...input, abonnes: v })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Abonnés"
                  focused
                  placeholder="Choisir"
                />
              )}
            />
          </Grid>

          {/* {filterbutton.map((item, key) => (
            <FilterButtons key={key} item={item} onclick={handleClick} />
          ))} */}

          {/* <Item
            sx={{
              backgroundColor: "rgb(255 255 255 / 0%)",
              width: { xs: "100%", md: "10%" },
            }}
          > */}
          <Grid item xs={12} md={1} sx={{ p: 1, textAlign: "center" }}>
            <IconButton
              onClick={handleDisplay}
              aria-label="Search"
              sx={{
                color: "#fff",
                backgroundColor: "#00C0D9",
                padding: "7px",
                marginLeft: "10px",
              }}
            >
              <SearchIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          lg={10}
          md={12}
          justifyContent="center"
          sx={{
            border: " 1px solid #0DEDA3",
            borderRadius: { xs: "30px", md: "50px" },
            margin: "auto",
            p: { xs: 2, md: 0 },
          }}
        >
          <Grid
            item
            md={11}
            xs={12}
            sx={{
              background: "#f9f9f9 !important",
              borderRight: "1px solid #ddd",
              borderRadius: "30px",
              paddingRight: "10px",
              paddingLeft: "10px",
              mb: { xs: 1, md: 0 },
            }}
          >
            <form action="#" onSubmit="return flase;">
              <TextField
                fullWidth={true}
                label="Rechercher"
                type="search"
                onInput={(e) => setSearching(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handlefilterInput({ search: e.target.value });
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Search"
                        onClick={() => {
                          handlefilterInput({ search: searching });
                        }}
                        sx={{
                          color: "#fff",
                          backgroundColor: "#00C0D9",
                          padding: "7px",
                          marginLeft: "10px",
                          mt: "-10px",
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder={
                  infpage === false
                    ? "Titre, platforme, entreprise, nom..."
                    : "Platforme, catégorie, ville, nom d'utilisateur..."
                }
              />
            </form>
          </Grid>
          <Grid item xs={12} md={1} sx={{ p: 1, textAlign: "center" }}>
            <IconButton
              aria-label="Search"
              onClick={handleDisplay}
              sx={{
                color: "#fff",
                backgroundColor: "#00C0D9",
                padding: "7px",
                marginLeft: "10px",
              }}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {/* offset grid with column you want to offset */}
    </Container>
  );
}
