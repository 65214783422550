import * as React from "react";
import Header from "../Layouts/Header/Header";
import Filter from "../HomePage/Elements/Filter";
import Popular from "../HomePage/Elements/Popular";
import Footer from "../Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Category from "../HomePage/Elements/Category";
import { FlashOffRounded } from "@mui/icons-material";
import ClientRequests from "../Api/ClientRequests";
import AuthService from "../Layouts/Login/services";
import LoadingButton from "@mui/lab/LoadingButton";

function isEmpty(obj) {
  if (obj?.platform?.length === 0) {
    delete obj["platform"];
  }
  if (obj?.categories?.length === 0) {
    delete obj["categories"];
  }
  if (obj?.abonnes === null) {
    delete obj["abonnes"];
  }
  return Object.keys(obj).length === 0;
}
const Influencers = () => {
  const [loading, setLoading] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [index, setIndex] = React.useState(1);

  const currentUser = AuthService.getCurrentUser();
  const [profiles, setProfiles] = React.useState([]);
  const [profile, setProfile] = React.useState(null);
  const [wishcounter, setWishcounter] = React.useState(0);
  const [wishlist, setWishlist] = React.useState([]);
  const [utilisateur, setUtilisateur] = React.useState(null);
  const [more, setMore] = React.useState(null);
  const [filter, setFilter] = React.useState({});

  React.useEffect(() => {
    if ("user" in localStorage) {
      setUtilisateur(JSON.parse(localStorage.getItem("user")));
    }

    const getWishlists = async () => {
      const wishlists = await fetchData("influencers/wishlist/");
      setWishlist(wishlists);
    };
    getWishlists();
  }, [localStorage.getItem("user"), wishcounter]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!isEmpty(filter)) {
      ClientRequests.post_("influencers/filtred/", filter)
        .then((data) => data.json())
        .then((resut) => setProfile(resut));
    }

    const getProfiles = async () => {
      if (more === null) {
        const profileslist = await fetchData("influencers/list/");
        setProfiles(profileslist);
      } else {
        const profileslist = await fetchData(
          "influencers/list/?category=" + more
        );
        setProfile(profileslist);
      }
    };
    if (index == 1) {
      getProfiles();
      setIsCompleted(false);
      window.scrollTo(0, 0);
    }
  }, [more, filter, index]);
  const fetchData = async (url) => {
    const res = await fetch(global.config.infos.server_url + url);
    const data = await res.json();
    return data;
  };

  const handleAddWishlist = (status) => {
    if (status === true) {
      setWishcounter(wishcounter + 1);
    }
    if (status === false && wishcounter > 0) {
      setWishcounter(wishcounter - 1);
    }
  };
  const loadMore = () => {
    setLoading(true);

    setIndex(index + 1);
    setTimeout(() => {
      ClientRequests.get_(
        "influencers/list/?category=" + more + "&page=" + (index + 1)
      ).then((profileslist) => {
        setProfile(profileslist);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });

      if (index * 12 > profile?.users.length) {
        setIsCompleted(true);
      } else {
        setIsCompleted(false);
      }
    }, 1000);
  };
  const handlefilterInput = (data) => {
    setMore(null);
    setFilter(data);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Header
        wishcounter={wishcounter}
        setWishcounter={setWishcounter}
        onUserChange={setUtilisateur}
      />
      <Box sx={{ marginTop: "150px", display: { md: "block", xs: "none" } }} />
      <Box sx={{ marginTop: "15px", display: { md: "none", xs: "block" } }} />
      <Container maxWidth="xl">
        <Box sx={{ minHeight: "75vh" }}>
          <Filter
            input={filter}
            handlefilterInput={handlefilterInput}
            infpage={true}
            setInput={setFilter}
          />
          {isEmpty(filter) ? (
            more === null ? (
              <Popular
                addwishlist={handleAddWishlist}
                utilisateur={utilisateur}
                wishlist={wishlist}
                profiles={profiles}
                setMore={setMore}
                more={more}
              />
            ) : (
              <>
                <Category
                  categories={profile}
                  addwishlist={handleAddWishlist}
                  wishlist={wishlist}
                  utilisateur={utilisateur}
                  setIndex={setIndex}
                  setMore={setMore}
                  more={more}
                />
                {isCompleted ? (
                  <></>
                ) : (
                  profile?.users.length > 0 && (
                    <Box
                      sx={{ mt: 4, p: 4, width: "100%", textAlign: "center" }}
                    >
                      <LoadingButton
                        size="large"
                        onClick={loadMore}
                        loading={loading}
                        loadingIndicator="Chargement..."
                        variant="outlined"
                        sx={{
                          margin: "auto",
                          border: "1px solid #0DEDA3",
                          color: "#000",
                          borderRadius: "15px",
                        }}
                      >
                        Voir plus
                      </LoadingButton>
                    </Box>
                  )
                )}
              </>
            )
          ) : (
            <Category
              categories={profile}
              addwishlist={handleAddWishlist}
              wishlist={wishlist}
              utilisateur={utilisateur}
              setIndex={setIndex}
              setMore={setMore}
              more={more}
              filter={filter}
              setFilter={setFilter}
            />
          )}
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Influencers;
