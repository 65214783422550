import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import User from "./Items/User";
import Net from "./Items/Net";
import Progress from "./Items/Progress";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Divide } from "react-lodash";
import { Box } from "@mui/system";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
const columns = [
  { id: "user", label: "User", minWidth: 200 },
  { id: "net", label: "Net", minWidth: 200 },
  {
    id: "date_fin",
    label: "Délai",
    minWidth: 200,
    align: "right",
  },
  {
    id: "progress",
    label: "Progress",
    minWidth: 0,
    align: "right",
  },

  {
    id: "action",
    label: "Action)",
    minWidth: 250,
    align: "right",
  },
];

const Recap = ({ contrats, profile }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  console.log("profile", contrats);
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", mt: 2 }}>
      <TableContainer sx={{ maxHeight: "95vh" }}>
        <Table stickyHeader aria-label="sticky table">
          {contrats !== null && (
            <TableBody>
              {contrats
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        sx={{
                          backgroundColor:
                            row["progress"] === "Completed" && "#0080007d",
                          display: { xs: "none", md: "block" },
                          position: "relative",
                        }}
                      >
                        {row["message"]["sender"]["id"] ===
                        profile?.profileinfos?.id ? (
                          <Box sx={{ position: "absolute", top: 0, right: 10 }}>
                            {row["seller_lu"] === false && (
                              <NotificationsActiveIcon
                                sx={{ color: "#9c27b0" }}
                              />
                            )}
                          </Box>
                        ) : (
                          <Box sx={{ position: "absolute", top: 0, right: 10 }}>
                            {row["buyer_lu"] === false && (
                              <NotificationsActiveIcon
                                sx={{ color: "#9c27b0" }}
                              />
                            )}
                          </Box>
                        )}
                        {columns?.map((column) => (
                          <TableCell
                            key={column?.id}
                            align={column?.align}
                            sx={{
                              minWidth: column?.minWidth,
                            }}
                          >
                            {column?.id === "user" && (
                              <>
                                <Typography sx={{ p: 2 }} variant="h5">
                                  {row["cntr"]["title"]}
                                </Typography>
                                <User
                                  contrat={row["message"]}
                                  created_at={row["created_at"]}
                                />
                              </>
                            )}
                            {column?.id === "net" && (
                              <>
                                {row["message"]["sender"]["id"] ===
                                profile?.profileinfos?.id ? (
                                  <Net contrat={row["cntr"]["budget"]} />
                                ) : (
                                  <Net contrat={row["net"]} />
                                )}
                              </>
                            )}
                            {column?.id === "date_fin" && (
                              <Progress contrat={row["progress"]} />
                            )}
                            {column?.id === "action" && (
                              <ButtonGroup
                                variant="contained"
                                aria-label="outlined primary button group"
                                sx={{
                                  borderRadius: 20,
                                  background: "#0DEDA3",
                                  width: "100%",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  href={
                                    "messages/" +
                                    row["message"]["receiver"]["username"]
                                  }
                                  sx={{
                                    p: 1,
                                    width: "100%",
                                    borderRadius: 20,
                                    background: "#0DEDA3",
                                  }}
                                >
                                  Recontacter
                                </Button>
                                <Button
                                  variant="contained"
                                  href={"contrat/" + row["cntr"]["id"]}
                                  sx={{
                                    p: 1,
                                    width: "100%",
                                    borderRadius: 20,
                                    background: "#0DEDA3",
                                  }}
                                >
                                  Contrat
                                </Button>
                              </ButtonGroup>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        sx={{
                          backgroundColor:
                            row["progress"] === "Completed" && "#0080007d",
                          display: { xs: "block", md: "none" },
                          position: "relative",
                        }}
                      >
                        {row["message"]["sender"]["id"] ===
                        profile?.profileinfos?.id ? (
                          <Box sx={{ position: "absolute", top: 0, right: 10 }}>
                            {row["seller_lu"] === false && (
                              <NotificationsActiveIcon
                                sx={{ color: "#9c27b0" }}
                              />
                            )}
                          </Box>
                        ) : (
                          <Box sx={{ position: "absolute", top: 0, right: 10 }}>
                            {row["buyer_lu"] === false && (
                              <NotificationsActiveIcon
                                sx={{ color: "#9c27b0" }}
                              />
                            )}
                          </Box>
                        )}
                        <TableCell>
                          <Container>
                            <Grid container>
                              <Grid item xs={12}>
                                <>
                                  <Typography sx={{ p: 2 }} variant="h5">
                                    {row["cntr"]["title"]}
                                  </Typography>
                                  <Divider />
                                  <User
                                    contrat={row["message"]}
                                    created_at={row["created_at"]}
                                  />
                                </>
                              </Grid>
                              <Grid item xs={6}>
                                {row["message"]["sender"]["id"] ===
                                profile?.profileinfos?.id ? (
                                  <Net contrat={row["cntr"]["budget"]} />
                                ) : (
                                  <Net contrat={row["net"]} />
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                <Progress contrat={row["progress"]} />
                              </Grid>
                              <Grid item xs={12}>
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="outlined primary button group"
                                  sx={{
                                    borderRadius: 5,
                                    background: "#0DEDA3",
                                    width: "100%",
                                    mt: 2,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    href={
                                      "messages/" +
                                      row["message"]["receiver"]["username"]
                                    }
                                    sx={{
                                      p: 1,
                                      width: "100%",
                                      borderRadius: 5,
                                      background: "#0DEDA3",
                                    }}
                                  >
                                    Recontacter
                                  </Button>
                                  <Button
                                    variant="contained"
                                    href={"contrat/" + row["cntr"]["id"]}
                                    sx={{
                                      p: 1,
                                      width: "100%",
                                      borderRadius: 5,
                                      background: "#0DEDA3",
                                    }}
                                  >
                                    Contrat
                                  </Button>
                                </ButtonGroup>
                              </Grid>
                            </Grid>
                          </Container>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={contrats?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        frFR
      />
    </Paper>
  );
};

export default Recap;
