import React from 'react';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  overflow: 'hidden',
    height: '100%',
    width:'100%',

}));


const cover = {
    width:'100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'unset',
    transition: '.5s ease-in-out',
    cursor:'pointer'
  }

const CoverImages = ({images, box,setBox}) => {
  const halfstyle = {
    maxHeight:{md:'250px', xs:'150px'}, 
    minHeight:{md:'249px', xs:'149px'}
  }
  const fullstyle = {
    maxHeight:{md:'500px', xs:'250px'}, 
    minHeight:{md:'499px', xs:'249px'}
  }

  return (
    <Grid container item md={12} xs={12} sx={{ margin:'auto' }}>
   

          <Grid item md={images?.length > 1 ? 6 : 12} sx={fullstyle}>
            <Item sx={{p:{xs:0, md:'8px'}}}>
            
                <img onClick={()=>setBox(0)} src={images[0]} style={cover}/>

                {box === 0 && <Lightbox image={images[0]} onClose={()=>setBox(-1)}  title="Profil photo" />}
            </Item>
          </Grid>


        {images?.length > 1 &&

          <Grid container item md={6} >
            
            {images?.length > 1 &&
          <Grid item md={images?.length == 3 ? 6 : 12} xs={images?.length < 3 ? 12 : 6} sx={images?.length < 4 ? fullstyle : halfstyle}>
            <Item sx={{p:{xs:0, md:'8px'}}}>
            <img onClick={()=>setBox(1)} src={images[1]} style={cover} />
            {box === 1 && <Lightbox image={images[1]} onClose={()=>setBox(-1)}  title="Profil photo" />}

            </Item>
          </Grid>
            }

          {images?.length > 2 &&
          
          <Grid item md={6} xs={6} sx={images?.length < 4 ? fullstyle : halfstyle}>
            <Item sx={{p:{xs:0, md:'8px'}}}>  

            <img onClick={()=>setBox(2)} src={images[2]} style={cover}/>

            {box === 2 && <Lightbox image={images[2]} onClose={()=>setBox(-1)}  title="Profil photo" />}

            </Item>
          </Grid>
          }
           {images?.length > 3 &&
          <Grid item md={6} xs={6} sx={{display:{md:'block', xs:'none'}, maxHeight:{md:'250px', xs:'0'}, minHeight:{md:'249px', xs:'0'} }}>
            <Item sx={{p:{xs:0, md:'8px'}}}>
            <img onClick={()=>setBox(3)} src={images[3]} style={cover}/>
            {box === 3 && <Lightbox image={images[3]} onClose={()=>setBox(-1)} title="Profil photo" />}

            </Item>
          </Grid>
            }

          </Grid>


}




    </Grid>
  )
}

export default CoverImages