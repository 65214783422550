import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Avatar from "./EditParts/Avatar";
import AuthService from "../../Layouts/Login/services";
import ClientRequests from "../../Api/ClientRequests";
import Images from "./EditParts/Images";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Infos from "./EditParts/Infos";
import Reseau from "./EditParts/Reseau";

const Edit = () => {
  const currentUser = AuthService.getCurrentUser();
  const [profile, setProfile] = React.useState(null);
  const [images, setImages] = React.useState(null);
  const [reseaux, setReseaux] = React.useState(null);
  const [updated, setUpdated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    ClientRequests.get_token("user/profile/", currentUser.auth_token).then(
      (response) => setProfile(response)
    );

    ClientRequests.get_token("user/images/", currentUser.auth_token).then(
      (response) => setImages(response)
    );

    ClientRequests.get_token("influencers/", currentUser.auth_token).then(
      (response) => setReseaux(response)
    );
  }, [updated]);

  const handleImageDelete = (data) => {
    ClientRequests.delete_token(
      "user/image/" + data.id + "/",
      currentUser.auth_token
    );
    setUpdated(!updated);
  };

  const handleChangeDefault = (data) => {
    ClientRequests.put_token(
      "user/image/" + data.id + "/",
      currentUser.auth_token
    );
    setUpdated(!updated);
  };

  const handleImagesAdd = async (pics) => {
    setLoading(true);
    try {
      const req = await ClientRequests.post_token(
        "user/images/",
        currentUser.auth_token,
        pics
      );
      const result = await req.json();
      if (req.ok == true) {
        setUpdated(!updated);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setLoading(false);
  };

  return (
    <Container
      maxWidth="100%"
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container sx={{ mb: 2, minHeight: "300px", p: { md: 3, xs: 2 } }}>
        <Grid container maxWidth="xl" sx={{ margin: "auto" }}>
          {profile != null && <Avatar profile={profile} />}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: "rgb(236 254 249)",
          minHeight: "300px",
          mb: 2,
          p: { md: 3, xs: 2 },
        }}
      >
        <Grid
          container
          maxWidth="xl"
          sx={{ margin: "auto", minHeight: "300px" }}
        >
          {images != null && (
            <Images
              photos={images}
              handleImagesAdd={handleImagesAdd}
              handleChangeDefault={handleChangeDefault}
              handleImageDelete={handleImageDelete}
            />
          )}
        </Grid>
      </Grid>

      <Grid container sx={{ mb: 2, minHeight: "300px", p: { md: 3, xs: 2 } }}>
        <Grid container maxWidth="xl" sx={{ margin: "auto" }}>
          {profile != null && <Infos profile={profile} />}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: "rgb(236 254 249)",
          minHeight: "300px",
          mb: 2,
          p: { md: 3, xs: 2 },
        }}
      >
        <Grid container maxWidth="xl" sx={{ margin: "auto" }}>
          {reseaux != null && (
            <Reseau
              setUpdated={setUpdated}
              updated={updated}
              reseaux={reseaux}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Edit;
