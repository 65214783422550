import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import AuthService from "../../../Layouts/Login/services";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ImageUploader from "react-images-upload";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const Item = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : "rgb(236 254 249)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
const Images = ({
  photos,
  handleImageDelete,
  handleImagesAdd,
  handleChangeDefault,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [picturUrls, setPicturUrls] = React.useState([]);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDrop = (picture, picurls) => {
    setPicturUrls(picurls);
  };
  return (
    <>
      <Grid item xs={8}>
        <Item>
          <Typography variant="h6">Photos de couverture</Typography>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item sx={{ textAlign: "right" }}>
          <Button onClick={handleClickOpen} variant="text">
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Ajouter
            </Typography>
          </Button>
        </Item>
      </Grid>

      <Grid item xs={12}>
        <Item sx={{ textAlign: "center" }}>
          <ImageList variant="quilted" cols={4} rowHeight={221}>
            {photos.map((item, key) => (
              <ImageListItem key={item.image} cols={1} rows={1}>
                <img
                  {...srcset(
                    global.config.infos.image_url + item.image,
                    221,
                    item.rows,
                    item.cols
                  )}
                  alt={item.title}
                  loading="lazy"
                />

                <ImageListItemBar
                  sx={{
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                      "rgba(0,0,0,0.3) 20%, rgba(0,0,0,0) 100%)",
                  }}
                  title={item.title}
                  position="top"
                  actionIcon={
                    !item.default && (
                      <IconButton
                        onClick={() => handleImageDelete(item)}
                        sx={{ color: "white" }}
                        aria-label={`star ${item.title}`}
                      >
                        <DeleteIcon sx={{ color: "#ff0000" }} />
                      </IconButton>
                    )
                  }
                  actionPosition="right"
                />

                <ImageListItemBar
                  sx={{
                    width: "50%",
                    background: "transparent",
                  }}
                  title={item.title}
                  position="top"
                  actionIcon={
                    <IconButton
                      onClick={() => handleChangeDefault(item)}
                      sx={{ color: "white" }}
                      aria-label={`star ${item.title}`}
                    >
                      {item.default ? (
                        <StarIcon sx={{ color: "#fbeb4b" }} />
                      ) : (
                        <StarBorderIcon />
                      )}
                    </IconButton>
                  }
                  actionPosition="left"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Item>
      </Grid>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Mettre à jour la photo de couverture"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ImageUploader
              withIcon={true}
              withLabel={true}
              label="Nombre maximal de fichiers acceptés : 4 || La taille de chacun est limitée à 2 Mo || Liste de formats de fichiers acceptés : jpg, gif, png, gif, jpeg"
              onChange={onDrop}
              imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
              maxFileSize={2000000}
              withPreview={true}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Annuler
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleImagesAdd(picturUrls);
            }}
            autoFocus
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Images;
