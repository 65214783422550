import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import WorkIcon from "@mui/icons-material/Work";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import Entreprise from "./Parts/Entreprise";
import Particulier from "./Parts/Particulier";
import ClientRequests from "../../Api/ClientRequests";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AuthService from "../../Layouts/Login/services";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Marque = () => {
  const currentAffiliate = AuthService.getCurrentAffiliate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [success, setSuccess] = new React.useState(false);
  const [backdropopen, setBackdropopen] = React.useState(false);

  const [errors, setError] = new React.useState({
    first_name: "",
    siret: "",
    company: "",
    company_adresse1: "",
    company_adresse2: "",
    last_name: "",
    codepostal: "",
    ville: "",
    username: "",
    email: "",
    password: "",
    re_password: "",
    non_field_errors: "",
    confidentiality: "",
  });

  const registerHandle = async (infos) => {
    if (infos.user.confidentiality == false) {
      setError({ ...errors, confidentiality: "Ce champ est obligatoire" });
      return false;
    }
    setBackdropopen(true);
    const validate = await ClientRequests.post_(
      "user/validateprofile/",
      infos.profile
    );
    if (validate.ok === false) {
      const validator = await validate.json();
      setError(validator);
      setBackdropopen(false);
      return false;
    }
    const res = await ClientRequests.post_("users/", infos.user);
    const data = await res.json();
    if (res.ok === true) {
      const profileres = await ClientRequests.post_("user/addprofile/", {
        ...infos.profile,
        user: data.id,
        affiliate: currentAffiliate?.username,
      });

      if (profileres.ok === true) {
        setBackdropopen(false);
        localStorage.removeItem("affiliate");
        window.scrollTo(0, 0);
        setSuccess(true);
      }
    } else {
      setBackdropopen(false);
      setError(data);
    }
  };
  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Box sx={{ marginTop: "150px", display: { md: "block", xs: "none" } }} />
      <Box sx={{ marginTop: "50px", display: { md: "none", xs: "block" } }} />
      <Container maxWidth="xl">
        <Box sx={{ minHeight: "75vh" }}>
          <Grid container>
            {/* offset grid with column you want to offset */}
            <Grid item md={2} xs={0} />
            <Grid item md={8} xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="icon label tabs example"
              >
                <Tab
                  sx={{ color: "rgb(0, 192, 217)" }}
                  icon={<WorkIcon />}
                  label="Entreprise"
                />
                <Tab
                  sx={{ color: "rgb(0, 192, 217)" }}
                  icon={<AssignmentIndIcon />}
                  label="Particulier"
                />
              </Tabs>
            </Grid>
            <Grid item md={2} xs={0} />
          </Grid>

          <Grid container>
            {/* offset grid with column you want to offset */}
            <Grid item xs={12}>
              <TabPanel value={value} index={0}>
                {success === false ? (
                  <Entreprise errors={errors} registerHandle={registerHandle} />
                ) : (
                  <Typography sx={{ p: 2, textAlign: "center" }}>
                    <strong>Félicitation </strong>&#128522;
                    <br />
                    Votre profil a été créé avec succès, un message a été envoyé
                    à votre adresse e-mail.
                    <br />
                    <strong style={{ color: "red" }}>
                      Pensez à vérifier vos spams (courrier indésirable)
                    </strong>
                  </Typography>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {success === false ? (
                  <Particulier
                    errors={errors}
                    registerHandle={registerHandle}
                  />
                ) : (
                  <Typography
                    sx={{ p: 2, textAlign: "center", margin: "auto" }}
                  >
                    <strong>Félicitation </strong>&#128522;
                    <br />
                    Votre profil a été créé avec succès, un message a été envoyé
                    à votre adresse e-mail.
                    <br />
                    <strong style={{ color: "red" }}>
                      Pensez à vérifier vos spams (courrier indésirable)
                    </strong>
                  </Typography>
                )}
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Marque;
