const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getCurrentAffiliate = () => {
  return JSON.parse(localStorage.getItem("affiliate"));
};

const fetchData = async (url, token) => {
  const res = await fetch(global.config.infos.server_url + url, {
    headers: {
      Authorization: "Token " + token,
    },
  });
  const data = await res.json();
  if (res.status === 401) {
    localStorage.clear();
    return {};
  }
  return data;
};
const AuthService = {
  getCurrentUser,
  fetchData,
  getCurrentAffiliate,
};

export default AuthService;
