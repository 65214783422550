import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
moment.locale("fr");

const ReceiverInfo = ({ receiver }) => {
  const navigate = useNavigate();
  return (
    <List>
      <Avatar
        alt={receiver?.first_name}
        src={global.config.infos.image_url + receiver?.avatar}
        onClick={
          receiver?.role === "Influenceur"
            ? () => navigate("/profile/influenceur/" + receiver?.username)
            : () => navigate("/profile/marque/" + receiver?.username)
        }
        sx={{
          width: 80,
          height: 80,
          margin: "auto",
          cursor: "pointer",
          marginTop: "20px",
        }}
      />
      {receiver?.role === "Marque" ? (
        <ListItem>
          <ListItemIcon>Raison sociale :</ListItemIcon>
          <ListItemText primary={receiver?.company} />
        </ListItem>
      ) : (
        <>
          <ListItem>
            <ListItemIcon>Prénom :</ListItemIcon>
            <ListItemText primary={receiver?.first_name} />
          </ListItem>

          <ListItem>
            <ListItemIcon>Nom :</ListItemIcon>
            <ListItemText primary={receiver?.last_name} />
          </ListItem>
        </>
      )}

      <ListItem>
        <ListItemIcon>Ville :</ListItemIcon>
        <ListItemText primary={receiver?.ville} />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <span style={{ marginRight: "5px" }}> Compte créé, </span>
        </ListItemIcon>
        <ListItemText primary={moment(receiver?.created_at).fromNow()} />
      </ListItem>
    </List>
  );
};

export default ReceiverInfo;
