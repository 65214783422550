import React from "react";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import contactImage from "../../assets/images/header/blog-title-img-03.jpeg";
import conbg from "../../assets/images/conbg.png";
import "./Elements/contact.css";
import LoadingButton from "@mui/lab/LoadingButton";

import FmdGoodIcon from "@mui/icons-material/FmdGood";
import EmailIcon from "@mui/icons-material/Email";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import ClientRequests from "../Api/ClientRequests";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Contact = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [utilisateur, setUtilisateur] = React.useState(null);
  const [input, setInput] = React.useState({
    nom: "",
    email: "",
    tele: "",
    sujet: "",
    message: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleContact = async () => {
    setLoading(true);
    const req = await ClientRequests.post_("home/contact/", input);
    if (req.ok === true) {
      setLoading(false);
      setSent(true);
    } else {
      const data = await req.json();
      setError(data);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Header wishcounter={0} onUserChange={setUtilisateur} />
      <img src={contactImage} style={{ width: "100%" }} />
      <Container
        maxWidth="100%"
        className="contact"
        style={{
          background: "rgb(0,203,132)",
          background:
            "linear-gradient(155deg, rgba(0,203,132,1) 11%, rgba(0,142,193,1) 100%)",
          marginTop: "-7px",
        }}
      >
        <Box sx={{ minHeight: "75vh" }}>
          <Typography
            variant="h5"
            textAlign="center"
            sx={{ p: 2, fontWeight: "800", color: "#fff" }}
          >
            Contactez nous
          </Typography>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{ p: 2, color: "#fff" }}
          >
            Vous avez une question ?Vous souhaitez nous rencontrer ?<br />
            Rien de plus simple ! Vous pouvez nous appeler ou nous envoyer un
            petit message, avec le formulaire ci-dessous ! À très vite !
          </Typography>
          <Divider color="white" />
          <Grid container sx={{ mt: 5 }} spacing={2}>
            {/* offset grid with column you want to offset */}
            <Grid item md={1} xs={1} />
            {/* main grid column! */}

            <Grid item md={4} xs={11} sx={{ p: 5 }}>
              <Item style={{ color: "#fff" }}>
                <FmdGoodIcon style={{ color: "#fff" }} />
                <Typography
                  variant="h5"
                  fontSize="small"
                  textAlign="center"
                  sx={{ mb: 2, fontWeight: "bolder" }}
                >
                  ADRESSE
                </Typography>

                <Typography variant="body1" textAlign="center">
                  21 square antoine watteau- <br />
                  60100 creil
                </Typography>
                <Divider sx={{ mb: 5 }} color="white" />

                <EmailIcon style={{ color: "#fff" }} />
                <Typography
                  variant="h5"
                  fontSize="small"
                  textAlign="center"
                  sx={{ mb: 2, fontWeight: "bolder" }}
                >
                  EMAIL
                </Typography>

                <Typography variant="body1" textAlign="center">
                  admin@adhouse.fr
                </Typography>
              </Item>
            </Grid>
            {sent ? (
              <Grid container item md={6} xs={12} spacing={2}>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    margin: "auto",
                    fontSize: "18px",
                    color: "#f6fff6",
                  }}
                >
                  <strong> Nous avons bien reçu votre demande ...</strong>{" "}
                  <br />
                  Merci de nous avoir contacté ! <br />
                  Nous reviendrons vers vous rapidement.
                </Typography>
              </Grid>
            ) : (
              <Grid container item md={6} xs={12} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <TextField
                      required
                      id="outlined-basic"
                      helperText={error?.nom}
                      label="Nom"
                      onInput={(e) =>
                        setInput({ ...input, nom: e.target.value })
                      }
                      style={{ width: "100%" }}
                      variant="outlined"
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Item>
                    <TextField
                      required
                      helperText={error?.email}
                      id="outlined-basic"
                      onInput={(e) =>
                        setInput({ ...input, email: e.target.value })
                      }
                      label="E-mail"
                      style={{ width: "100%" }}
                      variant="outlined"
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Item>
                    <TextField
                      id="outlined-basic"
                      label="Téléphone"
                      onInput={(e) =>
                        setInput({ ...input, tele: e.target.value })
                      }
                      style={{ width: "100%" }}
                      variant="outlined"
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Item>
                    <TextField
                      required
                      helperText={error?.sujet}
                      id="outlined-basic"
                      label="Sujet"
                      onInput={(e) =>
                        setInput({ ...input, sujet: e.target.value })
                      }
                      style={{ width: "100%" }}
                      variant="outlined"
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Item>
                    <TextField
                      id="outlined-multiline-static"
                      label="Message"
                      helperText={error?.message}
                      onInput={(e) =>
                        setInput({ ...input, message: e.target.value })
                      }
                      multiline
                      style={{ width: "100%" }}
                      rows={6}
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Item sx={{ textAlign: "right" }}>
                    <LoadingButton
                      onClick={handleContact}
                      endIcon={<SendIcon />}
                      loading={loading}
                      loadingPosition="end"
                      size="large"
                      variant="contained"
                      style={{ backgroundColor: "#00C0D9" }}
                    >
                      Envoyez
                    </LoadingButton>
                  </Item>
                </Grid>
              </Grid>
            )}

            <Grid item md={1} sm={0} />
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;
