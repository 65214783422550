import React from "react";
import moment from "moment";
import "moment/locale/fr";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AuthService from "../../../../Layouts/Login/services";
import ClientRequests from "../../../../Api/ClientRequests";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "1px solid #0DEDA3",
  background: "#fcfafa",
  overflow: "auto",
}));

const CopmanyInfo = ({ annonce }) => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  const [avg, setAvg] = React.useState(0);

  React.useEffect(() => {
    ClientRequests.get_token(
      "influencers/rating_average/" + annonce.username + "/",
      currentUser.auth_token
    ).then((res) => {
      setAvg(res.average);
    });
  }, []);
  return (
    <>
      <Item
        onClick={() => navigate("profile/marque/" + annonce.username)}
        sx={{
          textAlign: "justify",
          cursor: "pointer",

          "&:hover": {
            background: "rgb(243 243 243)",
          },
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src={global.config.infos.image_url + annonce.profile.avatar}
          sx={{ margin: "auto", width: 60, height: 60 }}
        />
        <Typography
          variant="body1"
          sx={{
            fontWeight: "900",
            mt: 1,
            textAlign: "center",
          }}
        >
          {annonce.profile.role === "Marque"
            ? annonce.profile.company
            : annonce.profile.first_name + " " + annonce.profile.last_name}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {annonce?.profile.ville}, France
        </Typography>
        <Rating
          name="read-only"
          value={avg}
          sx={{
            justifyContent: "center",
            width: "100%",
            color: "rgb(0, 192, 217)",
          }}
          readOnly
        />
        <Typography
          variant="caption"
          sx={{ textAlign: "justify", display: "block" }}
        >
          {annonce?.profile.introduction.substring(0, 150)}...
        </Typography>
      </Item>
      <Item sx={{ mt: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <strong style={{ color: "rgb(0, 192, 217)" }}>
              Date de publication :{" "}
            </strong>
            {moment(annonce.created_at).format("LLL")}
          </Grid>
        </Grid>
      </Item>
    </>
  );
};

export default CopmanyInfo;
