import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Avatar from "./EditsParts/Avatar";
import AuthService from "../../Layouts/Login/services";
import ClientRequests from "../../Api/ClientRequests";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Infos from "./EditsParts/Infos";

const ParticulierEdit = () => {
  const currentUser = AuthService.getCurrentUser();
  const [profile, setProfile] = React.useState(null);
  const [updated, setUpdated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    ClientRequests.get_token("user/profile/", currentUser.auth_token).then(
      (response) => setProfile(response)
    );
  }, [updated]);

  const handleClose = () => {
    setLoading(false);
  };

  return (
    <Container
      maxWidth="100%"
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container sx={{ mb: 2, minHeight: "300px", p: { md: 3, xs: 2 } }}>
        <Grid container maxWidth="xl" sx={{ margin: "auto" }}>
          {profile != null && <Avatar profile={profile} />}
        </Grid>
      </Grid>

      <Grid container sx={{ mb: 2, minHeight: "300px", p: { md: 3, xs: 2 } }}>
        <Grid container maxWidth="xl" sx={{ margin: "auto" }}>
          {profile != null && <Infos profile={profile} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ParticulierEdit;
