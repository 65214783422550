import * as React from "react";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import ClientRequests from "../../../Api/ClientRequests";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  marginTop: 20,
  padding: 30,
}));

const Entreprise = ({ errors, registerHandle }) => {
  const [value, setValue] = React.useState(0);
  const [form, setForm] = new React.useState({
    profile: {
      siret: "",
      company: "",
      company_adresse1: "",
      company_adresse2: "",
      pays: "France",
      role: "Marque",
      status: true,
    },
    user: {
      username: "",
      email: "",
      password: "",
      re_password: "",
      confidentiality: false,
    },
  });
  const [codepostal, setCodepostal] = React.useState(null);
  const handleCodePostalverification = async () => {
    //
    if (form.profile.codepostal.length == 5) {
      const res = await fetch(
        "https://geo.api.gouv.fr/communes?codePostal=" +
          form.profile.codepostal,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (res.ok === true) {
        const result = await res.json();
        setForm({
          form,
          profile: { ...form.profile, ville: result[0].nom },
        });
        setCodepostal(null);
      } else {
        setCodepostal("Le code postal n'est pas valid");
      }
    } else {
      setForm({ form, profile: { ...form.profile, ville: "" } });
      setCodepostal("Le code postal n'est pas valid");
    }
  };
  return (
    <Item>
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Divider>Entreprise</Divider>
        </Grid>
        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            SIRET *
          </InputLabel>
          <TextField
            required
            id="username"
            onInput={(e) =>
              setForm({
                ...form,
                profile: { ...form.profile, siret: e.target.value },
              })
            }
            helperText={errors?.siret}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Nom de société *
          </InputLabel>
          <TextField
            required
            id="username"
            onInput={(e) =>
              setForm({
                ...form,
                profile: { ...form.profile, company: e.target.value },
              })
            }
            helperText={errors?.company}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Adresse *
          </InputLabel>
          <TextField
            required
            id="username"
            onInput={(e) =>
              setForm({
                ...form,
                profile: { ...form.profile, company_adresse1: e.target.value },
              })
            }
            helperText={errors?.company_adresse1}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Complément d'adresse
          </InputLabel>
          <TextField
            id="username"
            onInput={(e) =>
              setForm({
                ...form,
                profile: { ...form.profile, company_adresse2: e.target.value },
              })
            }
            helperText={errors?.company_adresse2}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Pays *
          </InputLabel>
          <Autocomplete
            id="country-select-demo"
            options={ClientRequests?.countries}
            autoHighlight
            defaultValue={{
              code: "FR",
              label: "France",
              phone: "33",
              suggested: true,
            }}
            onChange={(event, newValue, reason) => {
              if (
                event.type === "keydown" &&
                event.key === "Backspace" &&
                reason === "removeOption"
              ) {
                return;
              }
              setForm({
                ...form,
                profile: { ...form.profile, pays: newValue?.label },
              });
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                helperText={errors?.pays}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Ville *
          </InputLabel>
          <TextField
            required
            id="username"
            value={form.profile.ville != "" ? form.profile.ville : ""}
            onInput={(e) =>
              setForm({
                ...form,
                profile: { ...form.profile, ville: e.target.value },
              })
            }
            helperText={errors?.ville}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Divider>Compte</Divider>
        </Grid>
        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Nom d'utilisateur *
          </InputLabel>
          <TextField
            required
            id="username"
            onInput={(e) =>
              setForm({
                ...form,
                user: { ...form.user, username: e.target.value },
              })
            }
            helperText={errors?.username}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Email *
          </InputLabel>
          <TextField
            required
            id="username"
            onInput={(e) =>
              setForm({
                ...form,
                user: { ...form.user, email: e.target.value },
              })
            }
            helperText={errors?.email}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Mot de passe *
          </InputLabel>
          <TextField
            required
            id="password"
            type="password"
            onInput={(e) =>
              setForm({
                ...form,
                user: { ...form.user, password: e.target.value },
              })
            }
            helperText={errors?.password}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel shrink htmlFor="bootstrap-input">
            Mot de passe (confirmation) *
          </InputLabel>
          <TextField
            required
            id="re_password"
            type="password"
            onInput={(e) =>
              setForm({
                ...form,
                user: { ...form.user, re_password: e.target.value },
              })
            }
            helperText={errors?.re_password}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormGroup>
            <FormControlLabel
              checked={form?.user?.confidentiality}
              onChange={(e) =>
                setForm({
                  ...form,
                  user: { ...form.user, confidentiality: e.target.checked },
                })
              }
              control={
                <Checkbox
                  sx={{
                    color: "rgb(0, 192, 217)",
                    "&.Mui-checked": {
                      color: "rgb(0, 192, 217)",
                    },
                  }}
                />
              }
              label="* J'ai lu et accepté les conditions générales d'utilisation et la politique de confidentialité de Adhouse."
            />
            <FormHelperText children={errors?.confidentiality} error={true} />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "rgb(0, 192, 217)",
                    "&.Mui-checked": {
                      color: "rgb(0, 192, 217)",
                    },
                  }}
                />
              }
              label="Je souhaite recevoir la newsletter par e-mail."
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => registerHandle(form)}
            style={{ backgroundColor: "#00C0D9", width: "100%" }}
            endIcon={<SendIcon />}
            size="large"
          >
            Inscrivez
          </Button>
        </Grid>
      </Grid>
    </Item>
  );
};

export default Entreprise;
