import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "@mui/material";

const columns = [
  { id: "title", label: "Title", minWidth: 170 },
  { id: "budget", label: "Budget", minWidth: 100 },
  {
    id: "date_fin",
    label: "Délai",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "progress",
    label: "Progress",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const Recap = ({ contrats }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", mt: 2 }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {contrats !== null && (
            <TableBody>
              {contrats
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      sx={{
                        backgroundColor:
                          row["progress"] === "Completed" && "#0080007d",
                      }}
                    >
                      {columns.map((column) => {
                        let value;
                        if (
                          column.id == "title" ||
                          column.id == "date_fin" ||
                          column.id == "budget"
                        ) {
                          value = row["cntr"][column.id];
                        } else {
                          value = row[column.id];
                        }

                        if (column.id == "progress") {
                          if (value === "Pending") value = "En cours";
                          if (value === "Completed") value = "Terminer";
                          if (value === "Cancelled") value = "Annuler";
                        }

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id == "title" ? (
                              <Link
                                sx={{
                                  textDecoration: "none",
                                  fontWeight: 900,
                                }}
                                href={"/contrat/" + row["cntr"]["id"]}
                              >
                                {value}
                              </Link>
                            ) : (
                              <>
                                {column?.id === "budget" ? (
                                  <>{value.toFixed(2)}€</>
                                ) : (
                                  <>{value}</>
                                )}
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={contrats?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default Recap;
