import React from "react";
import Grid from "@mui/material/Grid";
import { Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useLocation, Link } from "react-router-dom";
import logowords from "../../../../assets/images/logoword.png";
import fb from "../../../../assets/images/socialmedia/ro-facebook.png";
import ins from "../../../../assets/images/socialmedia/ro-instagram.png";
import tik from "../../../../assets/images/socialmedia/ro-tiktok.png";
import tw from "../../../../assets/images/socialmedia/ro-twitter.png";
import IconButton from "@mui/material/IconButton";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import TiktokPixel from "tiktok-pixel";

import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  textAlign: "left",
  padding: "15px",
}));
const social = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/profile.php?id=100080238220844",
    picture: fb,
  },
  {
    name: "Instagram",
    link: "https://instagram.com/adhouseofficiel?igshid=YmMyMTA2M2Y=",
    picture: ins,
  },
  {
    name: "Tik-tok",
    link: "https://tiktok.com/@adhouseofficiel",
    picture: tik,
  },
  {
    name: "Twitter",
    link: "https://twitter.com/adhouseofficiel?t=yJACaee2avpwOI3ODmscRg&s=09",
    picture: tw,
  },
];
const tagManagerArgs = {
  gtmId: "GTM-M82QJT9",
};

const Footermenus = () => {
  const location = useLocation();
  const getCookieValue = () => {
    if (Cookies.get("myAwesomeCookieName2") === undefined) {
      return false;
    }
    return Cookies.get("myAwesomeCookieName2");
  };

  const isConsent = getCookieValue();
  const handleAcceptCookie = () => {
    if (window.location.href !== "http://127.0.0.1:3000/") {
      ReactGA.initialize("UA-235398368-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
      TiktokPixel.init("CCU06AJC77U06CIB88TG");
      TiktokPixel.pageView(window.location.pathname + window.location.search);
    }
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  React.useEffect(() => {
    if (
      isConsent === "true" &&
      window.location.href !== "http://127.0.0.1:3000/"
    ) {
      ReactGA.initialize("UA-235398368-1");
      ReactGA.pageview(window.location.pathname + window.location.search);
      TiktokPixel.init("CCU06AJC77U06CIB88TG");
      TiktokPixel.pageView(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <Box sx={{ pt: 5, pb: 5, border: "1px solid #0DEDA3" }}>
      <Grid container>
        <Grid item md={1} xs={0} />

        <Grid item md={4} xs={12}>
          <Item elevation={0}>
            <img src={logowords} className="footerlogo" alt="logo" />
          </Item>
        </Grid>

        <Grid item md={3} xs={5}>
          <Item elevation={0}>
            <Typography
              variant="body1"
              sx={{
                lineHeight: 3,
                textAlign: "left",
                fontSize: { md: "initial", xs: "10px" },
              }}
            >
              <Link to="/" style={{ textDecoration: "none", color: "#00C0D9" }}>
                Accueil
              </Link>
            </Typography>

            <Typography
              variant="body1"
              sx={{
                lineHeight: 3,
                textAlign: "left",
                fontSize: { md: "initial", xs: "10px" },
              }}
            >
              <Link
                to="/contact"
                style={{ textDecoration: "none", color: "#00C0D9" }}
              >
                Contactez Nous
              </Link>
            </Typography>

            <Typography
              variant="body1"
              sx={{
                lineHeight: 3,
                textAlign: "left",
                fontSize: { md: "initial", xs: "10px" },
              }}
            >
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "#00C0D9" }}
              >
                Qui sommes nous
              </Link>
            </Typography>
          </Item>
        </Grid>

        <Grid item md={3} xs={7}>
          <Item elevation={0}>
            <Typography
              variant="body1"
              sx={{
                lineHeight: 3,
                textAlign: "left",
                fontSize: { md: "initial", xs: "10px" },
              }}
            >
              <Link
                to="/cgu"
                style={{ textDecoration: "none", color: "#00C0D9" }}
              >
                Conditions générales d'utilisation
              </Link>
            </Typography>

            <Typography
              variant="body1"
              sx={{
                lineHeight: 3,
                textAlign: "left",
                fontSize: { md: "initial", xs: "10px" },
              }}
            >
              <Link
                to="/legale"
                style={{ textDecoration: "none", color: "#00C0D9" }}
              >
                Mentions légales
              </Link>
            </Typography>

            <Typography
              variant="body1"
              sx={{
                lineHeight: 3,
                textAlign: "left",
                fontSize: { md: "initial", xs: "10px" },
              }}
            >
              <Link
                to="/confidentialite"
                style={{ textDecoration: "none", color: "#00C0D9" }}
              >
                Politique confidentialité
              </Link>
            </Typography>
          </Item>
        </Grid>
        <Grid item md={1} xs={0} />
        <Grid item md={12} xs={12} sx={{ textAlign: "center", mb: 5 }}>
          {social.map((item) => (
            <IconButton>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <img src={item.picture} style={{ width: 40, height: 40 }} />
              </a>
            </IconButton>
          ))}
        </Grid>
      </Grid>
      <CookieConsent
        location="bottom"
        buttonText="Accepter tout"
        enableDeclineButton="True"
        declineButtonText="Refuser tout"
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        cookieName="myAwesomeCookieName2"
        style={{ background: "rgb(43 55 59 / 67%)" }}
        buttonStyle={{
          background: "#0DEDA3",
          fontSize: "13px",
          borderRadius: "5px",
          padding: "9px 20px",
        }}
        declineButtonStyle={{
          background: "#00C0D9",
          color: "#000",
          fontSize: "13px",
          borderRadius: "5px",
          padding: "9px 20px",
        }}
        expires={150}
      >
        Ce site peut-être amener à utiliser des cookies et vous permet de
        contrôler ce que vous souhaitez activer.
        <br /> Vous pouvez accepter ou refuser cette utilisation des cookies via
        les options suivantes :{" "}
      </CookieConsent>
    </Box>
  );
};

export default Footermenus;
