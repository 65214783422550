// module.exports = global.config = {
//   infos: {
//     server_url: "http://127.0.0.1:8000/api/v1/",
//     image_url: "http://127.0.0.1:8000/",
//   },
// };

// module.exports = global.config = {
//   infos: {
//     server_url: "https://slowlorisons.pythonanywhere.com/api/v1/",
//     image_url: "https://slowlorisons.pythonanywhere.com/",
//   },
// };

module.exports = global.config = {
  infos: {
    server_url: "https://api.adhouse.fr/api/v1/",
    image_url: "https://api.adhouse.fr/",
  },
};
