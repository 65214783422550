import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import HubIcon from "@mui/icons-material/Hub";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DirectionsIcon from "@mui/icons-material/Directions";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import AuthService from "../../Login/services";
const Affiliate = () => {
  const currentUser = AuthService.getCurrentUser();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openT, setOpenT] = React.useState(false);

  const handleTooltipClose = () => {
    setOpenT(false);
  };

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(
      "https://adhouse.fr/follow/" + currentUser?.profileinfos?.username
    );
    setOpenT(true);
  };
  console.log(currentUser);
  return (
    <>
      <IconButton
        aria-label="Affiliate"
        onClick={handleClickOpen}
        sx={{
          color: "#00C0D9",
          p: { md: "15px", xs: "1px" },
          mt: { xs: "-2px", md: 0 },
        }}
      >
        <HubIcon sx={{ color: "#00C0D9" }} />
      </IconButton>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Invitez vos partenaires et gagnez ensemble !"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Adhouse récompense chaque recommandation réussie! En partageant
            votre lien de parrainage donnez a vos partenaires de nouvelles
            opportunités de collaboration avec les influenceurs les plus
            renommés
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                disabled={true}
                sx={{ ml: 0, flex: 1, background: "#eee", p: 1 }}
                value={
                  "https://adhouse.fr/follow/" +
                  currentUser?.profileinfos?.username
                }
                inputProps={{
                  "aria-label": "Affiliate",
                }}
              />

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={openT}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copié"
                  >
                    <IconButton
                      color="primary"
                      onClick={handleTooltipOpen}
                      sx={{ p: "10px" }}
                      aria-label="directions"
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Paper>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Affiliate;
