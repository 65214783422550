import React from "react";

import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useParams, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import InfluencerService from "../Api/Influencer";
import AuthService from "../Layouts/Login/services";
import ClientRequests from "../Api/ClientRequests";
import Avatars from "./Influencer/Parts/Avatars";
import Button from "@mui/material/Button";
import StarIcon from "@mui/icons-material/Star";
import Avatar from "@mui/material/Avatar";

import CoverImages from "./Influencer/Parts/CoverImages";
import Platforms from "./Influencer/Parts/Platforms";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import SendIcon from "@mui/icons-material/Send";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const UserProfile = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [box, setBox] = React.useState(-1);
  let { username } = useParams();
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  const [pictures, setPictures] = React.useState([]);
  const [platforms, setPlatforms] = React.useState(null);
  const [pls, setPls] = React.useState(null);

  const [profile, setProfile] = React.useState(null);
  const [categories, setCategories] = React.useState(null);
  const [utilisateur, setUtilisateur] = React.useState(true);
  const [exists, setExists] = React.useState(false);
  const [ratings, setRatings] = React.useState(null);
  // Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (currentUser === null) {
      navigate("/");
    } else {
      ClientRequests.get_token("home/platforms/", currentUser.auth_token).then(
        (res) => {
          setPls(res);
        }
      );
      ClientRequests.get_token(
        "home/message_proposition/" + username + "/",
        currentUser.auth_token
      ).then((res) => {
        console.log(res);
        setExists(true);
      });

      if (currentUser?.userinfos?.username === username) {
        navigate("/profile/influenceur");
      }
      InfluencerService.fetchData(
        "user/images/" + username + "/",
        currentUser?.auth_token
      )
        .then((response) => {
          let a = [];
          response.map((item, key) =>
            a.push(global.config.infos.image_url + item.image)
          );
          setPictures(a);
        })
        .catch((error) => {
          console.log(error);
        });

      InfluencerService.fetchData(
        "influencers/user/" + username + "/",
        currentUser?.auth_token
      )
        .then((response) => {
          setPlatforms(response);
        })
        .catch((error) => {
          console.log(error);
        });

      InfluencerService.fetchData(
        "user/profile/" + username + "/",
        currentUser?.auth_token
      )
        .then((response) => {
          setProfile(response);
        })
        .catch((error) => {
          console.log(error);
        });

      InfluencerService.fetchData(
        "influencers/category/" + username + "/",
        currentUser?.auth_token
      )
        .then((response) => {
          setCategories(response);
        })
        .catch((error) => {
          console.log(error);
        });

      InfluencerService.fetchData(
        "influencers/rating/" + username + "/",
        currentUser?.auth_token
      )
        .then((response) => {
          setRatings(response);
        })
        .catch((error) => {
          console.log(error);
        });
      window.scrollTo(0, 0);
    }
  }, [utilisateur]); // eslint-disable-line react-hooks/exhaustive-deps

  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleRegister = async (data) => {
    setLoading(true);
    setTimeout(async () => {
      const add = await ClientRequests.post_token(
        "home/message_proposition/" + username + "/",
        currentUser.auth_token,
        data
      );

      if (add.ok === true) {
        console.log(11111);

        setLoading(false);
        navigate("/messages/" + username);
      } else {
        setLoading(false);

        const reponse = await add.json();
        console.log(reponse);
        setErrors(reponse);
      }
      setLoading(false);
      console.log(333333);
    }, 2000);
  };

  return (
    <div>
      <Header onUserChange={setUtilisateur} />

      <Box
        sx={{
          marginTop: { md: "150px", xs: "50px" },
          display: { md: "block", xs: "none" },
        }}
      />
      <Container maxWidth="xl" sx={{ p: 0 }}>
        <Box sx={{ minHeight: "75vh" }}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ display: { md: "none", xs: "block" }, mb: 1 }}
          >
            <Item
              sx={{
                borderRadius: "5px",
                padding: { md: "0px 40px", xs: "5px" },
                overflow: "auto",
              }}
            >
              <br />
              <Avatars profile={profile} />
            </Item>
          </Grid>

          <CoverImages images={pictures} box={box} setBox={setBox} />

          <Grid container sx={{ mt: 2, p: 1 }}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                border: "1px solid #eee",
                boxShadow: "0px 0px 3px #e5e5e5",
              }}
            >
              <Item sx={{ textAlign: "left", p: 3, wordBreak: "break-word" }}>
                {categories?.map((item, key) => (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      width: "auto",
                      display: "inline-block",
                      color: "#000",
                      fontWeight: "900",
                      border: "1px solid #ddd",
                      borderRadius: "50px",
                      padding: "7px",
                      m: "2px",
                      background: "#eee",
                      whiteSpace: "nowrap",
                    }}
                    key={key}
                  >
                    {item.name}
                  </Typography>
                ))}
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: 4,
                    textAlign: "justify",
                    wordBreak: "break-word",
                  }}
                >
                  {profile?.introduction}
                </Typography>
              </Item>
            </Grid>

            <Grid item md={6} xs={12}>
              <Item
                sx={{
                  borderRadius: "5px",

                  overflow: "auto",
                }}
              >
                <br />
                <Box sx={{ display: { md: "block", xs: "none" }, p: 0 }}>
                  <Avatars profile={profile} />
                </Box>
                <Platforms platforms={platforms} />

                {exists === false ? (
                  <Button
                    sx={{ backgroundColor: "#00efa1", mt: 2, width: "100%" }}
                    variant="contained"
                    onClick={handleClickOpen}
                  >
                    PROPOSITION
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => navigate("/messages/" + username)}
                    sx={{ backgroundColor: "#00C0D9", mt: 2, width: "100%" }}
                  >
                    Message
                  </Button>
                )}
              </Item>
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
              sx={{ p: 3, mt: 4, boxShadow: "0px 0px 6px #eee" }}
            >
              <Typography variant="h5">Avis</Typography>
              {ratings === null
                ? "Aucun Avis, les nouveaux avis apparaîtront ici"
                : ratings.map((item, key) => (
                    <Box
                      key={key}
                      sx={{ p: 2, borderBottom: "1px solid #eee" }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          sx={{
                            borderRight: "1px solid #eee",
                            textAlign: { xs: "left", md: "center" },
                            pl: { xs: 2, md: 0 },
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ m: { xs: "normal", md: "auto" } }}
                            src={
                              global.config.infos.image_url +
                              item?.sender_details?.avatar
                            }
                          />
                          <Typography>
                            <strong>
                              {item.sender_details.first_name ||
                                item.sender_details.company}{" "}
                              {" " + item?.sender_details.last_name}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item md={10} xs={12} sx={{ paddingLeft: 2 }}>
                          <Typography>
                            {" "}
                            ({moment(item.created_at).format("LL")})<br />
                            {[...Array(item.rating)].map((e, i) => (
                              <StarIcon
                                sx={{
                                  fontSize: "1rem",
                                  color: "rgb(203 201 3)",
                                }}
                              />
                            ))}
                          </Typography>
                          <Typography>{item.review} </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
      {exists === false && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen={fullScreen}
          aria-labelledby="responsive-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Proposition de collaboration
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Container
                maxWidth="lg"
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #0DEDA3",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Item>
                      <TextField
                        id="standard-basic"
                        label="Titre"
                        helperText={errors?.title}
                        onInput={(e) =>
                          setValues({ ...values, title: e.target.value })
                        }
                        variant="standard"
                        fullWidth
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Item>
                      <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        helperText={errors?.description}
                        onInput={(e) =>
                          setValues({ ...values, description: e.target.value })
                        }
                        multiline
                        rows={6}
                        fullWidth
                      />
                    </Item>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Item>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Budget
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          onInput={(e) =>
                            setValues({ ...values, budget: e.target.value })
                          }
                          startAdornment={
                            <InputAdornment position="start">€</InputAdornment>
                          }
                          label="Budget"
                        />
                        <FormHelperText>{errors?.price}</FormHelperText>
                      </FormControl>
                    </Item>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Item>
                      <Autocomplete
                        multiple
                        id="multiple-limit-tags"
                        options={pls}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Plateformes"
                            placeholder="Ajouter.."
                          />
                        )}
                        sx={{ width: "100%" }}
                        onChange={(event, value) =>
                          setValues({
                            ...values,
                            platforms: value.map((item) => item.id),
                          })
                        }
                      />
                      <FormHelperText>{errors?.platforms}</FormHelperText>
                    </Item>
                  </Grid>

                  <Grid item xs={12}>
                    <Item
                      sx={{
                        textAlign: "end",
                      }}
                    ></Item>
                  </Grid>
                </Grid>
              </Container>
            </DialogContentText>
            <DialogActions sx={{ p: 0, mt: 2 }}>
              <Button
                sx={{
                  background: "#0DEDA3",
                  width: { d: "normal", xs: "50%" },
                }}
                onClick={handleClose}
                variant="contained"
              >
                Annuler
              </Button>
              <LoadingButton
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                onClick={() => handleRegister(values)}
                variant="contained"
                sx={{
                  background: "#0DEDA3",
                  width: { d: "normal", xs: "50%" },
                }}
              >
                Enregistrer
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default UserProfile;
