import * as React from "react";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Influncer from "../../assets/images/about/influncer.png";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import SecurityImage from "../../assets/images/security.jpg";
const influenceurs = [
  { id: 0, step: "Créez votre profil", color: "primary" },
  { id: 1, step: "Répertoriez vos services", color: "success" },
  {
    id: 2,
    step: "Personnalisé votre bio et partagez vos réseaux sociaux",
    color: "primary",
  },
  {
    id: 3,
    step: "Répondre aux propositions de collaboration des entreprises.",
    color: "success",
  },
  {
    id: 4,
    step: "postuler aux offres des entreprises sur la plate-forme.",
    color: "primary",
  },
  {
    id: 5,
    step: "Envoyer des contrats de collaboration",
    color: "success",
  },
  {
    id: 6,
    step: "Gérer vos contrats et suivre vos paiements",
    color: "primary",
  },
];

const entrepriseFirst = [
  { id: 0, step: "Recherchez parmi les influenceurs", color: "primary" },

  {
    id: 1,
    step: "Déposez directement une offre d'emploi sur la plate-forme",
    color: "success",
  },
  {
    id: 2,
    step: "Analyser les candidatures reçues sur votre messagerie",
    color: "primary",
  },
  {
    id: 3,
    step: "Sélectionnez le profil qui vous correspond",
    color: "success",
  },
  {
    id: 4,
    step: "Possibilité de faire une proposition de collaboration ",
    color: "primary",
  },
  {
    id: 5,
    step: "Régler vos contrats de collaboration en toute sécurité",
    color: "success",
  },
  {
    id: 6,
    step: "Gérer vos contrats et suivre vos paiements",
    color: "primary",
  },
];

const entrepriseSecond = [];

const Contact = () => {
  const [utilisateur, setUtilisateur] = React.useState(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <CssBaseline />
      <Header wishcounter={0} onUserChange={setUtilisateur} />
      <Box sx={{ marginTop: { xs: "50px", md: "150px" } }} />
      <Container maxWidth="xl">
        <Box sx={{ minHeight: "75vh" }}>
          <iframe
            src="https://www.youtube.com/embed/vsIVAgXi_XI?controls=0&autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ minHeight: "480px", width: "100%" }}
          ></iframe>
          <Typography
            variant="h5"
            textAlign="center"
            sx={{ p: 2, fontWeight: "800" }}
          >
            Qui sommes nous?
          </Typography>
          <Divider style={{ width: "20%", margin: "auto" }} />

          <Grid container>
            {/* offset grid with column you want to offset */}
            <Grid item md={1} sm={0} />
            {/* main grid column! */}
            <Grid
              item
              md={5}
              sm={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1" textAlign="justify">
                ADHOUSE est une plate-forme specialisée dans le marketing
                d'influence permettant de mettre en lien les influenceurs,
                créateurs de contenu et les entreprises ou à des particuliers
                souhaitant mettre en avant un projet.
                <br />
                Cette plate-forme permet de répondre à la demande des deux
                parties prenantes, les entreprises avec un large choix de profil
                afin de trouver la personne adaptée à sa campagne de
                communication, mais aussi les créateurs de contenu et
                influenceurs avec un listing des offres d'emploi et le fait de
                pouvoir mettre en avant leurs profils auprès des entreprises.
              </Typography>
            </Grid>

            <Grid item md={5} sm={12}>
              <img
                src={Influncer}
                alt="influenceur"
                style={{
                  width: "100%",
                }}
              />
            </Grid>

            <Grid item md={1} sm={0} />
            <Box textAlign="center" sx={{ width: "100%", p: 5 }}>
              <img src={SecurityImage} width={300} />
              <Typography
                variant="h5"
                textAlign="center"
                sx={{ p: 2, fontWeight: "800" }}
              >
                La sécurisation des paiements sur ADHOUSE
              </Typography>
              <Divider style={{ width: "20%", margin: "auto" }} />
              <Typography>
                Lorsqu'un paiements est effectué sur la plate-forme, ADHOUSE
                intervient en tant qu'intermédiaire entre l'entreprise et le
                créateur de contenu. Une fois que les deux parties on validé
                leur collaboration, l'argent est reversé automatiquement au
                destinataire. En cas de litige c'est ADHOUSE qui détermine en
                fonction du contrat et des éléments à disposition. <br />
                De plus ADHOUSE permet la sécurisation des paiements et la
                gestion des litiges.
              </Typography>
            </Box>

            <Box textAlign="center" sx={{ width: "100%", p: 5 }}>
              <Typography
                variant="h5"
                textAlign="center"
                sx={{ p: 2, fontWeight: "800" }}
              >
                Comment fonctionne ADHOUSE ?
              </Typography>
              <Divider style={{ width: "20%", margin: "auto" }} />
            </Box>

            <Grid
              container
              item
              md={12}
              sm={12}
              spacing="2"
              sx={{ p: 2, borderRadius: "10px" }}
            >
              <Grid
                item
                md={4}
                sm={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  LES INFLUENCEURS
                </Typography>
              </Grid>

              <Grid
                item
                md={8}
                sm={12}
                sx={{ p: 2, boxShadow: "0px 0px 2px #a0a0a0" }}
              >
                <Timeline position="alternate">
                  {influenceurs.map((item) => (
                    <TimelineItem key={item.id}>
                      <TimelineSeparator>
                        <TimelineDot color={item.color} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>{item.step}</TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>

                <Typography variant="body1" textAlign="center">
                  Les entreprises peuvent désormais découvrir votre profil et
                  acheter vos services
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              md={12}
              xs={12}
              spacing="2"
              sx={{ p: 2, mt: 2, borderRadius: "10px" }}
            >
              <Grid
                item
                md={0}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h6"
                  textAlign="left"
                  sx={{
                    fontWeight: "bold",
                    display: { md: "none", xs: "block" },
                  }}
                >
                  LES ENTREPRISES / LES PARTICULIERS
                </Typography>
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
                sx={{ p: 2, boxShadow: "0px 0px 2px #a0a0a0" }}
              >
                <Timeline position="alternate">
                  {entrepriseFirst.map((item) => (
                    <TimelineItem key={item.id}>
                      <TimelineSeparator>
                        <TimelineDot color={item.color} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>{item.step}</TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Grid>

              <Grid
                item
                md={4}
                xs={12}
                sx={{
                  display: { md: "flex", xs: "none" },
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h6"
                  textAlign="right"
                  sx={{
                    fontWeight: "bold",
                    display: { md: "block", xs: "none" },
                  }}
                >
                  LES ENTREPRISES / LES PARTICULIERS
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;
