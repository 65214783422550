import React from 'react'
import {useParams} from "react-router-dom";
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Success from './Success';
import Error from './Error';

const Verification = () => {
    // http://127.0.0.1:8000/api/v1/users/activation/
    let { uid,token } = useParams();
    const [activated, setActivated] = React.useState(false)
    const [show, setshow] = React.useState(false)
    React.useEffect( async ()=>{
        const res = await fetch(global.config.infos.server_url+'users/activation/', {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
            },
            body: JSON.stringify({
                uid:uid,
                token:token, 
              
            }),
          })
       if (res.ok === true){
       setActivated(true)
       }
       else{
        setActivated(false)
       }
       setshow(true)
    
    },[])  // eslint-disable-line react-hooks/exhaustive-deps

 


  return (
    <>
    <Box sx={{marginTop:'150px',display: { md: 'block', xs: 'none' }}} />
    <Box sx={{marginTop:'50px',display: { md: 'none', xs: 'block' }}} />
    {show===true &&
    <Container maxWidth="xl">
      {activated ? <Success  /> : <Error />}
    </Container>
    }
    </>

  )
}

export default Verification