import React from "react";
import Grid from "@mui/material/Grid";
import { Divider, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import jeun from "../../../../assets/images/partners/jeun.png";
import mbds from "../../../../assets/images/partners/mbds.png";
import ClientRequests from "../../../Api/ClientRequests";
import InfiniteCarousel from "react-leaf-carousel";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 100,
  lineHeight: "60px",
  background: "#eee",
}));
const Partner = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [partners, setPartners] = React.useState(null);
  React.useEffect(() => {
    ClientRequests.get_("home/partners/").then((data) => {
      setPartners(data);
    });
  }, []);
  console.log(partners);
  return (
    <Box sx={{ mt: { xs: 5, md: 15 } }}>
      <Grid container>
        {/* offset grid with column you want to offset */}
        <Grid item md={2} sm={0} />
        {/* main grid column! */}

        <Grid container spacing={1} md={8} sm={12}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h3"
              sx={{ p: 5, textAlign: "center" }}
            >
              Nos partenaires
            </Typography>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sm={12}
          sx={{ justifyContent: "center", mt: 3 }}
        >
          <Grid item xs={12} md={8}>
            {partners !== null ? (
              <InfiniteCarousel
                breakpoints={[
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 1,
                    },
                  },
                ]}
                dots={true}
                showSides={true}
                sideSize={0.1}
                slidesToScroll={1}
                autoCycle={true}
                cycleInterval={3000}
                slidesToShow={4}
                scrollOnDevice={true}
              >
                {partners.map((item) => (
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <Box
                      sx={{
                        border: "1px solid #eee",
                        borderRadius: "10px",
                        height: "100px",
                        backgroundImage:
                          "url(" +
                          global.config.infos.image_url +
                          item.logo +
                          ")",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundColor: "#00efa1",
                        "&:hover": {
                          backgroundColor: "rgba(0,0,0,0.4)",
                        },
                      }}
                    ></Box>
                  </a>
                ))}
              </InfiniteCarousel>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item md={2} sm={0} />
      </Grid>
    </Box>
  );
};

export default Partner;
