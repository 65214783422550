import React from "react";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ClientRequests from "../Api/ClientRequests";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Legale = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [utilisateur, setUtilisateur] = React.useState(null);

  return (
    <React.Fragment>
      <CssBaseline />
      <Header wishcounter={0} onUserChange={setUtilisateur} />
      <Container maxWidth="lg" sx={{ mt: 15 }}>
        <h3>Mentions légales</h3>
        <p>
          Le site Adhouse est inscrit au registre du commerce et des sociétés
          sous le numéro 843 828 46800010.Directeur de publication:Monsieur ADJI
          MOULERICHE, Adhouse 21 SQ ANTOINE WATTEAU, 60100 Creil Nous contacter
          : ontact@adhouse.com Le site Internet www.adhouse.fr est réalisé et
          édité par MBD www.mbdsolution.fr. Le Site est hébergé par la société
          IONOS SARL, immatriculée au registre du commerce et des sociétés de
          Sarreguemines sous le numéro 431 303 775, dont le siège social est
          situé au 7, place de la Gare, BP 70109, 57200 Sarreguemines Cedex,
          téléphone : 0970 808 911.
        </p>
        <p>
          La société IONOS est en accord avec les principes RGPD de l’Union
          européenne, ainsi que toutes les législations concernant la sécurité.
        </p>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Legale;
