import React from "react";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ClientRequests from "../Api/ClientRequests";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Cgu = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [utilisateur, setUtilisateur] = React.useState(null);
  return (
    <React.Fragment>
      <CssBaseline />
      <Header wishcounter={0} onUserChange={setUtilisateur} />
      <Container maxWidth="lg" sx={{ mt: 15 }}>
        <>
          <p>
            <strong>Conditions générales d’utilisation</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Bienvenue sur le site web&nbsp;{" "}
            <a href="http://www.adhouse.fr/">www.adhouse.fr</a> qui permet de
            mettre en lien <strong>les influenceurs</strong>, créateurs de
            contenu avec <strong>les entreprises</strong> des particuliers
            souhaitant mettre en avant un projet.
          </p>
          <ul>
            <li>
              Entreprises, particulier, institutions ou destinataires des
              Services (la « Marque ») ; et
            </li>
            <li>
              Les influenceurs chez Adhouse (l ’ « Influenceur »), rencontrent
              les marques, communiquent et travaillent ensemble de façon
              optimale&nbsp;;
            </li>
            <li>Marque, influenceur (profil).</li>
          </ul>
          <p>
            Pour utiliser le site web fourni par Adhouse, vous devez lire et
            accepter les conditions générales. Dans le cas contraire, vous devez
            cesser l’utilisation de la plateforme ou tous autres produits
            associés. Des modifications des conditions d’utilisation peuvent
            être apportées, Adhouse vous informera dès leur publication, date de
            leur entrée en vigueur.
          </p>
          <ol>
            <li>
              <strong>Acceptation des conditions</strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>
            En vous inscrivant au service, vous acceptez les conditions
            d’utilisation des services. Adhouse met également à votre
            disposition une option vous permettant d’accepter les conditions
            générales et notre politique de confidentialité dans l’interface
            utilisateur.
          </p>
          <ul>
            <li>Les services&nbsp;:</li>
          </ul>
          <ul>
            <li>
              Pour accéder aux Services, les utilisateurs (Marque et
              Influenceur) doivent créer un compte via la plateforme (le «
              Compte »).
            </li>
            <li>
              Dans le cadre du processus d'inscription, ou dans le cadre de
              votre utilisation continue des Services, il peut vous être demandé
              de fournir des informations personnelles vous concernant (telles
              que des informations d'identification ou vos coordonnées),
              notamment :
            </li>
          </ul>
          <p>
            Il s’agit d’informations variables et dépendent de votre profil :
          </p>
          <ul>
            <li>Adresse e-mail ;</li>
            <li>Nom d'utilisateur ;</li>
            <li>-Mot de passe ;</li>
            <li>Nom et prénom ;</li>
            <li>Ville ;</li>
            <li>Code postal ;</li>
            <li>Images, photo et Avatar ;</li>
            <li>Description pour les influenceurs ;</li>
            <li>Catégories pour les influenceurs</li>
            <li>Liens de vos réseaux sociaux et/ou vos identifiants ;</li>
            <li>Nom de l’entreprise&nbsp;</li>
            <li>Numéro de SIREN pour les entreprises ;</li>
            <li>Non de l’entreprise</li>
            <li>Détails de l'entreprise.</li>
            <li>Vos offres de service ;</li>
            <li>
              Vos préférences d’utilisation des services que nous proposons.
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            Durant le processus d’inscription,&nbsp;vous garantissez
            l’exactitude des informations fournies, ainsi que leurs mises à
            jour.
          </p>
          <p>
            À l’issue du processus d’inscription votre profil sera enregistré,
            ceci vous sera notifié et confirmé par e-mail.&nbsp;&nbsp;&nbsp;
          </p>
          <p>
            Vous pouvez bénéficier de nos services sous condition expresse
            d’avoir l'âge minimum légal permettant de conclure un contrat, sauf
            mention particulière dûment justifiée.
          </p>
          <p>&nbsp;</p>
          <ol start={2}>
            <li>
              <strong>Vos obligations en tant que profil</strong>
            </li>
          </ol>
          <p>
            En tant que profil, vous acceptez de vous conformer à ce qui en vous
            inscrivant vous acceptez ce qui suit :&nbsp;-Vous n'utiliserez les
            Services Adhouse qu'à des fins autorisées par les Conditions
            générales d’utilisation, ainsi que toute loi, réglementation,
            pratique ou directive généralement acceptée dans la juridiction
            concernée.
          </p>
          <ul>
            <li>
              Les informations d’authentification sont strictement personnelles,
              l’utilisation par d’autres personnes est prohibée peut entraîner
              l'annulation et/ou la suppression immédiate des services, en
              revanche si vous constatez une utilisation de vos données de
              connexion frauduleuse, vous vous engagez en informer
              Adhouse&nbsp;;
            </li>
            <li>
              Vous ne devez sous aucun prétexte utiliser le profil de quelqu’un
              d’autre.
            </li>
            <li>
              Assurez-vous que le contenu que vous diffusez est toujours à jour.
            </li>
            <li>
              L’usurpation d'identité, les menaces, le harcèlement d’un autre
              profil sont strictement interdit ;
            </li>
            <li>
              L'accès et l'utilisation du site Web sont limités, non
              transférables et vous permettent d'utiliser le site Web uniquement
              dans le but de fournir les services ;&nbsp;
            </li>
            <li>
              Vous n'utiliserez pas les Services ou le Site Web dans le cadre
              d'activités commerciales, à l'exception de celles qui sont
              spécifiquement approuvées ou approuvées par la direction Adhouse ;
            </li>
            <li>
              Les autres activités commerciales autre que celles définies par
              Adhouse ne sont pas autorisées ;
            </li>
            <li>
              Il est interdit d’utiliser les informations contenues dans les
              profils à des fins de prospection (dont la collecte par des moyens
              électroniques) non-autorisé par Adhouse&nbsp;;
            </li>
            <li>
              Vous acceptez que toute utilisation automatisée du site Web ou de
              ses services soient interdits.
            </li>
            <li>
              Toute diffusion de publicité est interdite et sera automatiquement
              supprimée par Adhouse.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ol start={3}>
            <li>
              <strong>Utilisation du site Web en tant que marque</strong>
            </li>
          </ol>
          <p>Les marques doivent fournir les informations suivantes :</p>
          <ul>
            <li>Adresse e-mail ;</li>
            <li>Nom d'utilisateur ;</li>
            <li>Mot de passe ;</li>
            <li>Nom et prénom ;</li>
            <li>Ville ;</li>
            <li>Code postal ;</li>
            <li>Avatar ;</li>
            <li>Nom de l’entreprise&nbsp;</li>
            <li>Numéro de SIREN pour les entreprises ;</li>
            <li>Secteur d’activité.</li>
            <li>Vos offres de collaboration.</li>
          </ul>
          <p>&nbsp;</p>
          <p>
            Les marques diffusent leurs offres de collaboration qui seront
            publiées sur Adhouse.
          </p>
          <p>
            En aucun cas la responsabilité d’Adhouse ne peut être engagée
            vis-à-vis de la prestation d’un influenceur, si un Influenceur
            accepte une offre de collaboration d'une marque, Adhouse n’est pas
            garant du bon déroulement du contrat.&nbsp;
          </p>
          <p>
            Le rôle d’Adhouse est limité dans la mise en relation des
            influenceurs et des marques.&nbsp;
          </p>
          <p>&nbsp;</p>
          <ol start={4}>
            <li>
              <strong>Utilisation du site Web en tant qu’influenceur</strong>
            </li>
          </ol>
          <p>Les influenceurs doivent fournir les informations suivantes :</p>
          <ul>
            <li>Adresse e-mail ;</li>
            <li>Nom d'utilisateur ;</li>
            <li>Mot de passe ;</li>
            <li>Nom et prénom ;</li>
            <li>Ville ;</li>
            <li>Code postal ;</li>
            <li>Images, photo et Avatar ;</li>
            <li>Description de votre domaine d’activité ;</li>
            <li>Catégories&nbsp;;&nbsp;</li>
            <li>Liens de vos réseaux sociaux et/ou vos identifiants.</li>
          </ul>
          <p>
            Les Influenceurs postulent pour collaborer et fournir des Services
            aux Marques.
          </p>
          <p>
            Si l'Influenceur accepte une offre de collaboration avec la Marque,
            il doit fournir les Services comme convenu entre les parties.&nbsp;
          </p>
          <p>
            Adhouse ne peut être rendu responsable en cas de difficultés, le
            rôle d’Adhouse est limité à la mise en relation entre les
            influenceurs et les marques.
          </p>
          <ul>
            <li>
              En aucun cas la responsabilité d’Adhouse ne peut être engagée
              vis-à-vis du contrat et de ses conséquences proposées par une
              marque, si une marque accepte une collaboration avec un
              influenceur, Adhouse n’est pas responsable du bon déroulement du
              contrat. Le rôle d’Adhouse est limité dans la mise en relation des
              influenceurs et des marques.&nbsp;
            </li>
          </ul>
          <p>&nbsp;</p>
          <ol start={5}>
            <li>
              <strong>Fonctionnalité de chat&nbsp;</strong>
            </li>
          </ol>
          <p>
            Tous les profils peuvent échanger dans l’espace dédié à cet effet à
            conditions que la marque accepte la collaboration entre influenceur
            et marque après une acceptation de l’offre&nbsp;:
          </p>
          <ul>
            <li>entre influenceur les messages sont possibles ;</li>
            <li>
              en cas de litige l'équipe Ad house peut accéder à tous moments aux
              messages en direct.&nbsp;&nbsp;
            </li>
            <li>
              Les fonctionnalités de chat incluent les textes, les fichiers, les
              photos et chaque chat est lié à une collaboration, sauf les chats
              entre influenceurs.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ol start={6}>
            <li>
              <strong>
                Processus de paiement et règlement des litiges entre les membres
              </strong>
            </li>
          </ol>
          <p>
            <strong>Article 1 : L'intermédiaire de paiement</strong>
          </p>
          <p>
            Ad house utilise Strippe (mode express) qui est une passerelle
            sécurisant les paiements entre les utilisateurs (influenceur et
            marque) du site web qui possède son propre processus de sécurité et
            qui permet un fonctionnement optimal.
          </p>
          <p>
            Pour les comptes des influenceurs, Strippe se chargera de
            l'inscription, de la gestion des comptes et de la vérification de
            l'identité pour le compte d’Adhouse.
          </p>
          <p>
            La création d’un compte Strippe est indispensable afin de créer des
            contrats et enregistrer des paiements.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Article 2 : Étapes d’une collaboration</strong>
          </p>
          <p>
            La marque qui souhaite démarrer une collaboration fait sa
            proposition à travers le bouton “Proposition” de l’interface
            influenceur, elle complète le formulaire de collaboration qui sera
            envoyé à l’influenceur, après acceptation de la proposition, les
            échanges commencent entre les deux parties.&nbsp;
          </p>
          <p>À la suite de la prestation voici les étapes qui suivent :</p>
          <ul>
            <li>&nbsp; Création du contrat par l’influenceur ;</li>
            <li>
              &nbsp; paiement par la marque (conservé "en attente"), versé à la
              fin de la prestation, sous 24 heure après validation par la marque
              à l'exception du premier versement qui nécessite un délai de 7 à
              14 jours après réception effective de votre premier paiement ;
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              &nbsp; &nbsp; Validation de la prestation par l’influenceur à
              travers le bouton "prestation terminée » ;&nbsp;&nbsp;
            </li>
            <li>
              &nbsp; &nbsp; Validation de la prestation par la marque à travers
              le bouton "valider la prestation", les fonds seront débloqués et
              ne pourront faire l’objet d’un remboursement. NB cette action doit
              être réalisée dans un délai maximum de 5 jours, au-delà les fonds
              seront automatiquement transférés sur le compte de l'influenceur.
              En cas de problème relatifs à l’exécution de la prestation,
              l’influenceur seul peut annuler la collaboration sur demande de la
              marque. Si vous n’arrivez pas à trouver un arrangement, vous
              pouvez dénoncer un litige.
            </li>
            <li>
              &nbsp; Gestion des litiges : la politique d’Ad house favorise les
              arrangements à l’amiable et encourage les utilisateurs à régler
              les litiges entre eux. En cas de désaccords persistants, l'équipe
              d'assistance Ad house intervient afin de gérer et de clôturer le
              litige.&nbsp;
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>
            <strong>Article 3 : Le litige</strong>
          </p>
          <p>
            Le litige est transmis avec les commentaires et les photos jointes
            des utilisateurs. Le centre de résolution d’Ad house tranchera en
            fonction des éléments reçus en faveur d'un remboursement de la
            marque (total ou partiel), ou d'un paiement à l’influenceur (total
            ou partiel).
          </p>
          <p>
            La décision de l'équipe d'assistance d’Adhouse est définitive et ne
            peut être contestée ou faire l'objet d'un appel.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Article 4 : Paiement</strong>
          </p>
          <p>&nbsp;</p>
          <p>
            Les paiements par carte bancaire gérée par la plateforme de paiement
            « Strippe ». vous garantissez que vous avez lu, compris et accepté
            d'être lié par les conditions générales de Strippe qui sont
            disponibles sur leur site Web. L’acceptation bancaire est faite
            directement par Strippe, aucune commande ne pourra être validée sans
            que le Site n’ait eu cette acceptation.
          </p>
          <p>&nbsp;</p>
          <p>
            Ad House ne peut être tenu responsable d'aucun litige d’aucune sorte
            ou défaut de paiement. Ad House est une plateforme qui met en
            relation les influenceurs et les marques, sa responsabilité ne peut
            en aucun cas être mise en cause.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Article 5 : Commission et facturation</strong>
          </p>
          <p>&nbsp;</p>
          <p>Pour la marque :</p>
          <p>
            Lors de la facturation (paiement de la collaboration), des frais
            bancaires seront applicables, ils représentent 1,5 % du montant TTC
            +0,25 €. Ces frais servent à financer nos partenaires bancaires.
          </p>
          <p>&nbsp;</p>
          <p>Pour l’influenceur :</p>
          <p>La commission Ad House représente :&nbsp;</p>
          <ul>
            <li>
              10% du montant TTC si la prestation est{" "}
              <strong>inférieure à</strong> 1000 € TTC ;
            </li>
            <li>
              7% du montant TTC si la prestation est{" "}
              <strong>supérieure ou</strong> <strong>égale à </strong>1000 € TTC{" "}
              <strong>et</strong> <strong>inférieur</strong> <strong>à</strong>{" "}
              5000€ TTC ;
            </li>
          </ul>
          <p>
            1000 € TTC <strong>&gt;=</strong>{" "}
            <strong>montant de la prestation TTC</strong> <strong>&lt;</strong>{" "}
            5000 € TTC ;
          </p>
          <ul>
            <li>
              5% du montant TTC si la prestation est{" "}
              <strong>supérieure ou</strong> <strong>égale à</strong> 5000 €
              TTC.
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>
            Il vous appartient de déclarer l’ensemble de vos revenus générés sur
            adhouse.fr quel que soit votre statut (particulier,
            auto-entrepreneur ou entreprise). Vous pouvez vous faire accompagner
            dans vos démarches auprès des institutions compétentes.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Article 4 : Politique de remboursement</strong>
          </p>
          <p>
            Ad house ne pourra en aucune façon procéder au remboursement d'une
            marque à la suite d’une commande, quel que soit son statut.
          </p>
          <p>
            Il est aussi rappelé qu'au titre de l’article L221-28 du Code de la
            consommation, le droit de rétractation ne peut être exercé pour les
            contrats de fourniture d’un contenu numérique non fourni sur un
            support matériel dont l’exécution a commencé après accord préalable
            exprès du consommateur.&nbsp; Ainsi, vous renoncez expressément à
            votre droit de rétractation et de ce fait de remboursement.
          </p>
          <p>
            Pour obtenir un remboursement, le Client, lorsqu'une commande est en
            cours, devra utiliser l'annulation mutuelle décrite à l'article 2
            Étapes d’une collaboration rubrique litige.
          </p>
          <p>
            Lorsque la marque validera la prestation, les fonds seront débloqués
            au profil de l’influenceur et ne pourront plus faire l’objet d’un
            remboursement.
          </p>
          <p>&nbsp;</p>
          <p>
            Ad house met en relation les Marques et les influenceurs, facilite
            leur collaboration toutefois sa responsabilité ne peut être engagée
            en cas de désaccord entre les différents utilisateurs. En effet,
            aucun remboursement de la part d’Ad house ne pourra être exigé.
          </p>
          <p>&nbsp;</p>
          <ol start={7}>
            <li>
              <strong>Tableau de bord</strong>
            </li>
          </ol>
          <p>
            Tous les profils ont un tableau de bord pour suivre leurs contrats.
            Les fonctionnalités de ce tableau de bord incluent des filtres,
            profil de collaborant, titre de la prestation, date de la
            prestation, budget, et état de la prestation (terminée, en cours ou
            annuler) ;
          </p>
          <p>&nbsp;</p>
          <ol start={8}>
            <li>
              <strong>Gain</strong>
            </li>
          </ol>
          <p>
            Espace dédié aux influenceurs pour transférer leurs fonds à leur
            (Wallet) Strippe avec un bouton d’accès à leur compte strippe
            <strong>&nbsp;</strong>
          </p>
          <p>&nbsp;</p>
          <ol start={9}>
            <li>
              <strong>Les étapes d’une collaboration</strong>
            </li>
          </ol>
          <p>Ils sont au nombre de trois :</p>
          <p>
            -"Proposition envoyée" signifiant que la marque a fait une offre de
            collaboration à un influenceur ;
          </p>
          <p>
            - "Lancer la discussion" signifiant que l'influenceur a initié le
            début d'une collaboration et que la discussion est ouverte entre
            eux.
          </p>
          <p>
            -”Contrat” signifiant les clauses qui comprennent le prix, la cible
            du travail, la durée et la ville), sur la base de ce contrat
            l’influenceur et la marque doivent respecter ses clauses.
          </p>
          <ol start={10}>
            <li>
              <strong>Frais d'inscription</strong>
            </li>
          </ol>
          <p>
            L'accès et l'utilisation du site web sont sans frais. Adhouse se
            réserve le droit de modifier les modalités d’accès à son site sans
            préavis.
          </p>
          <ol start={11}>
            <li>
              <strong>
                Fonctionnalités et &nbsp; Critères de classement des offres :
              </strong>
            </li>
          </ol>
          <ul>
            <li>Pour les influenceurs :</li>
          </ul>
          <p>
            Le classement des influenceurs est déterminé par le nombre des avis
            des différents réseaux sociaux sauf Facebook, puis par le nombre des
            abonnées.&nbsp;
          </p>
          <p>
            La recherche peut être affinée grâce à la barre de recherche
            soit&nbsp;:&nbsp;
          </p>
          <ul>
            <li>
              Par plateforme (les différents réseaux sociaux)&nbsp;;&nbsp;
            </li>
            <li>Catégories (Food, voyage…)&nbsp;;</li>
            <li>Nombre d’abonnés&nbsp;;</li>
            <li>
              La recherche multicritère qui contient (Ville, nom, catégorie et
              plateforme)&nbsp;&nbsp;&nbsp;
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Pour la marque :</li>
          </ul>
          <p>
            Le classement des offres de collaboration est déterminé par le
            nombre d’avis, puis par la date de publication.
          </p>
          <p>
            La recherche peut être affinée grâce à la barre de recherche
            soit&nbsp;:
          </p>
          <ul>
            <li>
              Par plateforme (les différents réseaux sociaux)&nbsp;;&nbsp;
            </li>
            <li>Ville&nbsp;;&nbsp;</li>
            <li>Nombre d’abonnés&nbsp;;&nbsp;</li>
            <li>
              la recherche multicritère qui contient (Ville, nom, entreprise et
              plateforme).
            </li>
          </ul>
          <p>&nbsp;</p>
          <ol start={12}>
            <li>
              <strong>Politique concernant les avis profils :</strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ul>
            <li>Typologie des avis</li>
          </ul>
          <p>
            Les avis des profils sur le site permettent d’évaluer de manière
            objective / subjective une marque et/ou un influenceur et, ce
            faisant, de conseiller et de guider au mieux les autres profils dans
            leurs recherches mais également de permettre aux profils d’améliorer
            les différents services.
          </p>
          <p>
            Les avis sont constitués d’une note et/ou d’un commentaire. La note
            est donnée par un profil sur une échelle de 1 à 5 (1 étant la note
            la plus faible et 5 étant la note maximale).&nbsp;&nbsp;
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>Modalités de dépôt d’un avis par un profil</li>
          </ul>
          <p>
            Le profil qui souhaite déposer un avis portant sur une prestation
            peut :
          </p>
          <ul>
            <li>
              Depuis l’espace dédié à la discussion (message), choisir le profil
              à évaluer et cliquer sur « Avis ». Une note sera donnée au profil
              ainsi qu’un commentaire texte libre et enfin cliquez&nbsp; sur «
              Envoyer ».&nbsp;&nbsp;
            </li>
            <li>
              Chaque profil peut émettre un avis après échange d’au moins 5
              messages. Un seul avis peut être exprimé, avec un droit de réponse
              pour le profil concerné.&nbsp;
            </li>
            <li>
              Le profil sera sensible aux obligations suivantes lors de la
              rédaction et prend l'engagement de ce qui suit :
            </li>
            <li>
              Tenir des propos faux, discriminatoire, violent, raciste ou tout
              autre comportement nuisible ou incitant au comportement
              inadapté&nbsp;;
            </li>
            <li>
              Il n’est pas permis de citer d’autre site web ou d'intégrer des
              liens hypertexte qui renvoient vers ces sites&nbsp;;
            </li>
            <li>
              l’avis ne doit pas contenir de données à caractère
              personnel&nbsp;;
            </li>
            <li>
              Il ne doit pas être utilisé à des fins personnelles ou
              professionnelles ainsi que professionnelles ou
              publicitaires&nbsp;;
            </li>
            <li>
              L’avis ne doit pas contenir de violation de droits d’auteur, de
              brevet, marque ou secret commercial et doit être libre de tous
              droits de propriété, de publicité ou de confidentialité&nbsp;;
            </li>
            <li>
              Le profil doit publier l’avis sans avoir perçue de rémunération
              sous aucune forme&nbsp;;
            </li>
            <li>
              L’avis doit être rédigé dans le strict respect de toute
              réglementation en vigueur&nbsp;;
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Modération des avis&nbsp;</li>
          </ul>
          <p>
            Les avis contenant des propos illicites sont automatiquement rejetés
          </p>
          <p>
            Les avis suivants sont soumis à analyse, Adhouse ne filtre pas les
            avis, qu’ils soient positifs ou négatifs, ils sont publiés dans leur
            intégralité.&nbsp;
          </p>
          <ul>
            <li>Conservation et modalité de publication des avis&nbsp;</li>
          </ul>
          <p>
            Le délai de publication et de conservation des avis est égale à 12
            mois à compter de la date de leur publication.
          </p>
          <ul>
            <li>Classement des avis</li>
          </ul>
          <p>
            Le classement par ordre chronologique est appliqué cependant, les
            avis peuvent également être classés par les utilisateurs.
          </p>
          <ul>
            <li>Signalement des avis</li>
          </ul>
          <p>
            Les avis non conformes à la politique relatives à la publication des
            avis Adhouse peuvent et doivent être signalés.
          </p>
          <ol start={13}>
            <li>
              <strong>Droit d’auteur et propriété intellectuelle</strong>
            </li>
          </ol>
          <p>
            En acceptant les conditions générales d’utilisation, vous consentez
            à ce que Adhouse collecte des images et textes fournis sur son site
            web.
          </p>
          <p>
            Le contenu du site Adhouse est protégé par le droit d’auteur en
            vigueur en France et à l’étranger. Tout le contenu sans exception et
            sauf mention contraire est protégé par les droits d’auteur.&nbsp;
          </p>
          <p>
            Tous les noms commerciaux sont détenus, enregistrés et/ou concédés
            sous licence par Adhouse qui vous accorde une licence mondiale, non
            exclusive, libre de droits et révocable à tous moments pendant que
            vous êtes profil pour :
          </p>
          <ul>
            <li>
              Utiliser le site web en application des conditions générales
              d’utilisation&nbsp;;
            </li>
            <li>
              Copier, stocker le site web et son contenu dans la mémoire cache
              de votre appareil et imprimer si besoin les pages que vous
              souhaitez utiliser dans le cadre personnel et non
              commercial&nbsp;;
            </li>
            <li>
              Adhouse ne vous accorde aucun autre droit relatif au site web ou à
              ses services annexes, ceux-ci sont exclusivement réservés à
              Adhouse &nbsp; qui conserve tous les droits, titres et intérêts
              sur le site web et services annexes.
            </li>
            <li>
              L’utilisation d’un système automatisé afin d’extraire des données
              du site à des fins de reproduction, d’indexation, duplication,
              copie, vente/revente entre autres est formellement
              interdite.&nbsp;
            </li>
            <li>
              Vous consentez ici à ce que le site web bénéficie d’une licence
              mondiale, non exclusive, transférable sans limitation dans le
              temps, libre de droits pour diffuser, republier, télécharger
              transmettre distribuer, diffuser en public, adapter ou modifier
              votre contenu.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ol start={14}>
            <li>
              <strong>Intimité</strong>
            </li>
          </ol>
          <p>
            La protection de votre vie privée est un axe majeur pour Adhouse
            ainsi toutes les informations que vous fournissez par le biais du
            site sont soumises à la politique de confidentialité d’Adhouse.
          </p>
          <p>
            <br />{" "}
          </p>
          <ol start={15}>
            <li>
              <strong>Clause de non-responsabilité générale</strong>
            </li>
          </ol>
          <p>
            Aucun élément dans les Conditions ne permet d’exclure les garanties,
            représentations ou conditions imposées par la loi y compris les lois
            françaises pour la consommation qui, en vertu de la législation ne
            peut être limitée ou exclue.
          </p>
          <p>Par la présente clause et dans le cadre de la loi :</p>
          <ul>
            <li>
              Toutes les dispositions qui ne sont pas citées dans les Conditions
              sont formellement exclues et Adhouse ne pourra en aucun cas être
              rendu responsable de perte ou dommage éventuel quel qu’il soit
              sauf si le dommage était prévisible et prévu dans une clause de
              garantie utilisateur.
            </li>
          </ul>
          <p>
            -Vous êtes seul responsable de l’utilisation du site web, ceci en
            toute connaissance de cause, sans garantie ou condition.&nbsp;
          </p>
          <ul>
            <li>
              Aucun des abonnées, administrateurs, dirigeants, employés,
              sous-traitant, contributeurs ne fait de représentation ou de
              garantie expresse ou implicite concernant les Services ou tout
              produit ou service (y compris les produits ou services d’Adhouse)
              mentionnés sur le site web, y compris et sans limite les dommages
              suivants :
            </li>
            <li>
              défaut d'exécution, erreur, omission, interruption, suppression,
              problème technique comme le retard de fonctionnement ou de
              transmission, virus informatique, perte de données, défaillance de
              la ligne de communication, conduite illégale d'un tiers, la
              modification ou l'accès non autorisé aux dossiers.
            </li>
            <li>Vous acceptez le principe suivant&nbsp;:</li>
          </ul>
          <p>
            Adhouse est une plateforme destinée aux interactions entre des
            entreprises et des clients potentiels mais aussi entre les marques
            et les influenceurs. Adhouse ne peut être rendu responsable du
            comportement inadapté d’un influenceur ou d’une entreprise vis à vis
            de vous ou encore d’une utilisation abusive de votre contenu.
          </p>
          <p>
            L’exactitude du contenu d’un profil marque ou influenceur ne peut
            être
          </p>
          <p>garantie par Adhouse.</p>
          <p>
            <u>Mise à jour&nbsp;: 03/05/2023</u>
          </p>
          <p>&nbsp;</p>
        </>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Cgu;
