import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Platforms = ({ platforms }) => {
  return (
    <Stack
      sx={{ display: "inline-block", textAlign: "center", width: "100%" }}
      spacing={2}
    >
      <Box
        sx={{
          display: "inline-block",
          textAlign: "center",
          flexWrap: "wrap",
          width: "100%",
          flexDirection: "row-reverse",
          "& > :not(style)": {
            m: "2px",
            width: "100%",
            height: "auto",
          },
        }}
      >
        {platforms?.map((item, key) => (
          <Box
            sx={{
              width: "100%",
              textAlign: "left",
              boxShadow: "0px 0px 3px #ddd",
              padding: "5px",
            }}
          >
            <Grid container md={12}>
              <Grid
                item
                md={6}
                xs={6}
                sx={{ borderRight: "1px solid #ddd", marginTop: "3px" }}
              >
                <a
                  target="_blank"
                  key={item.logo}
                  style={{ textDecoration: "none" }}
                  href={item.url}
                >
                  <img
                    src={global.config.infos.image_url + item.logo}
                    alt={key}
                    style={{ width: "25px", height: "25px", marginTop: "5px" }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      marginLeft: "5px",
                      fontSize: "17px",
                      position: "relative",
                      top: "-6px",
                    }}
                  >
                    {item.platform_name}
                  </Typography>
                </a>
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
                sx={{ textAlign: "right", padding: "4px" }}
              >
                <Typography sx={{ lineHeight: "2" }}>
                  <strong>{item.views}</strong> Abonnés
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};
export default Platforms;
