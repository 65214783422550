import React from 'react'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Error = () => {


  return (
    <Stack  sx={{inlineSize: 'fit-content', margin:'auto' }} spacing={2}>
    <Alert variant="filled" icon={false} severity='error' sx={{

        textAlign:'center',
        p:5
    }}>
        
        <Typography variant='h4'>Erreur</Typography><br />
        <Typography variant='subtitle1'>Ce compte a bien été déja verifier ou vous avez utilisé un lien erroné — <strong> Verifiez votre email</strong></Typography>
      </Alert>
      </Stack>
  )
}

export default Error