import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import SellIcon from "@mui/icons-material/Sell";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
const Progress = ({ contrat }) => {
  return (
    <Box
      sx={{
        padding: "5px",
        border: "2px solid #d1d1d1",
        borderRadius: "15px",
        width: "fit-content",
      }}
    >
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          columnSpacing={1}
        >
          <Grid item xs={12} md={2} sx={{ textAlign: "center" }}>
            <HourglassBottomIcon
              sx={{
                color: "#00C0D9",
              }}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography textAlign="center">Période</Typography>

            <Typography textAlign="center" sx={{ fontWeight: 900 }}>
              {contrat === "Pending" && "En cours"}
              {contrat === "Completed" && "Terminer"}
              {contrat === "Cancelled" && "Annuler"}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Progress;
