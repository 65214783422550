import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Checkbox from "@mui/material/Checkbox";
import Login from "../../Layouts/Login/Login";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ClientRequests from "../../Api/ClientRequests";

// function kFormatter(num) {
//   return Math.abs(num) > 999
//     ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
//     : Math.sign(num) * Math.abs(num);
// }
var unitlist = ["", "K", "M", "G"];
function kFormatter(number) {
  let sign = Math.sign(number);
  let unit = 0;

  while (Math.abs(number) >= 1000) {
    unit = unit + 1;
    number = Math.floor(Math.abs(number) / 100) / 10;
  }
  return sign * Math.abs(number) + unitlist[unit];
}

const Profile = ({
  user,
  AddWishlist,
  wishlist,
  utilisateur,
  more,
  filter,
}) => {
  const [loggedin, setLoggedin] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const handleAddwishlist = (influencer) => {
    ClientRequests.post_token("influencers/wishlist/", utilisateur.auth_token, {
      influencer: influencer,
    })
      .then((result) => result.json())
      .then((response) => {
        setChecked(response.wishlist);
        AddWishlist(response.wishlist);
      });
  };
  React.useEffect(() => {
    if (utilisateur !== null) {
      setLoggedin(true);
      ClientRequests.post_token(
        "influencers/userwishlist/",
        utilisateur.auth_token,
        { influencer: user.id }
      ).then((result) => {
        setChecked(result.ok);
      });
    } else {
      setLoggedin(false);
    }
  }, [utilisateur, checked]);
  var wl = [{}];
  var uid = 0;
  if (loggedin) {
    wl = wishlist.filter(
      (list) =>
        (list.user === utilisateur?.userinfos.id) &
        (list.influencer.id === user.id)
    );
    uid = utilisateur?.userinfos.id;
  }
  const first = {
    transition: "transform .5s, filter 1s ease-in-out",
    filter: "grayscale(0)",
    minWidth: { xs: "200px", md: "100%" },
    width: { xs: "100%", md: "100%" },
    height: { xs: "300px", md: "400px" },
    borderRadius: "20px",
    cursor: "pointer",
  };
  const second = {
    transition: "transform .5s, filter 1s ease-in-out",
    filter: "grayscale(0)",
    width: "100%",
    height: { xs: "300px", md: "400px" },
    borderRadius: "20px",
    cursor: "pointer",
  };
  const [isopen, setIsOpen] = React.useState({
    status: false,
    link: null,
  });
  const [u, setU] = React.useState(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (u !== null) {
        setU(null);
        setIsOpen({
          status: false,
          link: null,
        });
        navigate(isopen.link);
      }
    }, 1000);
  }, [u]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleClose = () => {
    setIsOpen({
      status: false,
      link: null,
    });
  };
  return (
    <Grid
      item
      xs={(more === null) & (filter === true) ? 3 : 12}
      md={3}
      sx={{ p: 1, maxWidth: "100%", borderRadius: "20px" }}
    >
      <Login
        isopen={isopen?.status}
        handleClose={handleClose}
        onUserChange={setU}
      />
      <Card sx={{ borderRadius: "20px" }}>
        <CardMedia
          component="img"
          onClick={
            loggedin
              ? () => navigate("/profile/influenceur/" + user.username)
              : () =>
                  setIsOpen({
                    status: true,
                    link: "/profile/influenceur/" + user.username,
                  })
          }
          image={global.config.infos.image_url + user.image_name}
          alt={user?.username}
          className="profileimage"
          sx={(more === null) & (filter === true) ? first : second}
        />
        <Box
          style={{
            position: "relative",
            margin: "-50px 0px 0px 10px",
            height: " 50px",
            color: "white",
          }}
        >
          <Typography variant="subtitle2" component="h2">
            <strong>{user.channel}</strong>
          </Typography>
          <Typography variant="subtitle2" component="h2">
            <strong>{user.city}</strong>
          </Typography>
        </Box>
      </Card>
      <CardContent
        style={{
          padding: "10px 0px",
        }}
      >
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{
            marginBottom: { xs: "-0.8em", md: "-0.25em" },
          }}
        >
          <Grid container>
            <Grid item xs={8} sm={8}>
              <Typography align="left">
                <Box
                  component="img"
                  src={global.config.infos.image_url + user.logo}
                  alt={user?.username}
                  sx={{ width: { xs: "15px", md: "20px" } }}
                />

                <Typography
                  variant="body3"
                  sx={{
                    verticalAlign: "super",
                    fontSize: { xs: "0.7rem", md: "1rem" },
                  }}
                >
                  {" "}
                  {user.platform_name}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} sx={{ lineHeight: "0.6" }}>
              <Typography align="right">
                {!loggedin ? (
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Vous n'êtes pas connecté"
                    >
                      <IconButton
                        aria-label="wishlist"
                        onClick={handleTooltipOpen}
                        sx={{
                          color: "#00C0D9",
                          padding: "0",
                          "& svg": { fontSize: { xs: "1.1rem", md: "1.5rem" } },
                        }}
                      >
                        <FavoriteBorderIcon />
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                ) : (
                  <Checkbox
                    icon={<FavoriteBorderIcon />}
                    checked={checked}
                    sx={{
                      color: "#00C0D9",
                      padding: "0",
                      "& svg": { fontSize: { xs: "1.1rem", md: "1.5rem" } },
                      "&.Mui-checked": {
                        color: "#00C0D9",
                      },
                    }}
                    onChange={() => handleAddwishlist(user.id)}
                    checkedIcon={<FavoriteIcon />}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: { xs: "0.8rem", md: "1rem" },
            marginTop: { xs: "10px", md: "2px" },
          }}
        >
          {user?.channel.substring(0, 16)} {user?.channel.length >= 16 && "..."}{" "}
          | Abonnés: {kFormatter(user.views)}
        </Typography>
      </CardContent>
    </Grid>
  );
};

export default Profile;
