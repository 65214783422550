import React from "react";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import { useParams, useNavigate } from "react-router-dom";
import Influencer from "./Influencer/Influencer";
import Marque from "./Marque/Marque";
import Verification from "./Verification/Verification";
import "./Elements/inscription.css";

const Inscription = () => {
  const [utilisateur, setUtilisateur] = React.useState(null);

  const navigate = useNavigate();
  let { pagename, username } = useParams();

  return (
    <React.Fragment>
      <Header onUserChange={setUtilisateur} />
      {pagename === "influencer" && <Influencer />}

      {pagename === "marque" && <Marque />}

      {pagename === "activate" && <Verification />}

      <Footer />
    </React.Fragment>
  );
};

export default Inscription;
