import * as React from "react";
import Header from "../Layouts/Header/Header";
import Filter from "./Elements/Filter";
import Popular from "./Elements/Popular";
import Footer from "../Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import "./Elements/home.css";
import Category from "./Elements/Category";
import { FlashOffRounded } from "@mui/icons-material";
import ClientRequests from "../Api/ClientRequests";
import AuthService from "../Layouts/Login/services";
import Annonces from "../AnnoncePage/Annonces";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
function isEmpty(obj) {
  if (obj?.platform?.length === 0) {
    delete obj["platform"];
  }
  if (obj?.categories?.length === 0) {
    delete obj["categories"];
  }
  if (obj?.abonnes === null) {
    delete obj["abonnes"];
  }
  return Object.keys(obj).length === 0;
}
const Home = () => {
  const [loading, setLoading] = React.useState(false);
  const currentUser = AuthService.getCurrentUser();
  const [profiles, setProfiles] = React.useState([]);
  const [profile, setProfile] = React.useState(null);
  const [wishcounter, setWishcounter] = React.useState(0);
  const [annwishcounter, setAnnWishcounter] = React.useState(0);

  const [wishlist, setWishlist] = React.useState([]);
  const [utilisateur, setUtilisateur] = React.useState(null);
  const [more, setMore] = React.useState(null);
  const [filter, setFilter] = React.useState({});

  React.useEffect(() => {
    if ("user" in localStorage) {
      setUtilisateur(JSON.parse(localStorage.getItem("user")));
    }

    const getWishlists = async () => {
      const wishlists = await fetchData("influencers/wishlist/");
      setWishlist(wishlists);
    };
    getWishlists();
  }, [localStorage.getItem("user"), wishcounter]); // eslint-disable-line react-hooks/exhaustive-deps
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [index, setIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    setOpen(true);
    if (!isEmpty(filter)) {
      ClientRequests.post_("influencers/filtred/", filter)
        .then((data) => data.json())
        .then((resut) => {
          setProfile(resut);
          setOpen(false);
        });
    }

    const getProfiles = () => {
      if (more === null) {
        ClientRequests.get_("influencers/list/").then((profileslist) => {
          setProfiles(profileslist);
          window.scrollTo(0, 0);
          setOpen(false);
        });
      } else {
        ClientRequests.get_(
          "influencers/list/?category=" + more + "&page=" + index
        ).then((profileslist) => {
          setProfile(profileslist);

          window.scrollTo(0, 0);
          setOpen(false);
        });
      }
    };
    if (index == 1) {
      getProfiles();

      window.scrollTo(0, 0);
      setIsCompleted(false);
    }
  }, [more, filter, index]);
  const loadMore = () => {
    setLoading(true);

    setIndex(index + 1);
    setTimeout(() => {
      ClientRequests.get_(
        "influencers/list/?category=" + more + "&page=" + (index + 1)
      ).then((profileslist) => {
        setProfile(profileslist);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });

      if (index * 12 > profile?.users.length) {
        setIsCompleted(true);
      } else {
        setIsCompleted(false);
      }
    }, 1000);
  };
  const fetchData = async (url) => {
    const res = await fetch(global.config.infos.server_url + url);
    const data = await res.json();
    return data;
  };
  const AddAnnonceWishlist = (status) => {
    if (status === true) {
      setAnnWishcounter(annwishcounter + 1);
    }
    if (status === false && annwishcounter > 0) {
      setAnnWishcounter(annwishcounter - 1);
    }
  };
  const handleAddWishlist = (status) => {
    if (status === true) {
      setWishcounter(wishcounter + 1);
    }
    if (status === false && wishcounter > 0) {
      setWishcounter(wishcounter - 1);
    }
  };
  const handlefilterInput = (data) => {
    setMore(null);
    setFilter(data);
  };

  const renderHome = () => {
    return (
      <Box sx={{ minHeight: "75vh" }}>
        <Filter
          input={filter}
          infpage={false}
          setInput={setFilter}
          handlefilterInput={handlefilterInput}
        />
        {isEmpty(filter) ? (
          more === null ? (
            <Popular
              addwishlist={handleAddWishlist}
              utilisateur={utilisateur}
              wishlist={wishlist}
              profiles={profiles}
              setMore={setMore}
              more={more}
            />
          ) : (
            <>
              <Category
                categories={profile}
                addwishlist={handleAddWishlist}
                wishlist={wishlist}
                setIndex={setIndex}
                utilisateur={utilisateur}
                setMore={setMore}
                more={more}
              />
              {isCompleted ? (
                <></>
              ) : (
                profile?.users.length > 0 && (
                  <Box sx={{ mt: 4, p: 4, width: "100%", textAlign: "center" }}>
                    <LoadingButton
                      size="large"
                      onClick={loadMore}
                      loading={loading}
                      loadingIndicator="Chargement..."
                      variant="outlined"
                      sx={{
                        margin: "auto",
                        border: "1px solid #0DEDA3",
                        color: "#000",
                        borderRadius: "15px",
                      }}
                    >
                      Voir plus
                    </LoadingButton>
                  </Box>
                )
              )}
            </>
          )
        ) : (
          <Category
            categories={profile}
            addwishlist={handleAddWishlist}
            wishlist={wishlist}
            setIndex={setIndex}
            utilisateur={utilisateur}
            setMore={setMore}
            more={more}
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />

      <Header
        wishcounter={wishcounter}
        setWishcounter={setWishcounter}
        onUserChange={setUtilisateur}
      />
      <Box sx={{ marginTop: "150px", display: { md: "block", xs: "none" } }} />
      <Box sx={{ marginTop: "15px", display: { md: "none", xs: "block" } }} />
      <Container maxWidth="xl">
        {currentUser?.profileinfos.role === "Influenceur" ? (
          <>
            <Filter
              input={filter}
              handlefilterInput={handlefilterInput}
              infpage={false}
              setInput={setFilter}
            />
            <Annonces
              filter={filter}
              AddAnnonceWishlist={AddAnnonceWishlist}
              annwishcounter={annwishcounter}
            />
          </>
        ) : (
          renderHome()
        )}
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Home;
