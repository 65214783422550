import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import Box from "@mui/material/Box";
import AuthService from "../Layouts/Login/services";
import Add from "./Parts/Add";
import List from "./Parts/List";

const Annonces = ({ filter, AddAnnonceWishlist, annwishcounter }) => {
  const { pagename } = useParams();
  const currentUser = AuthService.getCurrentUser();

  return (
    <React.Fragment>
      <Box
        sx={{
          marginTop: { md: "20px", xs: "20px" },
          display: { md: "block", xs: "none" },
        }}
      />
      <List
        filter={filter}
        AddAnnonceWishlist={AddAnnonceWishlist}
        annwishcounter={annwishcounter}
      />
    </React.Fragment>
  );
};

export default Annonces;
