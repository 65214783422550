import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import AuthService from "../../../Layouts/Login/services";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import ClientRequests from "../../../Api/ClientRequests";
import Social from "../Social/Social";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : "rgb(236 254 249)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
const Reseau = ({ reseaux, setUpdated, updated }) => {
  const currentUser = AuthService.getCurrentUser();
  const [abonne, setAbonne] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [modifiable, setModifiable] = React.useState(true);
  const [platforms, setPlatform] = React.useState([]);
  const [plform, setPlForm] = React.useState({});

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClickOpen = () => {
    AuthService.fetchData("home/platforms/", currentUser.auth_token).then(
      (res) => {
        setPlatform(res);
      }
    );
    setOpen(true);
  };

  const handleClose = () => {
    setPlForm({});
    setErrors({});
    setOpen(false);
  };

  const handleUpdateAbonne = (data) => {
    ClientRequests.patch_token(
      "influencers/",
      currentUser.auth_token,
      data
    ).then((response) => {
      if (response.ok == true) {
        setModifiable(false);
      }
    });
  };
  const handleUpdateSocialMedia = async (data) => {
    try {
      const req = await ClientRequests.post_token(
        "influencers/",
        currentUser.auth_token,
        data
      );
      const result = await req.json();
      if (req.ok == true) {
        setOpen(false);
        setUpdated(!updated);
      } else {
        setErrors(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePlatformDelete = async (data) => {
    ClientRequests.delete_token(
      "influencers/delete/" + data.id + "/",
      currentUser.auth_token
    );
    setUpdated(!updated);
  };
  return (
    <>
      <Grid item xs={8}>
        <Item>
          <Typography variant="h6">Catégories et reseaux sociaux</Typography>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item sx={{ textAlign: "right" }}>
          <Button onClick={handleClickOpen} variant="text">
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Ajouter
            </Typography>
          </Button>
        </Item>
      </Grid>
      {reseaux.map((platform) => (
        <Grid item container xs={12} sx={{ border: "1px solid #ddd" }}>
          <Grid item xs={12} md={2}>
            <Item sx={{ mt: 1 }}>
              <img
                src={global.config.infos.image_url + platform.logo}
                alt={platform.platform_name}
                style={{ width: "40px" }}
              />
              <Typography
                variant="caption"
                sx={{ position: "relative", top: "-15px", left: "5px" }}
              >
                {" "}
                {platform.platform_name}
              </Typography>
            </Item>
          </Grid>

          <Grid item xs={12} md={6}>
            <Item>
              <TextField
                disabled={modifiable}
                id="filled-disabled"
                label="Abonnés"
                type="number"
                helperText={errors?.abonne}
                onInput={(e) =>
                  setAbonne({ id: platform.id, views: e.target.value })
                }
                defaultValue={platform.views}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </Item>
          </Grid>

          <Grid item xs={6} md={2}>
            <Item sx={{ textAlign: "right" }}>
              {modifiable ? (
                <Button
                  variant="contained"
                  onClick={() => setModifiable(false)}
                  sx={{
                    width: "100%",
                    height: "54px",
                    backgroundColor: "rgb(9 207 233)",
                  }}
                >
                  Modifier
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => handleUpdateAbonne(abonne)}
                  sx={{
                    width: "100%",
                    height: "54px",
                    backgroundColor: "rgb(9 207 233)",
                  }}
                >
                  Enregistrer
                </Button>
              )}
            </Item>
          </Grid>

          <Grid item xs={6} md={2}>
            <Item sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={() => handlePlatformDelete(platform)}
                startIcon={<DeleteIcon />}
                sx={{
                  width: "100%",
                  height: "54px",
                  backgroundColor: "rgb(9 207 233)",
                }}
              >
                Suprimer
              </Button>
            </Item>
          </Grid>
        </Grid>
      ))}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Ajouter vos reseaux sociaux"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {platforms.map((item) => (
              <Social
                key={item.id}
                platform={item}
                errors={errors}
                setFormChanger={setPlForm}
                form={plform}
              />
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={() => {
              handleUpdateSocialMedia(plform);
            }}
            autoFocus
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Reseau;
