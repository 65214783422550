import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Redirect } from "react-router";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ClientRequests from "../../Api/ClientRequests";
import InfluencerService from "../../Api/Influencer";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = ({ isopen, handleClose, onUserChange }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [passwords, setPasswords] = React.useState({
    new_password: "",
    re_new_password: "",
  });
  const [tempoData, setTempoData] = React.useState(null);
  const [code, setCode] = React.useState(null);
  const [forgotErrors, setForgotErrors] = React.useState({
    email: "",
    code: "",
    new_password: "",
    re_new_password: "",
  });
  const [open, setOpen] = React.useState(false);
  const [forgot, setForgot] = React.useState({
    status: 0,
    step: 0,
  });
  const [snackbaropen, setSnackBarOpen] = React.useState(false);

  const [form, setForm] = new React.useState({
    username: "",
    password: "",
  });
  const [errors, setError] = new React.useState({
    username: "",
    password: "",
    non_field_errors: "",
  });
  React.useEffect(() => {
    isopen === true ? setOpen(true) : setOpen(false);
  }, [isopen]); // eslint-disable-next-line

  const handleLogin = () => {
    setLoading(true);
    setTimeout(async () => {
      const res = await fetch(global.config.infos.server_url + "token/login/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          username: form.username,
          password: form.password,
        }),
      });
      const data = await res.json();
      if (res.ok === true) {
        setSnackBarOpen(true);
        const infos = await fetch(
          global.config.infos.server_url + "users/me/",
          {
            method: "GET",
            headers: { Authorization: "Token " + data.auth_token },
          }
        );
        const userinfos = await infos.json();

        const proinfos = await fetch(
          global.config.infos.server_url + "user/profile/",
          {
            method: "GET",
            headers: { Authorization: "Token " + data.auth_token },
          }
        );
        const profileinfos = await proinfos.json();

        localStorage.setItem(
          "user",
          JSON.stringify({ ...data, userinfos, profileinfos })
        );
        onUserChange({ ...data, userinfos, profileinfos });
        setOpen(false);
        handleClose();
        if (profileinfos.role === "Influenceur") {
          InfluencerService.fetchData(
            "influencers/hasplatform/",
            data.auth_token
          )
            .then((response) => {
              if (response.answer === true) {
                navigate("/");
              } else {
                navigate("/profile");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          navigate("/");
        }
      } else {
        setLoading(false);
        setError(data);
      }
      setLoading(false);
    }, 1500);
  };

  const handleSneackbarClose = () => {
    setSnackBarOpen(false);
  };

  const handleForgetPassword = async () => {
    if (email == "") {
      setForgotErrors({ ...forgotErrors, email: "Ce champ est obligatoire" });
      return false;
    }
    try {
      const reset = await ClientRequests.post_("user/passwordchangeer/", {
        email: email,
      });
      if (reset.ok === true) {
        setForgot({
          status: true,
          step: 1,
        });
        setForgotErrors({});
      } else {
        const response = await reset.json();
        setForgotErrors(response);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const CodeVerification = async () => {
    try {
      const reset = await ClientRequests.post_("user/passwordchangeercheck/", {
        email: email,
        code: code,
      });
      if (reset.ok === true) {
        const response = await reset.json();
        setTempoData(response);
        setForgot({
          status: true,
          step: 2,
        });
        setForgotErrors({});
      } else {
        setForgotErrors({
          ...forgotErrors,
          code: "Le code saisi est incorrect.",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const NewPasswordSet = async () => {
    try {
      const reset = await ClientRequests.post_(
        "users/reset_password_confirm/",
        {
          token: tempoData.token,
          uid: tempoData.uid,
          new_password: passwords.new_password,
          re_new_password: passwords.re_new_password,
        }
      );

      if (reset.ok === true) {
        setForgot({
          status: true,
          step: 3,
        });
        setForgotErrors({});
      } else {
        const response = await reset.json();
        console.log(response);
        setForgotErrors(response);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Snackbar
        open={snackbaropen}
        autoHideDuration={3000}
        onClose={handleSneackbarClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Bienvenu!! Vous êtes connecté
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">
          Démarrer la session
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        {errors.non_field_errors && (
          <Paper
            elevation={0}
            sx={{
              mb: 4,
              p: 2,
              color: "white",
              backgroundColor: "rgb(234 157 156)",
            }}
          >
            <Typography>{errors.non_field_errors}</Typography>
          </Paper>
        )}

        {forgot.status !== true ? (
          <DialogContent>
            <DialogContentText sx={{ p: 5 }}>
              <Paper elevation={0} sx={{ mb: 4 }}>
                <TextField
                  required
                  id="username"
                  helperText={errors.username}
                  onInput={(e) =>
                    setForm({ ...form, username: e.target.value })
                  }
                  label="E-mail ou Nom d'utilisateur"
                  style={{ width: "100%" }}
                  variant="outlined"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleLogin();
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                />
              </Paper>
              <Paper elevation={0} sx={{ mb: 4 }}>
                <TextField
                  required
                  id="password"
                  helperText={errors.password}
                  onInput={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  type="password"
                  label="Mot de passe"
                  style={{ width: "100%" }}
                  variant="outlined"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleLogin();
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                />
              </Paper>
              <Paper elevation={0} sx={{ mt: 4 }}>
                <LoadingButton
                  variant="contained"
                  style={{ backgroundColor: "#00C0D9", width: "100%" }}
                  endIcon={<SendIcon />}
                  onClick={handleLogin}
                  loading={loading}
                  loadingPosition="end"
                  size="large"
                >
                  Connexion
                </LoadingButton>
              </Paper>
              <Paper elevation={0} sx={{ mt: 4, textAlign: "center" }}>
                <Link
                  to="#"
                  underline="none"
                  onClick={() => setForgot({ ...forgot, status: true })}
                  style={{
                    marginRight: "20px",
                    textDecoration: "none",
                    color: "#00C0D9",
                  }}
                >
                  Vous avez oublié votre mot de passe?
                </Link>
                <hr />
                <a
                  href="/inscription/influencer"
                  style={{
                    marginRight: "20px",
                    textDecoration: "none",
                    color: "#00C0D9",
                  }}
                >
                  <strong>Rejoignez en tant qu'influenceur</strong>
                </a>
                <br />
                <a
                  href="/inscription/marque"
                  style={{
                    marginRight: "20px",
                    textDecoration: "none",
                    color: "#00C0D9",
                  }}
                >
                  <strong>Rejoignez en tant que marque</strong>
                </a>
              </Paper>
            </DialogContentText>
          </DialogContent>
        ) : (
          <>
            {forgot?.step == 0 && (
              <DialogContent>
                <Typography
                  variant="body1"
                  sx={{ mb: 4, color: "rgb(9 207 233)", fontWeight: "700" }}
                >
                  Mot de passe oublié
                  <br />
                  <small>Entrez l'adresse e-mail associée à votre compte</small>
                  <Divider />
                </Typography>

                <TextField
                  id="standard-basic"
                  label="Email"
                  onInput={(e) => setEmail(e.target.value)}
                  helperText={forgotErrors?.email}
                  variant="standard"
                  sx={{ width: "100%" }}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgb(9 207 233)",
                    width: "100%",
                    mt: 3,
                  }}
                  onClick={handleForgetPassword}
                >
                  Continuer
                </Button>
              </DialogContent>
            )}

            {forgot?.step == 1 && (
              <DialogContent>
                <Typography
                  variant="body1"
                  sx={{ color: "rgb(9 207 233)", fontWeight: "700" }}
                >
                  Modifier mon mot de passe
                  <br />
                </Typography>
                <small>
                  Saisissez le code de vérification reçu sur l'adresse e-mail{" "}
                  <strong>{email}</strong>
                </small>
                <Divider sx={{ mb: 4 }} />

                <TextField
                  id="standard-basic"
                  label="Code"
                  inputProps={{
                    maxLength: 6,
                  }}
                  onInput={(e) => setCode(e.target.value)}
                  helperText={forgotErrors?.code}
                  variant="standard"
                  sx={{ width: "100%" }}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgb(9 207 233)",
                    width: "100%",
                    mt: 3,
                  }}
                  onClick={CodeVerification}
                >
                  Continuer
                </Button>
                <Typography sx={{ mt: 2 }}>
                  Je n'ai rien reçu{" "}
                  <Link
                    to="#"
                    underline="none"
                    style={{
                      marginRight: "20px",
                      textDecoration: "none",
                      color: "#00C0D9",
                    }}
                    onClick={() => setForgot({ ...forgot, step: 0 })}
                  >
                    Me renvoyer le code
                  </Link>
                </Typography>
              </DialogContent>
            )}

            {forgot?.step == 2 && (
              <DialogContent>
                <Typography
                  variant="body1"
                  sx={{ color: "rgb(9 207 233)", fontWeight: "700" }}
                >
                  Nouveau mot de passe
                  <br />
                </Typography>
                <small>Saisissez votre nouveau mot de passe</small>
                <Divider sx={{ mb: 4 }} />
                <TextField
                  id="standard-basic"
                  type="password"
                  label="Nouveau mot de passe"
                  helperText={forgotErrors?.new_password}
                  onInput={(e) =>
                    setPasswords({ ...passwords, new_password: e.target.value })
                  }
                  variant="standard"
                  sx={{ width: "100%" }}
                />

                <TextField
                  id="standard-basic"
                  type="password"
                  label="Confirmer le nouveau mot de passe"
                  helperText={forgotErrors?.non_field_errors}
                  onInput={(e) =>
                    setPasswords({
                      ...passwords,
                      re_new_password: e.target.value,
                    })
                  }
                  variant="standard"
                  sx={{ width: "100%" }}
                />

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "rgb(9 207 233)",
                    width: "100%",
                    mt: 3,
                  }}
                  onClick={NewPasswordSet}
                >
                  Continuer
                </Button>
                <Typography sx={{ mt: 2 }}>
                  <Link
                    to="#"
                    underline="none"
                    style={{
                      marginRight: "20px",
                      textDecoration: "none",
                      color: "#00C0D9",
                    }}
                    onClick={() => setForgot({ status: false, step: 0 })}
                  >
                    Connexion
                  </Link>
                </Typography>
              </DialogContent>
            )}

            {forgot?.step == 3 && (
              <DialogContent>
                <Typography
                  variant="body1"
                  sx={{ color: "rgb(9 207 233)", fontWeight: "700", mb: 2 }}
                >
                  C'est fait
                  <br />
                </Typography>
                Votre modification de mot de passe a bien été enregistrée.
                <Typography sx={{ mt: 2 }}>
                  <Link
                    to="#"
                    underline="none"
                    style={{
                      marginRight: "20px",
                      textDecoration: "none",
                      color: "#00C0D9",
                    }}
                    onClick={() => setForgot({ status: false, step: 0 })}
                  >
                    Connexion
                  </Link>
                </Typography>
              </DialogContent>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

export default Login;
