import { Container, Grid, Avatar, Typography } from "@mui/material";
import React from "react";
import Rating from "@mui/material/Rating";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
import AuthService from "../../Layouts/Login/services";
import ClientRequests from "../../Api/ClientRequests";
moment.locale("fr");
const User = ({ contrat, created_at }) => {
  const [rating, setRating] = React.useState(0);
  const currentUser = AuthService.getCurrentUser();
  React.useEffect(() => {
    if (currentUser?.profileinfos?.id === contrat?.receiver?.id) {
      ClientRequests.get_token(
        "influencers/rating_average/" + contrat?.sender?.username + "/",
        currentUser.auth_token
      ).then((response) => {
        setRating(response?.average);
      });
    } else {
      ClientRequests.get_token(
        "influencers/rating_average/" + contrat?.receiver?.username + "/",
        currentUser.auth_token
      ).then((response) => {
        setRating(response?.average);
      });
    }
  }, []);
  return (
    <Container>
      {currentUser?.profileinfos?.id === contrat?.receiver?.id ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
          columnSpacing={1}
        >
          <Grid item>
            <Avatar
              alt={
                contrat?.sender?.first_name + " " + contrat?.sender?.last_name
              }
              src={global.config.infos.image_url + contrat?.sender?.avatar}
            />
          </Grid>
          <Grid item>
            <Typography>
              {contrat?.sender?.first_name + " " + contrat?.sender?.last_name}
            </Typography>

            <Rating name="read-only" size="small" value={rating} readOnly />
            <Typography>{moment(created_at).format("LL")}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
          columnSpacing={1}
        >
          <Grid item>
            <Avatar
              alt={
                contrat?.receiver?.first_name ||
                contrat?.receiver?.company + " " + contrat?.receiver?.last_name
              }
              src={global.config.infos.image_url + contrat?.receiver?.avatar}
            />
          </Grid>
          <Grid item>
            <Typography>
              {contrat?.receiver?.first_name || contrat?.receiver?.company}
              {" " + contrat?.receiver?.last_name}
            </Typography>

            {/* <Rating name="read-only" size="small" value={rating} readOnly /> */}
            <Typography>{moment(created_at).format("LL")}</Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default User;
