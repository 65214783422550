import React from "react";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ImageUploader from "react-images-upload";

import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Images = ({ handleAddImages, loading }) => {
  const [pictures, setPictures] = React.useState([]);
  const [picturUrls, setPicturUrls] = React.useState([]);
  const onDrop = (picture, picurls) => {
    setPictures(pictures.concat(picture));
    setPicturUrls(picurls);
  };
  return (
    <>
      <Grid container sx={{ mt: 5 }} spacing={2}>
        <Grid container item xs={12} md={12} columnSpacing={15} rowSpacing={2}>
          <Grid item xs={12} sm={12}>
            <Item>
              <ImageUploader
                withIcon={true}
                onChange={onDrop}
                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                maxFileSize={5242880}
                withPreview={true}
              />
            </Item>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <LoadingButton
          onClick={() => handleAddImages(picturUrls)}
          endIcon={<SendIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
          sx={{
            background: "#00C0D9",
          }}
        >
          Suivant
        </LoadingButton>
      </Box>
    </>
  );
};

export default Images;
