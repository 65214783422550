import React from "react";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import ClientRequests from "../../../Api/ClientRequests";
import AuthService from "../../../Layouts/Login/services";
import { Box, Button, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useConfirm } from "material-ui-confirm";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  theme: "stripe",
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const PaymentfForm = ({ contrat, success, setSuccess }) => {
  const confirm = useConfirm();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const currentUser = AuthService.getCurrentUser();
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async () => {
    confirm({
      description:
        "Vous êtes sur le point d'être redirigé vers notre partenaire de paiement sécurisé, Stripe, pour finaliser votre transaction.",
      title: "Êtes-vous sûr de vouloir effectuer ce paiement ?",
      confirmationText: "Payer",
      cancellationText: "Annuler",
    })
      .then(() => {
        setOpen(true);
        ClientRequests.post_token(
          "home/paiment_validation/" + contrat?.id + "/",
          currentUser.auth_token,
          {
            currency: "eur",
            amount: contrat.budget,
          }
        )
          .then((resp) => resp.json())
          .then((response) => {
            window.location = response?.intent?.url;
          })
          .catch((err) => {
            console.log(err);
            setOpen(false);
          });
      })
      .catch(() => {
        console.log("Payer cancelled.");
      });

    //} else {
    //   console.log(error);
    //   setOpen(false);
    // }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="body1" sx={{ fontWeight: 900, m: 1 }}>
        Paiement
      </Typography>
      {Object.keys(contrat).length > 0 && (
        <Box
          sx={{
            m: 1,
            background: "#eee",
            p: 2,
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "rgb(0, 192, 217)",

              padding: "4px",
              minWidth: "100px",
              width: "100%",
              fontSize: "12px",
            }}
            onClick={handleSubmit}
          >
            Payer
          </Button>
        </Box>
      )}
    </>
  );
};

export default PaymentfForm;
