import React from "react";
import MenuBar from "./MenuBar";
import FMB from "./FMB";
import Login from "../../Login/Login";
import ClientRequests from "../../../Api/ClientRequests";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const Menu = ({ wishCounter, onUserChange, setWishcounter }) => {
  const navigate = useNavigate();

  const [loginOpen, setLoginOpen] = React.useState(false);
  const [utilisateur, setUtilisateur] = React.useState(null);
  const [counter, setCounter] = React.useState(0);
  const [propocount, setPropoCount] = React.useState(0);

  const [orderCount, setOrderCount] = React.useState(0);

  const [wishls, setWishls] = React.useState(null);
  const [backdropopen, setBackdropopen] = React.useState(false);
  const [messages, setMessages] = React.useState(null);
  const [upd, setUpd] = React.useState(true);

  const handleLoginModal = () => {
    setLoginOpen(true);
  };

  const handlemodalclose = () => {
    setLoginOpen(false);
  };

  React.useEffect(() => {
    if ("user" in localStorage) {
      const loggedin = JSON.parse(localStorage.getItem("user"));
      setUtilisateur(loggedin);
      onUserChange(loggedin);
      ClientRequests.get_token("influencers/userwishlist/", loggedin.auth_token)
        .then((response) => {
          setCounter(response.length);
          setWishls(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [localStorage.getItem("user"), wishCounter, upd]);
  React.useEffect(() => {
    if ("user" in localStorage) {
      const interval = setInterval(() => {
        const loggedin = JSON.parse(localStorage.getItem("user"));
        ClientRequests.get_token("home/message_nonlu/", loggedin.auth_token)
          .then((response) => {
            if (response.length !== messages?.length) {
              setMessages(response);
            }
          })
          .catch((error) => {
            console.log(error);
          });

        ClientRequests.get_token("home/order_nonlu/", loggedin.auth_token)
          .then((response) => {
            if (response.count !== orderCount) {
              setOrderCount(response.count);
            }
          })
          .catch((error) => {
            console.log(error);
          });

        ClientRequests.get_token(
          "marque/proposition_nonvu/",
          loggedin.auth_token
        )
          .then((response) => {
            if (response.length !== propocount) {
              setPropoCount(response.length);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 3000);
      return () => clearInterval(interval);
    }
  });

  const handleLogout = () => {
    ClientRequests.post_token("token/logout/", utilisateur.auth_token).then(
      (response) => {
        console.log(response);
      }
    );
    setBackdropopen(true);
    setTimeout(() => {
      setUtilisateur(null);
      localStorage.removeItem("user");
      setBackdropopen(false);
      onUserChange(null);
      navigate("/");
    }, 2000);
  };
  console.log(orderCount);
  return (
    <React.Fragment>
      <MenuBar
        messages={messages}
        handleLogout={handleLogout}
        counter={counter}
        utilisateur={utilisateur}
        propocount={propocount}
        orderCount={orderCount}
        OpenModal={setLoginOpen}
        wishls={wishls}
        setWishcounter={setWishcounter}
        setUpd={setUpd}
      />
      <FMB
        messages={messages}
        handleLogout={handleLogout}
        counter={counter}
        propocount={propocount}
        orderCount={orderCount}
        utilisateur={utilisateur}
        OpenModal={setLoginOpen}
        wishls={wishls}
        setWishcounter={setWishcounter}
        setUpd={setUpd}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Login
        handleClose={handlemodalclose}
        handleLoginModal={handleLoginModal}
        isopen={loginOpen}
        onUserChange={setUtilisateur}
      />
    </React.Fragment>
  );
};

export default Menu;
