import React from "react";
import { Link } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";
import InfluencerService from "../../Api/Influencer";
import AuthService from "../../Layouts/Login/services";
import Avatars from "./Parts/Avatars";
import CoverImages from "./Parts/CoverImages";
import ClientRequests from "../../Api/ClientRequests";
import Platforms from "./Parts/Platforms";
import StarIcon from "@mui/icons-material/Star";

import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Complete = () => {
  const currentUser = AuthService.getCurrentUser();
  const [pictures, setPictures] = React.useState([]);
  const [platforms, setPlatforms] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [categories, setCategories] = React.useState(null);
  const [ratings, setRatings] = React.useState(null);

  React.useEffect(() => {
    InfluencerService.fetchData("user/images/", currentUser.auth_token)
      .then((response) => {
        let a = [];
        response.map((item, key) => {
          a.push(global.config.infos.image_url + item.image);
        });
        setPictures(a);
      })
      .catch((error) => {
        console.log(error);
      });

    ClientRequests.get_token("influencers/", currentUser.auth_token)
      .then((response) => {
        setPlatforms(response);
      })
      .catch((error) => {
        console.log(error);
      });

    ClientRequests.get_token("user/profile/", currentUser.auth_token)
      .then((response) => {
        setProfile(response);
      })
      .catch((error) => {
        console.log(error);
      });

    ClientRequests.get_token("influencers/category/", currentUser.auth_token)
      .then((response) => {
        setCategories(response);
      })
      .catch((error) => {
        console.log(error);
      });
    ClientRequests.get_token(
      "influencers/rating/" + currentUser?.userinfos.username + "/",
      currentUser?.auth_token
    )
      .then((response) => {
        setRatings(response);
      })
      .catch((error) => {
        console.log(error);
      });
    window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Box sx={{ marginTop: "150px", display: { md: "block", xs: "none" } }} />
      <Box
        sx={{
          marginTop: { md: "50px", xs: "10px" },
          display: { md: "none", xs: "block" },
        }}
      />
      <Container maxWidth="xl" sx={{ p: { xs: 0, md: "0px 24px" } }}>
        <Box sx={{ minHeight: "75vh" }}>
          <Grid
            item
            xs={12}
            sx={{ display: { xs: "block", md: "none" }, mb: 5 }}
          >
            <Item
              sx={{
                borderRadius: "5px",
                padding: { md: "0px 40px", xs: "5px" },
                overflow: "scroll",
              }}
            >
              <br />

              <Avatars profile={profile} />
            </Item>
          </Grid>

          <CoverImages images={pictures} />

          <Grid
            item
            xs={12}
            sx={{ display: { xs: "block", md: "none" }, mb: 5 }}
          >
            <Item
              sx={{
                borderRadius: "5px",
                padding: { md: "0px 40px", xs: "5px" },
                overflow: "scroll",
              }}
            >
              <br />
              <Platforms platforms={platforms} />
            </Item>
          </Grid>

          <Grid container sx={{ mt: 5, p: 1 }}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                border: "1px solid #eee",
                boxShadow: "0px 0px 3px #e5e5e5",
              }}
            >
              <Item sx={{ textAlign: "left", p: 3, wordBreak: "break-word" }}>
                {categories?.map((item, key) => (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      width: "auto",
                      display: "inline-block",
                      color: "#000",
                      fontWeight: "900",
                      border: "1px solid #ddd",
                      borderRadius: "50px",
                      padding: "7px",
                      m: "2px",
                      background: "#eee",
                      whiteSpace: "nowrap",
                    }}
                    key={key}
                  >
                    {item.name}
                  </Typography>
                ))}
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: 4,
                    textAlign: "justify",
                    wordBreak: "break-word",
                  }}
                >
                  {profile?.introduction}
                </Typography>
              </Item>
            </Grid>

            <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
              <Item
                sx={{
                  borderRadius: "5px",
                  padding: { md: "0px 40px", xs: "5px" },
                  overflow: "scroll",
                }}
              >
                <br />
                <Avatars profile={profile} />
                <Platforms platforms={platforms} />
              </Item>
            </Grid>
          </Grid>

          <Grid container>
            {(ratings === null) | (ratings?.length == 0)
              ? "Aucun Avis, les nouveaux avis apparaîtront ici"
              : ratings.map((item, key) => (
                  <Box
                    key={key}
                    sx={{
                      p: 2,
                      borderBottom: "1px solid #eee",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      {" "}
                      <strong>
                        {item.sender_fn} {item.sender_ln}
                      </strong>{" "}
                      ({moment(item.created_at).format("LL")}) <br />
                      {[...Array(item.rating)].map((e, i) => (
                        <StarIcon
                          sx={{ fontSize: "1rem", color: "rgb(203 201 3)" }}
                        />
                      ))}
                    </Typography>
                    <Typography>{item.review} </Typography>
                  </Box>
                ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Complete;
