import React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import AuthService from "../../Layouts/Login/services";
import Avatars from "../Influencer/Parts/Avatars";
import ClientRequests from "../../Api/ClientRequests";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LanguageIcon from "@mui/icons-material/Language";
import GroupsIcon from "@mui/icons-material/Groups";
import IconButton from "@mui/material/IconButton";
import { Paper } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: "1px solid #0DEDA3",
  borderRadius: "10px",
}));
const listInfo = {
  first_name: "Prénom",
  last_name: "Nom",
  siret: "SIRET",
  company: "Société",
  company_adresse1: "Adresse",
  company_adresse2: "Complément",
};

const ProfileMaquette = ({ profile, ratings, annonces, handleDelete }) => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [profile]);
  const renderPM = (profile, ratings, annonces, handleDelete) => {
    if (profile?.role == "Influenceur") {
      navigate("/profile/influenceur/" + profile?.username);
      return false;
    }
    return (
      <div>
        <Box
          sx={{ marginTop: "150px", display: { md: "block", xs: "none" } }}
        />
        <Box
          sx={{
            marginTop: { md: "50px", xs: "10px" },
          }}
        />
        <Container maxWidth="xl" sx={{ p: { xs: 0, m: "0px 24px" } }}>
          <Box>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Avatars profile={profile} />
            </Grid>

            <Grid container spacing={4} sx={{ p: 1 }}>
              <Grid
                item
                md={6}
                xs={12}
                sx={{ display: { xs: "inital", md: "none" } }}
              >
                <Item sx={{ textAlign: "left", p: 3 }}>
                  <Typography variant="h5" sx={{ color: "black", mb: 2 }}>
                    <strong>Informations</strong>
                  </Typography>
                  <Grid container>
                    {Object.entries(listInfo).map(
                      (item) =>
                        profile?.[item[0]] !== "" && (
                          <Grid container md={6} xs={12}>
                            <Grid item xs={5}>
                              <Typography
                                variant="body1"
                                sx={{ color: "black", mb: 2 }}
                              >
                                <strong>{item[1]}</strong>
                              </Typography>
                            </Grid>

                            <Grid item xs={7}>
                              {profile?.[item[0]]}
                            </Grid>
                          </Grid>
                        )
                    )}
                  </Grid>
                </Item>
              </Grid>

              <Grid item md={6} xs={12}>
                <Item sx={{ textAlign: "left", p: 3 }}>
                  <Typography variant="h5" sx={{ color: "black", mb: 2 }}>
                    <strong>Description</strong>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", wordBreak: "break-word" }}
                  >
                    {profile?.introduction || "Ajouter votre description"}
                  </Typography>
                </Item>
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                sx={{ display: { xs: "none", md: "initial" } }}
              >
                <Item sx={{ textAlign: "left", p: 3 }}>
                  <Typography variant="h5" sx={{ color: "black", mb: 2 }}>
                    <strong>Informations</strong>
                  </Typography>
                  <Grid container>
                    {Object.entries(listInfo).map(
                      (item) =>
                        profile?.[item[0]] !== "" && (
                          <Grid container md={6} xs={12}>
                            <Grid item xs={5}>
                              <Typography
                                variant="body1"
                                sx={{ color: "black", mb: 2 }}
                              >
                                <strong>{item[1]}</strong>
                              </Typography>
                            </Grid>

                            <Grid item xs={7}>
                              {profile?.[item[0]]}
                            </Grid>
                          </Grid>
                        )
                    )}
                  </Grid>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Container
          maxWidth={false}
          sx={{
            mt: 10,
            border: "1px solid #0DEDA3",
            borderRadius: "5px",
            background: "#cdfbe6",
            pt: 3,
            pb: 3,
          }}
        >
          <Grid
            container
            maxWidth="xl"
            sx={{
              p: 2,
              m: "auto",
            }}
            xs={11}
          >
            <Typography variant="h5" sx={{ color: "black", mb: 2 }}>
              <strong>Annonces</strong>
            </Typography>
          </Grid>

          {annonces?.map((annonce) => (
            <Grid
              container
              maxWidth="xl"
              sx={{
                m: "auto",
                background: "#fff",
                border: "1px solid #0DEDA3",
                borderRadius: "10px",
                p: 2,
              }}
            >
              <Grid item xs={10}>
                <Typography variant="body1" sx={{ color: "black", mb: 2 }}>
                  <strong>{annonce?.title}</strong>
                </Typography>
              </Grid>
              {currentUser?.userinfos?.username === profile?.username && (
                <Grid item xs={2} sx={{ textAlign: "right" }}>
                  <IconButton onClick={() => handleDelete(annonce)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Grid>
              )}

              <Grid item md={9} xs={12}>
                <Typography sx={{ textAlign: "justify" }}>
                  {annonce?.description}
                </Typography>
              </Grid>

              <Grid container sx={{ pt: 2 }}>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sx={{
                    border: "1px solid #0DEDA3",
                    borderRadius: "15px",
                    m: { xs: 0, md: 2 },
                    p: "5px 10px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6} textAlign="center">
                      <LocalOfferIcon />
                      <Typography>Budget</Typography>
                    </Grid>
                    <Grid item xs={6} padding="20px" textAlign="center">
                      <strong>{annonce.budget}</strong>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sx={{
                    border: "1px solid #0DEDA3",
                    borderRadius: "15px",
                    m: { xs: 0, md: 2 },
                    p: "5px 10px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6} textAlign="center">
                      <GroupsIcon />
                      <Typography>Abonnés</Typography>
                    </Grid>
                    <Grid item xs={6} padding="20px" textAlign="center">
                      <strong>{annonce.abonnee}</strong>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sx={{
                    border: "1px solid #0DEDA3",
                    borderRadius: "15px",
                    m: { xs: 0, md: 2 },
                    p: "5px 10px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6} textAlign="center">
                      <LanguageIcon />
                      <Typography>Réseaux sociaux</Typography>
                    </Grid>
                    <Grid item xs={6} padding="20px" textAlign="center">
                      {annonce?.pls?.map((plf) => (
                        <img
                          src={global.config.infos.image_url + plf.logo}
                          style={{ width: 20, height: 20 }}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Container>
        <Container
          maxWidth="false"
          sx={{
            mt: 5,

            borderRadius: "5px",
            pt: 3,
            pb: 3,
          }}
        >
          <Grid
            container
            maxWidth="xl"
            sx={{
              p: 2,
              m: "auto",
            }}
            xs={11}
          >
            <Typography variant="h5" sx={{ color: "black", mb: 2 }}>
              <strong>Avis</strong>
            </Typography>
          </Grid>

          <Grid
            conatiner
            maxWidth="xl"
            sx={{
              p: 2,
              m: "auto",
            }}
          >
            {(ratings === null) | (ratings?.length === 0)
              ? "Aucun Avis, les nouveaux avis apparaîtront ici"
              : ratings.map((item, key) => (
                  <Box key={key} sx={{ p: 2, borderBottom: "1px solid #eee" }}>
                    <Typography>
                      {" "}
                      <strong>
                        {item.sender_fn} {item.sender_ln}
                      </strong>{" "}
                      (moment(item.created_at).format("LL")) |{" "}
                      {[...Array(item.rating)].map((e, i) => (
                        <StarIcon
                          sx={{ fontSize: "1rem", color: "rgb(203 201 3)" }}
                        />
                      ))}
                    </Typography>
                    <Typography>{item.review} </Typography>
                  </Box>
                ))}
          </Grid>
        </Container>
      </div>
    );
  };
  return profile !== null ? (
    renderPM(profile, ratings, annonces, handleDelete)
  ) : (
    <></>
  );
};

export default ProfileMaquette;
