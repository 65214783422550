import React from "react";
import Countdown from "react-countdown";

import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import Contrat from "./ContratElements/Contrat";
import Recap from "./ContratElements/Recap";
import ClientRequests from "../Api/ClientRequests";
import AuthService from "../Layouts/Login/services";
import Paypal from "./ContratElements/Paypal";
import PaymentForm from "./ContratElements/buying/Paymentform";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// when you toggle to live mode, you should add the live publishale key.
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
const stripePromise = loadStripe(
  "pk_test_51MFevRLtl3e5LtbL1P0HGKMhBAJ5QodokR46oDClExeJtCe1UzXhjmgubLIGzylIxZNedPmjGwx14xTOPPNecva100O4AxVY3v"
);
const appearance = {
  theme: "night",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "2px solid #0DEDA3",
  background: "#fff",
  overflow: "auto",
}));

const PaimentStatus = () => {
  let { contratid, status, checkoutid } = useParams();
  console.log(status);
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [utilisateur, setUtilisateur] = React.useState(null);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if ("user" in localStorage) {
      setUtilisateur(JSON.parse(localStorage.getItem("user")));
    } else {
      navigate("/");
    }

    if (status === "success") {
      ClientRequests.post_token(
        "home/paid_validation_2/" + contratid + "/",
        currentUser.auth_token,
        {
          session_id: checkoutid,
        }
      ).then((res) => {
        window.location = "/contrat/" + contratid;
      });
    }
    if (status === "error") {
      setError(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  console.log(1);
  return (
    <React.Fragment>
      <Header onUserChange={setUtilisateur} />
      <Box
        sx={{
          marginTop: { xs: "50px", md: "150px" },
        }}
      />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            {error === true ? (
              <>
                <Typography
                  variant="h4"
                  textAlign="center"
                  sx={{ color: "red", fontWeight: 900 }}
                >
                  Erreur
                </Typography>

                <Typography textAlign="center" sx={{ color: "red" }}>
                  Oups ! Une erreur s’est Produit. Veuillez réessayer s’il vous
                  plaît
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default PaimentStatus;
