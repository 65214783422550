import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import AuthService from "../../../Layouts/Login/services";
import FormHelperText from "@mui/material/FormHelperText";
import ClientRequests from "../../../Api/ClientRequests";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const Social = ({ platform, setFormChanger, form, errors }) => {
  const currentUser = AuthService.getCurrentUser();
  const [categories, SetCategories] = React.useState([]);

  React.useEffect(() => {
    ClientRequests.get_("influencers/categories/").then((result) =>
      SetCategories(result)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Typography variant="h5" textAlign="center" sx={{ p: 2, mt: 4 }}>
        <img
          src={global.config.infos.image_url + platform.logo}
          alt={platform.name}
          style={{
            width: "30px",
            height: "30px",
            marginRight: "25px",
            position: "relative",
            top: "7px",
          }}
        />
        {platform.name}
      </Typography>
      <Divider
        sx={{
          maxWidth: "600px",
          margin: "auto",
          borderBottomWidth: 5,
          borderColor: "#0DEDA3",
        }}
      />
      <Grid container sx={{ mt: 5 }} spacing={2}>
        {/* offset grid with column you want to offset */}
        <Grid item md={1} xs={0} />
        {/* main grid column! */}
        <Grid container item xs={12} md={10} columnSpacing={20} rowSpacing={5}>
          <Grid item xs={12} sm={6}>
            <Item>
              <TextField
                required
                id="standard-basic"
                helperText={errors[platform.name]?.name}
                label="Nom d'utilisateur"
                style={{ width: "100%" }}
                onInput={(e) =>
                  setFormChanger({
                    ...form,
                    [platform.name]: {
                      ...form[platform.name],
                      name: e.target.value,
                      url: "https://instagram.com/" + e.target.value,
                    },
                  })
                }
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              <TextField
                required
                id="standard-basic"
                label="Nombre d'abonnés"
                helperText={errors[platform.name]?.abonne}
                onInput={(e) =>
                  setFormChanger({
                    ...form,
                    [platform.name]: {
                      ...form[platform.name],
                      abonne: e.target.value,
                    },
                  })
                }
                style={{ width: "100%" }}
                variant="standard"
              />
            </Item>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Item>
              {platform.name !== "Instagram" && (
                <TextField
                  required
                  id="standard-basic"
                  label={"url " + platform.name}
                  helperText={errors[platform.name]?.url}
                  onInput={(e) =>
                    setFormChanger({
                      ...form,
                      [platform.name]: {
                        ...form[platform.name],
                        url: e.target.value,
                      },
                    })
                  }
                  style={{ width: "100%" }}
                  variant="standard"
                />
              )}
            </Item>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Item>
              <Autocomplete
                multiple
                id="multiple-limit-tags"
                options={categories}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categories"
                    placeholder="Ajouter.."
                  />
                )}
                sx={{ width: "100%" }}
                onChange={(event, value) =>
                  setFormChanger({
                    ...form,
                    [platform.name]: {
                      ...form[platform.name],
                      categories: value,
                    },
                  })
                }
              />
              <FormHelperText>
                {errors[platform.name]?.categories}
              </FormHelperText>
            </Item>
          </Grid>
        </Grid>
        <Grid item md={1} xs={0} />
      </Grid>
    </>
  );
};

export default Social;
