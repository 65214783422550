import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import AuthService from "../../../Layouts/Login/services";
import CopmanyInfo from "./Divisions/CopmanyInfo";
import Details from "./Divisions/Details";
import ClientRequests from "../../../Api/ClientRequests";
import { SettingsInputHdmiRounded } from "@mui/icons-material";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "1px solid #0DEDA3",
  background: "#fcfafa",
  overflow: "auto",
}));
export default function Draweror({ annonce }) {
  const currentUser = AuthService.getCurrentUser();
  const [state, setState] = React.useState({
    left: false,
  });
  const [values, setValues] = React.useState({
    annonce: annonce.id,
    user: currentUser.profileinfos.id,
  });

  const [errors, setErrors] = React.useState(null);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hide, setHide] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    ClientRequests.get_token(
      "marque/single_proposition/" + annonce.id + "/",
      currentUser.auth_token
    ).then((res) => {
      if (res.length > 0) {
        setHide(true);
      }
    });
  }, []);

  const handleAddProposition = () => {
    ClientRequests.post_token(
      "marque/proposition/0/",
      currentUser.auth_token,
      values
    ).then((response) => {
      setHide(true);
    });
  };

  const list = (anchor) => (
    <Box role="presentation">
      <List sx={{ textAlign: "left" }}>
        <IconButton aria-label="return" onClick={toggleDrawer(anchor, false)}>
          <ArrowBackIcon />
        </IconButton>
      </List>
      <Divider />

      <Container>
        <Grid container sx={{ mb: 3, mt: 5 }}>
          <Grid item xs={12} sx={{ p: 1 }}>
            <Item
              sx={{
                p: 0,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "800", pl: 2 }}>
                {annonce.title}
              </Typography>
            </Item>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ p: 1, display: { md: "none", xs: "block" } }}
          >
            <CopmanyInfo annonce={annonce} />
          </Grid>

          <Grid item md={8} xs={12} sx={{ p: 1 }}>
            <Details annonce={annonce} />
          </Grid>
          <Grid item md={4} sx={{ p: 1, display: { xs: "none", md: "block" } }}>
            <CopmanyInfo annonce={annonce} />
          </Grid>
          {hide === false ? (
            <Grid container md={8} xs={12}>
              <Grid item xs={12} md={12} sx={{ p: 1 }}>
                <TextField
                  id="outlined-multiline-static"
                  sx={{
                    borderRadius: "10px",
                    border: "1px solid #0DEDA3",
                    background: "#fcfafa",
                  }}
                  label="Proposition"
                  helperText={errors?.description}
                  onInput={(e) =>
                    setValues({ ...values, description: e.target.value })
                  }
                  multiline
                  rows={8}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ p: 1 }}>
                <Item sx={{ p: 0.5 }}>
                  <Grid container>
                    <Grid item xs={12} md={6} sx={{ lineHeight: "3.5rem" }}>
                      Proposition de prix
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          sx={{ height: "2.5rem", mt: 1 }}
                          onInput={(e) =>
                            setValues({ ...values, price: e.target.value })
                          }
                          startAdornment={
                            <InputAdornment position="start">€</InputAdornment>
                          }
                        />
                        <FormHelperText>{errors?.price}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid item xs={6} sx={{ p: 1 }}>
                <Button
                  variant="text"
                  onClick={handleAddProposition}
                  sx={{
                    backgroundColor: "#00C0D9",
                    color: "#fff",
                    border: "1px solid #0DEDA3",
                    height: "60%",
                    mt: 2,
                    borderRadius: "10px",
                    ":hover": {
                      bgcolor: "rgba(0,192,217,0.7)", // theme.palette.primary.main
                      color: "white",
                    },
                  }}
                  fullWidth="true"
                >
                  Envoyer
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container sx={{ p: 1 }}>
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  background: "#fafafa",
                  borderRadius: "10px",
                  border: "1px solid #0DEDA3",
                  background: "#fcfafa",
                  textAlign: "center",

                  p: 3,
                }}
              >
                <CheckIcon
                  sx={{
                    border: "1px solid #0DEDA3",
                    background: "#fff",
                    borderRadius: "50%",
                    color: "#00C0D9",
                    p: 2,
                    width: 80,
                    height: 80,
                    m: 2,
                  }}
                />
                <Typography variant="body1">
                  <strong>Proposition envoyée</strong>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );

  return (
    <Box sx={{ position: "relative", height: "100%", textAlign: "right" }}>
      <CssBaseline />
      <IconButton
        sx={{
          color: "#00C0D9",
          p: 0,
          borderRadius: "5px",
        }}
        onClick={toggleDrawer("right", true)}
      >
        <strong style={{ fontSize: "12px" }}>Pour plus </strong>{" "}
        <ArrowForwardIcon />
      </IconButton>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: { xs: "100%", md: "80%" },
          },
        }}
      >
        {list("right")}
      </Drawer>
    </Box>
  );
}
