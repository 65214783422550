import React from "react";
import AuthService from "../Layouts/Login/services";
import { useNavigate } from "react-router-dom";

const Profilepre = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  React.useEffect(() => {
    AuthService.fetchData("user/role/", currentUser.auth_token)
      .then((response) => {
        if (response.role.toLowerCase() === "influenceur") {
          navigate("/profile/influenceur");
        } else {
          navigate("/profile/marque");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <div></div>;
};

export default Profilepre;
