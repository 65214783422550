import React from "react";
import moment from "moment";
import "moment/locale/fr";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import DiscountIcon from "@mui/icons-material/Discount";
import GroupsIcon from "@mui/icons-material/Groups";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import Grid from "@mui/material/Grid";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "1px solid #0DEDA3",
  background: "#fcfafa",
  overflow: "auto",
}));

const Details = ({ annonce }) => {
  return (
    <>
      <Item sx={{ p: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: "800", mb: 3 }}>
          Description
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "justify" }}>
          {annonce.description}
        </Typography>
      </Item>
      <Item sx={{ mt: 3, p: 1 }}>
        <Grid
          container
          xs={12}
          sx={{
            textAlign: "center",
          }}
        >
          <Grid
            item
            xs={3}
            md={3}
            sx={{ fontSize: { xs: "0.7rem", md: "0.875rem" } }}
          >
            <p>
              <DiscountIcon
                sx={{
                  color: "rgb(0, 192, 217)",
                  fontSize: { xs: "1rem", md: "1.5rem" },
                }}
              />
              <strong
                style={{
                  position: "relative",
                  top: "-7px",
                  left: "10px",
                }}
              >
                {" "}
                Budget
              </strong>
              <br />
              <strong style={{ fontWeight: "bolder", color: "black" }}>
                € {annonce.budget}
              </strong>
            </p>
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            sx={{ fontSize: { xs: "0.7rem", md: "0.875rem" } }}
          >
            <p>
              <GroupsIcon
                sx={{
                  color: "rgb(0, 192, 217)",
                  fontSize: { xs: "1rem", md: "1.5rem" },
                }}
              />{" "}
              <strong
                style={{
                  position: "relative",
                  top: "-7px",
                  left: "10px",
                }}
              >
                Abonnés
              </strong>
              <br />
              <strong style={{ fontWeight: "bolder", color: "black" }}>
                {annonce.abonnee}
              </strong>
            </p>
          </Grid>
          <Grid
            item
            xs={3}
            md={3}
            sx={{ fontSize: { xs: "0.7rem", md: "0.875rem" } }}
          >
            <p style={{ textAlign: "center" }}>
              <LocationOnIcon
                sx={{
                  color: "rgb(0, 192, 217)",
                  fontSize: { xs: "1rem", md: "1.5rem" },
                }}
              />
              <strong
                style={{
                  position: "relative",
                  top: "-7px",
                  left: "10px",
                }}
              >
                Lieu
              </strong>
              <br />
              <strong style={{ fontWeight: "bolder", color: "black" }}>
                {annonce.ville}
              </strong>
            </p>
          </Grid>

          <Grid
            item
            xs={3}
            md={3}
            sx={{ fontSize: { xs: "0.7rem", md: "0.875rem" } }}
          >
            <p>
              <LanguageIcon
                sx={{
                  color: "rgb(0, 192, 217)",
                  fontSize: { xs: "1rem", md: "1.5rem" },
                }}
              />{" "}
              <strong
                style={{
                  position: "relative",
                  top: "-7px",
                  left: "10px",
                }}
              >
                Cible
              </strong>
            </p>
            {annonce?.pls.map((pl) => (
              <img
                src={global.config.infos.image_url + pl.logo}
                alt={pl.name}
                style={{ width: "20px", marginRight: "4px" }}
              />
            ))}
          </Grid>
        </Grid>
      </Item>
    </>
  );
};

export default Details;
