import React from "react";
import { styled } from "@mui/material/styles";
import AuthService from "../../Layouts/Login/services";
import ClientRequests from "../../Api/ClientRequests";
import { Paper } from "@mui/material";
import ProfileMaquette from "./ProfileMaquette";
import Header from "../../Layouts/Header/Header";
import Footer from "../../Layouts/Footer/Footer";
import { useParams } from "react-router-dom";
const UserMarqueProfile = () => {
  let { username } = useParams();
  const currentUser = AuthService.getCurrentUser();
  const [profile, setProfile] = React.useState(null);
  const [annonces, setAnnonces] = React.useState(null);
  const [updated, setUpdated] = React.useState(false);
  const [ratings, setRatings] = React.useState(null);
  const [utilisateur, setUtilisateur] = React.useState(true);

  React.useEffect(() => {
    ClientRequests.get_token(
      "user/profile/" + username + "/",
      currentUser.auth_token
    )
      .then((response) => {
        setProfile(response);
        console.log(profile);
      })
      .catch((error) => {
        console.log(error);
      });
    ClientRequests.get_token(
      "marque/annonce/user/" + username + "/?limit=1000000",
      currentUser.auth_token
    ).then((res) => {
      setAnnonces(res);
      console.log(annonces);
    });
    ClientRequests.get_token(
      "influencers/rating/" + username + "/",
      currentUser?.auth_token
    )
      .then((response) => {
        setRatings(response);
        console.log(ratings);
      })
      .catch((error) => {
        console.log(error);
      });

    window.scrollTo(0, 0);
  }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Header onUserChange={setUtilisateur} />
      <ProfileMaquette
        profile={profile}
        ratings={ratings}
        annonces={annonces}
      />

      <Footer />
    </React.Fragment>
  );
};

export default UserMarqueProfile;
