import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Badge from "@mui/material/Badge";
import AuthService from "../../Login/services";
import ClientRequests from "../../../Api/ClientRequests";
import { SettingsInputHdmiRounded } from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import MenuItem from "@mui/material/MenuItem";
import ListItems from "./ListItems";
import PropositionList from "./PropositionList";
import Deposez from "./Deposez";
import { ConfirmProvider } from "material-ui-confirm";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { borderRadius } from "@mui/system";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AddBoxIcon from "@mui/icons-material/AddBox";
const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "1px solid #0DEDA3",
  background: "#fcfafa",
}));
const pg = 2;
export default function Draweror({ propocount }) {
  const currentUser = AuthService.getCurrentUser();
  const [updated, setUpdated] = React.useState(false);
  const [section, setSection] = React.useState({
    part: "list",
    annonce: null,
  });
  const [page, setPage] = React.useState(pg);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setSection({
      part: "list",
      annonce: null,
    });
    setUpdated((prev) => !prev);
    setPage(pg);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hide, setHide] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [annonces, setAnnonces] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleLoadMore = () => {
    setLoading(true);
    setTimeout(() => {
      setPage((prev) => prev + pg);
      setUpdated((prev) => !prev);
      setLoading(false);
    }, 1000);
  };

  const [propos, setPropos] = React.useState(null);
  React.useEffect(() => {
    if (section.part === "list") {
      setPropos(null);
    }
    ClientRequests.get_token(
      "marque/annonce/user/?limit=" + page,
      currentUser.auth_token
    ).then((res) => {
      setAnnonces(res);
    });
    if (section.part === "proposition") {
      ClientRequests.get_token(
        "marque/proposition/" + section.annonce + "/?limit=" + page,
        currentUser.auth_token
      ).then((res) => {
        setPropos(res);
      });
    }
  }, [updated, section]);
  const list = (anchor) => (
    <Container
      maxWidth="xl"
      sx={{
        p: { xs: 2, md: 5 },
        borderRadius: "10px",
      }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="h5" sx={{ fontWeight: "800" }}>
            Mes annonces
          </Typography>
        </Grid>

        <Grid container xs={12} sx={{ p: 1 }}>
          {(annonces === null) | (annonces?.length === 0) ? (
            <Grid
              item
              xs={12}
              sx={{
                background: "#fafafa",
                border: "1px solid #0DEDA3",
                borderRadius: "5px",
                textAlign: "center",
                p: 3,
              }}
            >
              <AddBoxIcon
                sx={{
                  border: "1px solid #0DEDA3",
                  background: "#fff",
                  borderRadius: "50%",
                  color: "#00C0D9",
                  p: 2,
                  width: 80,
                  height: 80,
                  m: 2,
                }}
              />
              <Typography variant="body1">
                <strong>Vous n’avez aucune annonce en ligne</strong>
              </Typography>
              <Button
                variant="contained"
                sx={{
                  color: "#4a4a4a",
                  bgcolor: "#0DEDA3",
                  m: 3,
                  border: "1px solid #0DEDA3",
                  borderRadius: "20px",
                  ":hover": {
                    bgcolor: "rgba(0,192,217,0.7)", // theme.palette.primary.main
                    color: "white",
                  },
                }}
                onClick={() =>
                  setSection({
                    part: "deposez",
                    annonce: null,
                  })
                }
              >
                Déposer une annonce
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item md={8} xs={0} />
              <Grid item md={4} xs={12}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  sx={{
                    color: "#4a4a4a",
                    bgcolor: "#0DEDA3",

                    border: "1px solid #0DEDA3",
                    borderRadius: "20px",
                    ":hover": {
                      bgcolor: "rgba(0,192,217,0.7)", // theme.palette.primary.main
                      color: "white",
                    },
                  }}
                  onClick={() =>
                    setSection({
                      part: "deposez",
                      annonce: null,
                    })
                  }
                >
                  Déposer une annonce
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container xs={12} sx={{ p: 1 }}>
        <ConfirmProvider>
          {annonces?.map((annonce) => (
            <ListItems
              annonce={annonce}
              setSection={setSection}
              setUpdated={setUpdated}
            />
          ))}
        </ConfirmProvider>
        {page <= annonces?.length ? (
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            variant="text"
            onClick={() => handleLoadMore()}
            sx={{
              margin: "auto",
              color: "#4a4a4a",
              border: "1px solid #0DEDA3",
              borderRadius: "20px",
              ":hover": {
                bgcolor: "rgba(0,192,217,0.7)", // theme.palette.primary.main
                color: "white",
              },
            }}
          >
            Voir plus
          </LoadingButton>
        ) : (
          <></>
        )}
      </Grid>
    </Container>
  );

  const deposez = (anchor) => (
    <Container
      maxWidth="xl"
      sx={{
        borderRadius: "10px",
        p: { xs: 2, md: 5 },
      }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="h5" sx={{ fontWeight: "800" }}>
            Déposer une annonce
          </Typography>
        </Grid>

        <Grid container xs={12} sx={{ p: 1 }}>
          <Grid item md={4} xs={12}>
            <Button
              variant="text"
              sx={{
                color: "#4a4a4a",
                border: "1px solid #0DEDA3",
                borderRadius: "20px",
                ":hover": {
                  bgcolor: "rgba(0,192,217,0.7)", // theme.palette.primary.main
                  color: "white",
                },
              }}
              fullWidth="true"
              onClick={() => setSection({ ...section, part: "list" })}
            >
              Vos annonces
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Deposez setSection={setSection} setUpdated={setUpdated} />
    </Container>
  );

  const propositions = (anchor) => (
    <Container
      maxWidth="xl"
      sx={{
        p: { xs: 2, md: 5 },
        borderRadius: "10px",
      }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="h5" sx={{ fontWeight: "800" }}>
            Propositions
          </Typography>
        </Grid>

        <Grid container xs={12} sx={{ p: 1 }}>
          <Grid item md={4} xs={12}>
            <Button
              variant="text"
              sx={{
                color: "#4a4a4a",
                border: "1px solid #0DEDA3",
                borderRadius: "20px",
                ":hover": {
                  bgcolor: "rgba(0,192,217,0.7)", // theme.palette.primary.main
                  color: "white",
                },
              }}
              fullWidth="true"
              onClick={() => setSection({ ...section, part: "list" })}
            >
              Vos annonces
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} sx={{ p: 1 }}>
        <ConfirmProvider>
          {propos?.map((prop) => (
            <PropositionList proposition={prop} />
          ))}
        </ConfirmProvider>
      </Grid>
    </Container>
  );
  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <CssBaseline />
      <IconButton
        aria-label="messages"
        onClick={toggleDrawer("right", true)}
        sx={{ color: "#00C0D9", p: { xs: "0px", md: "15px" } }}
      >
        <Badge badgeContent={propocount} max={5} color="secondary">
          <ControlPointIcon
            sx={{ color: "#00C0D9", display: { md: "block", xs: "none" } }}
          />
          <AddBoxIcon
            sx={{ color: "#00C0D9", display: { md: "none", xs: "block" } }}
          />
        </Badge>
      </IconButton>

      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: { xs: "100%", md: "80%" },
          },
        }}
      >
        <Box role="presentation">
          <List sx={{ textAlign: "left" }}>
            <IconButton
              aria-label="return"
              onClick={toggleDrawer("right", false)}
            >
              <ArrowBackIcon />
            </IconButton>
          </List>
          <Divider />
          {section.part === "list" && list()}
          {section.part === "deposez" && deposez()}
          {section.part === "proposition" && propositions()}
        </Box>
      </Drawer>
    </Box>
  );
}
