import React from "react";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ClientRequests from "../Api/ClientRequests";
import AuthService from "../Layouts/Login/services";

const Refresh = () => {
  const currentUser = AuthService.getCurrentUser();
  React.useEffect(() => {
    ClientRequests.post_token("home/stripe/payout/", currentUser.auth_token, {})
      .then((result) => result.json())
      .then((response) => {
        window.location = response.url;
      });
  }, []);
  const [utilisateur, setUtilisateur] = React.useState(null);

  return (
    <React.Fragment>
      <CssBaseline />
      <Header wishcounter={0} onUserChange={setUtilisateur} />
      <Container maxWidth="lg" sx={{ mt: 15 }}>
        <Stack spacing={1}>
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          {/* For other variants, adjust the size with `width` and `height` */}
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={60} />
          <Skeleton variant="rounded" width={210} height={60} />
        </Stack>

        <Stack spacing={1}>
          {/* For variant="text", adjust the height via font-size */}
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          {/* For other variants, adjust the size with `width` and `height` */}
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={210} height={60} />
          <Skeleton variant="rounded" width={210} height={60} />
        </Stack>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Refresh;
