
const fetchData = async (url, token) => {
    const res = await fetch(global.config.infos.server_url+ url, {
        headers: {
        'Authorization':'Token ' + token
      }})
    const data = await res.json()
    return data
  }
  const InfluencerService = {
    fetchData
  }
  
  export default InfluencerService;



