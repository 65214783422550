import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { IconButton, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import moment from "moment";
import "moment/locale/fr";
import AuthService from "../../../Layouts/Login/services";
import ClientRequests from "../../../Api/ClientRequests";
import Draweror from "./Draweror";
import DiscountIcon from "@mui/icons-material/Discount";

import GroupsIcon from "@mui/icons-material/Groups";
import CheckIcon from "@mui/icons-material/Check";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import Checkbox from "@mui/material/Checkbox";
import ClearIcon from "@mui/icons-material/Clear";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import { useNavigate } from "react-router-dom";

moment.locale("fr");

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
  borderRadius: "10px",

  width: "100%",
  overflow: "auto",
}));

const ListItems = ({ annonce, AddAnnonceWishlist, favorix }) => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }
  const [avg, setAvg] = React.useState(0);
  const [proposed, setProposed] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    ClientRequests.get_token(
      "influencers/rating_average/" + annonce.username + "/",
      currentUser.auth_token
    ).then((res) => {
      setAvg(res.average);
    });

    ClientRequests.get_token(
      "marque/proposed_annonce/" + annonce.id + "/",
      currentUser.auth_token
    ).then((res) => {
      setProposed(res.proposed);
    });

    ClientRequests.post_token("marque/userwishlist/", currentUser.auth_token, {
      annonce: annonce.id,
    }).then((result) => {
      setChecked(result.ok);
    });
  }, [checked]);

  const handleAddAnnonceWishlist = (annonce) => {
    ClientRequests.post_token("marque/wishlist/", currentUser.auth_token, {
      annonce: annonce,
    })
      .then((result) => result.json())
      .then((response) => {
        setChecked(response.wishlist);
        AddAnnonceWishlist(response.wishlist);
      });
  };
  return (
    <Grid container sx={{ pb: 3, mb: 3, borderBottom: "1px solid #ddd" }}>
      <Grid item md={3} xs={12} sx={{ p: 1 }}>
        <Item
          sx={{
            textAlign: "justify",
            display: { md: "block", xs: "none" },
            minHeight: { md: "315px" },
            cursor: "pointer",
            "&:hover": {
              background: "rgb(243 243 243)",
            },
            position: "relative",
          }}
          onClick={() => navigate("profile/marque/" + annonce.username)}
        >
          <Box>
            <Avatar
              alt={annonce.profile.first_name}
              src={global.config.infos.image_url + annonce.profile.avatar}
              sx={{
                margin: "auto",
                width: 60,
                height: 60,
              }}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "900",
                mt: 1,
                textAlign: "center",
              }}
            >
              {annonce.profile.role === "Marque"
                ? annonce.profile.company
                : annonce.profile.first_name + " " + annonce.profile.last_name}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              {annonce?.profile.ville}, France
            </Typography>
            <Rating
              name="read-only"
              value={avg}
              sx={{
                justifyContent: "center",
                width: "100%",
                color: "rgb(0, 192, 217)",
              }}
              readOnly
            />
            {proposed && (
              <CheckIcon
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  fontSize: "3em",
                  color: "#0DEDA3",
                }}
              />
            )}
          </Box>
          <Typography
            variant="caption"
            sx={{ textAlign: "justify", display: { md: "block", xs: "none" } }}
          >
            {annonce?.profile.introduction.substring(0, 150)}...
          </Typography>
        </Item>
      </Grid>

      <Grid container md={9} xs={12} sx={{ p: 1 }}>
        <Item
          sx={{
            mb: 1,
            minHeight: "220px",
          }}
        >
          <Box
            sx={{
              display: {
                md: "none",
                xs: "block",
              },
              borderBottom: "1px solid #ddd",
              mb: 2,
              position: "relative",
            }}
            onClick={() => navigate("profile/marque/" + annonce.username)}
          >
            <Avatar
              alt={annonce.profile.first_name}
              src={global.config.infos.image_url + annonce.profile.avatar}
              sx={{ margin: "auto", width: 60, height: 60 }}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "900",
                mt: 1,
                textAlign: "center",
              }}
            >
              {annonce.profile.role === "Marque"
                ? annonce.profile.company
                : annonce.profile.first_name + " " + annonce.profile.last_name}
            </Typography>
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              {annonce?.profile.ville}, France
            </Typography>
            <Rating
              name="read-only"
              value={avg}
              sx={{
                justifyContent: "center",
                width: "100%",
                color: "rgb(0, 192, 217)",
              }}
              readOnly
            />
            {proposed && (
              <CheckIcon
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  fontSize: "3em",
                  color: "#0DEDA3",
                }}
              />
            )}
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid xs={10} md={11}>
                  <Typography variant="h6" sx={{ fontWeight: "800" }}>
                    {annonce.title}
                  </Typography>
                  <Typography variant="caption">
                    {moment(annonce.created_at).format("LL")}
                  </Typography>
                </Grid>

                <Grid item md={1} xs={2} sx={{ textAlign: "end" }}>
                  {favorix ? (
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleAddAnnonceWishlist(annonce.id)}
                    >
                      <ClearIcon
                        sx={{
                          color: "#00C0D9",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <Checkbox
                      icon={<StarBorderIcon />}
                      checked={checked}
                      sx={{
                        color: "#00C0D9",
                        padding: "0",
                        "& svg": { fontSize: { xs: "1.5rem", md: "2rem" } },
                        "&.Mui-checked": {
                          color: "#00C0D9",
                        },
                      }}
                      onChange={() => handleAddAnnonceWishlist(annonce.id)}
                      checkedIcon={<StarIcon />}
                    />
                  )}

                  {/* <IconButton>
                <FavoriteBorderIcon />
              </IconButton> */}
                </Grid>
                <Grid xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "justify", mt: 3 }}
                  >
                    {annonce.description.substring(0, 150)}...
                  </Typography>
                  <Box sx={{ p: 1 }}>
                    {" "}
                    <Draweror annonce={annonce} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Item>

        <Grid item xs={12}>
          <Item
            sx={{
              background: "#0DEDA3",
              color: "#000",
            }}
          >
            <Grid container sx={{ textAlign: "center" }}>
              <Grid
                item
                xs={3}
                sx={{ fontSize: { xs: "0.7rem", md: "1.1rem" } }}
              >
                <DiscountIcon
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "1rem", md: "1.5rem" },
                  }}
                />
                <strong
                  style={{
                    position: "relative",
                    top: "-7px",
                    left: "5px",
                    fontSize: "0.8em",
                    color: "#fff",
                  }}
                >
                  Budget
                </strong>
                <br />
                <span style={{ marginLeft: "8px" }}>{annonce.budget} €</span>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ fontSize: { xs: "0.7rem", md: "1.1rem" } }}
              >
                <GroupsIcon
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "1rem", md: "1.5rem" },
                  }}
                />{" "}
                <strong
                  style={{
                    position: "relative",
                    top: "-7px",
                    left: "5px",
                    fontSize: "0.8em",
                    color: "#fff",
                  }}
                >
                  Abonnés
                </strong>
                <br />
                <span style={{ marginLeft: "8px" }}>
                  {kFormatter(annonce.abonnee)}
                </span>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ fontSize: { xs: "0.7rem", md: "1.1rem" } }}
              >
                <LocationOnIcon
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "1rem", md: "1.5rem" },
                  }}
                />
                <strong
                  style={{
                    position: "relative",
                    top: "-7px",
                    left: "5px",
                    fontSize: "0.8em",
                    color: "#fff",
                  }}
                >
                  Lieu
                </strong>
                <br />
                <span style={{ marginLeft: "8px" }}>{annonce.ville}</span>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ fontSize: { xs: "0.7rem", md: "1.1rem" } }}
              >
                <LanguageIcon
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "1rem", md: "1.5rem" },
                  }}
                />{" "}
                <strong
                  style={{
                    position: "relative",
                    top: "-7px",
                    left: "5px",
                    fontSize: "0.8em",
                    color: "#fff",
                  }}
                >
                  Cible
                </strong>
                <br />
                {annonce?.pls?.map((image) => (
                  <img
                    src={global.config.infos.image_url + image.logo}
                    style={{ width: 15, height: 15 }}
                  />
                ))}
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListItems;
