import * as React from "react";
import Divider from "@mui/material/Divider";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import moment from "moment";
import PropositionMessage from "./PropositionMessage";
import "moment/locale/fr"; // without this line it didn't work
import { letterSpacing } from "@mui/system";
import ContratView from "./ContratView";

moment.locale("fr");

const Message = ({
  data,
  username,
  messagesBoxRef,
  MessageProposition,
  setAccepted,
  accepted,
}) => {
  const ownerstyle = {
    display: "flex",
  };
  const nonownerstyle = {
    display: "flex",
    flexDirection: "row-reverse",
  };

  const leftbubblestyle = {
    display: "block",
    border: "1px solid #ddd",
    padding: "5px 15px",
    borderRadius: "15px",
    background: "#0deda35e",
  };

  const rightbubblestyle = {
    display: "block",
    border: "1px solid #ddd",
    padding: "5px 15px",
    borderRadius: "15px",
    marginRight: "16px",
    background: "#00c0d930",
  };

  const [display, setDisplay] = React.useState("none");
  const getTheDate = (item, key) => {
    let dates = null;
    let hours = null;
    let current = new Date(item?.created_at);

    if (key > 0) {
      let previous = new Date(data[key - 1].created_at);
      if (
        current.getMonth() == previous.getMonth() &&
        current.getDate() > previous.getDate()
      ) {
        dates = moment(current).format("LL");
      }

      hours = moment(current).format("LT");
    } else {
      dates = moment(current).format("LL");
      hours = moment(current).format("LT");
    }
    if (dates != null) {
      return (
        <>
          <span>{dates}</span>
          <hr />
          <span>{hours}</span>
        </>
      );
    } else {
      return (
        <>
          <span>{hours}</span>
        </>
      );
    }
  };
  return (
    <Box
      ref={messagesBoxRef}
      id="infinite-scroll-wrapper"
      sx={{
        height: "80%",
        overflow: "auto",
        p: { xs: "60px 24px 24px 24px", md: 3 },
      }}
    >
      {data !== false && (
        <>
          {data != null ? (
            <>
              <PropositionMessage
                data={data}
                proposition={MessageProposition}
                username={username}
                setAccepted={setAccepted}
                accepted={accepted}
              />
              {data?.map((item, i) => (
                <>
                  {item?.message !== "Offre de collaboration 404" && (
                    <Box
                      key={i}
                      sx={{
                        height: "auto",

                        mb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "9px",
                          color: "#747474",
                          textAlign: "center",
                        }}
                      >
                        {getTheDate(item, i)}
                      </Typography>
                      <Box
                        style={
                          item.sender_username !== username
                            ? nonownerstyle
                            : ownerstyle
                        }
                      >
                        {/* {item.sender_username === username && <Avatar alt={item.message} sx={{ marginRight:2 }}/> } */}
                        {item?.message != "Offre de contrat 404" && (
                          <Box
                            sx={
                              item.sender_username !== username
                                ? {
                                    ...rightbubblestyle,
                                    maxWidth: { md: "30%", xs: "70%" },
                                  }
                                : {
                                    ...leftbubblestyle,
                                    maxWidth: { md: "30%", xs: "70%" },
                                  }
                            }
                          >
                            <Typography variant="body1">
                              {" "}
                              {item.message}
                            </Typography>

                            {item.messagefiles.length > 0 &&
                              item.messagefiles.map((file) =>
                                file.file.substr(-3) === "pdf" ? (
                                  <a
                                    href={
                                      global.config.infos.image_url + file.file
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <PictureAsPdfIcon
                                      sx={{ fontSize: "6rem" }}
                                    />
                                  </a>
                                ) : (
                                  <a
                                    href={
                                      global.config.infos.image_url + file.file
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      style={{ width: "100px" }}
                                      src={
                                        global.config.infos.image_url +
                                        file.file
                                      }
                                      alt="attached file"
                                    />
                                  </a>
                                )
                              )}
                          </Box>
                        )}
                      </Box>

                      {item?.messagecontrat.length > 0 && (
                        <ContratView
                          data={item?.messagecontrat[0]}
                          sender={item?.sender_username}
                        />
                      )}
                    </Box>
                  )}
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </Box>
  );
};

export default Message;
