import * as React from "react";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import SellIcon from "@mui/icons-material/Sell";
import MapIcon from "@mui/icons-material/Map";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Grid from "@mui/material/Grid";
import AuthService from "../../../Layouts/Login/services";
import { useNavigate } from "react-router-dom";
import ClientRequests from "../../../Api/ClientRequests";
import "moment/locale/fr"; // without this line it didn't work
import { Button } from "@mui/material";
moment.locale("fr");

export default function PropositionList({ proposition }) {
  const currentUser = AuthService.getCurrentUser();
  const [image, setImage] = React.useState(null);
  const [rating, setRating] = React.useState(null);
  const navigate = useNavigate();
  React.useEffect(() => {
    ClientRequests.get_token(
      "influencers/user/" + proposition.profile.username + "/",
      currentUser.auth_token
    ).then((res) => {
      setImage(res[0].image_name);
    });

    ClientRequests.get_token(
      "influencers/rating_average/" + proposition.profile.username + "/",
      currentUser.auth_token
    ).then((res) => {
      setRating(res.average);
    });
  }, []);

  return (
    <Grid
      container
      spacing={1}
      sx={{ mt: 2, p: 2, border: "1px solid #0DEDA3" }}
      alignItems="center"
    >
      <Grid item lg={1} md={2} xs={3}>
        <Avatar
          alt={proposition.profile.username.toUpperCase()}
          src={global.config.infos.image_url + proposition.profile.avatar}
          sx={{ width: 70, height: 70, mr: 1, cursor: "pointer" }}
          onClick={() =>
            navigate("/profile/influenceur/" + proposition.profile.username)
          }
        />
      </Grid>
      <Grid item lg={9} md={8} xs={5}>
        <Typography gutterBottom variant="body2">
          {proposition.profile.first_name + " " + proposition.profile.last_name}
          <br />
          {proposition.profile.ville + ", France"}
          {/* // moment(proposition.created_at) // .calendar() // .charAt(0)
                  // .toUpperCase() + //
                  moment(proposition.created_at).calendar().slice(1) */}
        </Typography>
        {rating !== null && (
          <Rating
            name="half-rating-read"
            defaultValue={rating}
            precision={0.25}
            readOnly
            sx={{ color: "#00c2dc" }}
          />
        )}
      </Grid>

      <Grid item md={2} xs={4}>
        <Button
          variant="contained"
          sx={{ width: "100%", background: "#00c2dc", borderRadius: "20px" }}
          onClick={() => navigate("/messages/" + proposition.profile.username)}
        >
          Message
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="body2"
          sx={{
            borderRadius: "15px",
            border: "1px solid #eee",
            background: "#fcfcfc",
            p: 2,
          }}
        >
          <Typography variant="h5">Proposition</Typography>
          {proposition.description}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            borderRadius: "15px 0px 15px 0px",
            border: "1px solid #eee",
            background: "#00c2dc",
            textAlign: "center",
            p: 1,
            maxWidth: "150px",
          }}
        >
          <Typography variant="body2">
            <SellIcon sx={{ color: "#fff" }} />
            <strong style={{ color: "#fff" }}>Budget</strong>
          </Typography>
          <Typography variant="body2" sx={{ p: "10px", color: "#fff" }}>
            € {proposition.price}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
