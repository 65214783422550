import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Info from "./Elements/Info";
import Images from "./Elements/Images";
import Reseau from "./Elements/Reseau";
import Request from "../../Api/ClientRequests";
import AuthService from "../../Layouts/Login/services";
import { styled } from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import Avatar from "./Elements/Avatar";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ClorredStepper = styled(Stepper)(({ theme }) => ({
  "& .Mui-active": { color: "#65a6ff !important" },
  "& .Mui-completed": { color: "green !important" },
  "& .Mui-disabled .MuiStepIcon-root": { color: "gray !important" },
}));

const Uncomplete = () => {
  const currentUser = AuthService.getCurrentUser();
  const [activeStep, setActiveStep] = React.useState(-1);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [imageserrors, setImagesErrors] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [steps, setSteps] = React.useState([
    { title: "Informations de profil" },
    { title: "Avatar" },
    { title: "Photos de couverture" },
    { title: "Réseaux sociaux" },
  ]);

  const handleAddInfos = async (data) => {
    setLoading(true);
    try {
      data = { ...data, user: currentUser.userinfos.id, status: true };
      const req = await Request.put_token(
        "user/profile/",
        currentUser.auth_token,
        data
      );
      const result = await req.json();
      if (req.ok === true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0, 0);
      } else {
        setErrors(result);
        window.scrollTo(0, 0);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleAddImages = async (data) => {
    if (data.length == 0) {
      setImagesErrors(true);
      return false;
    }
    setLoading(true);
    try {
      const req = await Request.post_token(
        "user/images/",
        currentUser.auth_token,
        data
      );
      const result = await req.json();
      if (req.ok === true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0, 0);
        setImagesErrors(false);
      } else {
        setErrors(result);
        window.scrollTo(0, 0);
        setImagesErrors(true);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleAddPlatform = async (data) => {
    setLoading(true);
    try {
      const req = await Request.post_token(
        "influencers/",
        currentUser.auth_token,
        data
      );
      const result = await req.json();
      if (req.ok === true) {
        setOpen(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0, 0);
      } else {
        setErrors(result);
        setOpen(true);
        window.scrollTo(0, 0);
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const Inscriptionstatus = async () => {
      try {
        const req = await Request.get_token(
          "user/inscriptionstatus/",
          currentUser.auth_token
        );
        setActiveStep(req.step);
      } catch (err) {
        setActiveStep(0);
      }
    };
    Inscriptionstatus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="xl">
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        open={open}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Un ou plusieurs champs contiennent une erreur. S'il vous plaît,
          vérifiez et essayez à nouveau.
        </Alert>
      </Snackbar>

      <Box sx={{ width: "100%" }}>
        <CssBaseline />
        <Box
          sx={{ marginTop: "150px", display: { md: "block", xs: "none" } }}
        />
        <Box sx={{ marginTop: "50px", display: { md: "none", xs: "block" } }} />
        <ClorredStepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={step.title} {...stepProps}>
                <StepLabel {...labelProps} className="steppers">
                  {step.title}
                </StepLabel>
              </Step>
            );
          })}
        </ClorredStepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              <Box
                sx={{
                  maxWidth: "700px",
                  margin: "auto",
                  textAlign: "center",
                  p: 4,
                }}
              >
                <strong>
                  Félicitations !<br /> Votre compte est complet.
                </strong>
                <br />
                <br />
                <strong>La prochaine étape ?</strong>
                <br />
                <br />
                Ad House! va examiner votre compte et reviendra vers vous
                rapidement Si votre compte est validé vous recevrez par e-mail
                une notification d’activation de votre compte.
                <br />
                Merci &#128515;.
              </Box>
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {activeStep === 0 && (
              <Info
                handleAddInfos={handleAddInfos}
                loading={loading}
                errors={errors}
              />
            )}

            {activeStep === 1 && (
              <Avatar
                setNextStep={setActiveStep}
                profile={currentUser.profileinfos}
              />
            )}
            {activeStep === 2 && (
              <>
                {imageserrors == true && (
                  <Typography sx={{ textAlign: "center", color: "red", mt: 3 }}>
                    Ajoute au moins 1 photo pour continuer
                  </Typography>
                )}

                <Images loading={loading} handleAddImages={handleAddImages} />
              </>
            )}
            {activeStep === 3 && (
              <Reseau
                loading={loading}
                handleAddPlatform={handleAddPlatform}
                errors={errors}
              />
            )}
          </React.Fragment>
        )}
      </Box>
    </Container>
  );
};
export default Uncomplete;
