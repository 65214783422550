import React from "react";
import { styled } from "@mui/material/styles";
import AuthService from "../../Layouts/Login/services";
import ClientRequests from "../../Api/ClientRequests";
import { Paper } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import ProfileMaquette from "./ProfileMaquette";

const Marque = () => {
  const confirm = useConfirm();
  const currentUser = AuthService.getCurrentUser();
  const [profile, setProfile] = React.useState(null);
  const [annonces, setAnnonces] = React.useState(null);
  const [updated, setUpdated] = React.useState(false);
  const [ratings, setRatings] = React.useState(null);

  React.useEffect(() => {
    ClientRequests.get_token("user/profile/", currentUser.auth_token)
      .then((response) => {
        setProfile(response);
      })
      .catch((error) => {
        console.log(error);
      });

    ClientRequests.get_token(
      "marque/annonce/user/?limit=1000000",
      currentUser.auth_token
    ).then((res) => {
      setAnnonces(res);
    });
    ClientRequests.get_token(
      "influencers/rating/" + currentUser?.userinfos.username + "/",
      currentUser?.auth_token
    )
      .then((response) => {
        setRatings(response);
      })
      .catch((error) => {
        console.log(error);
      });

    window.scrollTo(0, 0);
  }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleDelete = (item) => {
    confirm({
      title: "Êtes vous sûres?",
      description: `Cet action supprimera définitivement l'annonce: ${item.title}.`,
      confirmationText: "Oui",
      cancellationText: "Annuler",
    })
      .then(() => {
        ClientRequests.delete_token(
          "marque/annonce/" + item.id + "/",
          currentUser.auth_token
        ).then((res) => {
          if (res.ok) {
            setUpdated((prev) => !prev);
          }
        });
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  return (
    <ProfileMaquette
      profile={profile}
      ratings={ratings}
      annonces={annonces}
      handleDelete={handleDelete}
    />
  );
};

export default Marque;
