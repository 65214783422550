import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Badge from "@mui/material/Badge";
import AuthService from "../../Login/services";
import ClientRequests from "../../../Api/ClientRequests";

import { useNavigate } from "react-router-dom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import "moment/locale/fr";
const pg = 2;
export default function FavorisSpace({
  counter,
  wishls,
  setWishcounter,
  setUpd,
}) {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [updated, setUpdated] = React.useState(false);
  const [section, setSection] = React.useState({
    part: "list",
    annonce: null,
  });
  const [page, setPage] = React.useState(pg);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setSection({
      part: "list",
      annonce: null,
    });
    setUpdated((prev) => !prev);
    setPage(pg);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hide, setHide] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [favoris, setFavoris] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleLoadMore = () => {
    setLoading(true);
    setTimeout(() => {
      setPage((prev) => prev + pg);
      setUpdated((prev) => !prev);
      setLoading(false);
    }, 1000);
  };

  const [propos, setPropos] = React.useState(null);
  React.useEffect(() => {
    if (section.part === "list") {
      setPropos(null);
    }

    ClientRequests.get_token(
      "influencers/userwishlist/",
      currentUser?.auth_token
    ).then((res) => {
      setFavoris(res);
    });
  }, [updated, section]);

  const handleDelete = (influencer) => {
    ClientRequests.post_token("influencers/wishlist/", currentUser.auth_token, {
      influencer: influencer,
    })
      .then((result) => result.json())
      .then((response) => {
        setUpd((prev) => !prev);
        setWishcounter((prev) => prev - 1);
      });
  };
  const list = (anchor) => (
    <Container
      maxWidth="xl"
      sx={{
        p: { xs: 2, md: 5 },
        borderRadius: "10px",
      }}
    >
      {(wishls === null) | (wishls?.length === 0) ? (
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              background: "#fafafa",
              border: "1px solid #0DEDA3",
              borderRadius: "5px",
              textAlign: "center",
              p: 3,
            }}
          >
            <ClearIcon
              sx={{
                border: "1px solid #0DEDA3",
                background: "#fff",
                borderRadius: "50%",
                color: "#00C0D9",
                p: 2,
                width: 80,
                height: 80,
                m: 2,
              }}
            />
            <Typography variant="body1">
              <strong>Vous n’avez aucun élément dans cette liste</strong>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          {wishls.map((w) => (
            <Grid item md={3} xs={12} sx={{ p: 1, position: "relative" }}>
              <IconButton
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  zIndex: 999,
                }}
                onClick={() => handleDelete(w.influencer.id)}
              >
                <ClearIcon sx={{ color: "#fff" }} />
              </IconButton>
              <Card
                sx={{ m: "auto", position: "relative" }}
                onClick={() =>
                  navigate("/profile/influenceur/" + w.influencer.username)
                }
              >
                <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    left: 0,
                    top: 0,
                    height: "50px",
                    zIndex: 990,
                    background: "rgba(0,0,0,0.4)",
                  }}
                ></Box>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="250"
                    image={
                      global.config.infos.image_url + w.influencer.image_name
                    }
                    alt={w.influencer.username}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      <img
                        src={global.config.infos.image_url + w.influencer.logo}
                        style={{ width: 20, height: 20, marginRight: "20px" }}
                      />
                      {w.influencer.username}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <Typography>
                        {moment(w.created_at).format("LLLL")}
                      </Typography>
                      <Typography>
                        <strong>Abonnés</strong> : {w.influencer.username}
                      </Typography>
                      <Typography>
                        <strong>Ville</strong> : {w.influencer.city}
                      </Typography>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <CssBaseline />
      <IconButton
        aria-label="wishlist"
        onClick={toggleDrawer("right", true)}
        sx={{ color: "#00C0D9", p: { xs: "4px 0px 0px 0px", md: "15px" } }}
      >
        <Badge badgeContent={counter} max={5} color="secondary">
          <FavoriteBorderIcon />
        </Badge>
      </IconButton>
      {/* <AutoAwesomeIcon
        onClick={toggleDrawer("right", true)}
        sx={{ mr: 1, display: { md: "none", xs: "block" }, color: "#00C0D9" }}
      /> */}
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: { xs: "100%", md: "80%" },
          },
        }}
      >
        <Box role="presentation">
          <List sx={{ textAlign: "left" }}>
            <IconButton
              aria-label="return"
              onClick={toggleDrawer("right", false)}
            >
              <ArrowBackIcon />
            </IconButton>
          </List>
          <Divider />
          {section.part === "list" && list()}
        </Box>
      </Drawer>
    </Box>
  );
}
