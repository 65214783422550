import React from "react";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ClientRequests from "../Api/ClientRequests";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Confidentialite = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [utilisateur, setUtilisateur] = React.useState(null);
  return (
    <React.Fragment>
      <CssBaseline />
      <Header wishcounter={0} onUserChange={setUtilisateur} />
      <Container maxWidth="lg" sx={{ mt: 15 }}>
        <h2>Politique de confidentialité</h2>
        <p>
          La société Adhouse s’engage à protéger les données personnelles et la
          vie privée de ses clients, visiteurs du Site internet et de toute
          personne utilisant ses Services de manière générale.{" "}
        </p>
        <p>
          La présente Politique de protection des données personnelles et
          cookies a pour objectif de vous informer :
        </p>
        <ul>
          <li>
            Des types de données personnelles que nous collectons vous
            concernant ;
          </li>
          <li>
            Sur la manière dont vos données à caractère personnel sont traitées
            par Adhouse et ses partenaires et sous-traitants ;
          </li>
          <li>
            Des conditions et modalités d’utilisation de vos données
            personnelles et de vos droits à cet égard, dans le respect de la
            législation européenne et française applicables à Adhouse;
          </li>
          <li>
            outes les opérations sur vos données personnelles sont réalisées
            dans le respect des réglementations en vigueur et notamment du
            Règlement n°2016/679 du Parlement européen et du Conseil du 27 avril
            2016 relatif à la protection des personnes physiques à l’égard du
            traitement des données à caractère personnel et à la libre
            circulation de ces données (ci-après le « RGPD »), et de toute
            législation nationale applicable à la protection des données.
          </li>
        </ul>

        <h3>
          <strong>1. Responsable de traitement</strong>
        </h3>

        <p>
          Société Adhouse, immatriculée sous le numéro 843 828 468 00010, dont
          le siège social est situé au 21 SQ ANTOINE WATTEAU, 60100 Creil,
          représentée par Monsieur ADJI MOULERICHE, en sa qualité de Gérant.
        </p>

        <h3>
          <strong>2. Hébergeur</strong>
        </h3>
        <p>
          La Plateforme www.adhouse.fr est hébergée par la société OVH SAS,
          immatriculée au registre du commerce et des sociétés de Lille
          Métropole sous le numéro 424761419, dont le siège social est situé au
          2 rue Kellermann 59100 Roubaix, téléphone : 08 99 70 17 61. La société
          OVH est en accord avec les principes RGPD de l’Union européenne (pour
          en savoir plus, cliquez ici), ainsi que toutes les législations
          concernant la sécurité (pour en savoir plus, cliquez ici).
        </p>

        <h3>
          <strong>
            3. Quand et pourquoi recueillons-nous des informations à votre sujet
            ?
          </strong>
        </h3>

        <p>
          Nous recevons des informations de votre part pour permettre leur bonne
          transmission aux destinataires que vous voulez rencontrer sur notre
          plateforme. Ces informations permettent à la fois de vérifier
          l'authenticité des données transmises et d'assurer leur bon aiguillage
          sur notre logiciel. Certains éléments d'information sont obligatoires
          pour accéder aux services. Le caractère obligatoire de ces éléments
          est précisé dans les formulaires pour chaque question, par un
          astérisque ou par une mention "obligatoire".
        </p>

        <h3>
          <strong>4. Quelles informations recueillons-nous ?</strong>
        </h3>

        <p>
          Lors de la navigation sur le site internet. La simple consultation du
          site internet de Adhouse en tant que visiteur ne requiert pas
          d’enregistrement nominatif.
        </p>
        <p>Les données personnelles que nous recueillons peuvent inclure :</p>

        <ul>
          <li>Adresse e-mail ;</li>
          <li>Nom d'utilisateur ;</li>
          <li>Mot de passe ;</li>
          <li>Nom et prénom ;</li>
          <li>Emplacement ;</li>
          <li>Fichier pdf ;</li>
          <li>Images ;</li>
          <li>Biographie des influenceurs ;</li>
          <li>Catégories pour les influenceurs ;</li>
          <li>liens de vos réseaux sociaux et/ou vos identifiants ;</li>
          <li>Vos offres de service ;</li>
          <li>Identifiant SIREN pour les entreprises ;</li>
          <li>Détails de l'entreprise ;</li>
          <li>Votre utilisation des services que nous proposons.</li>
        </ul>
        <p>
          Certaines informations et certains commentaires ou contenus que vous
          fournissez de façon optionnelle peuvent, sous votre responsabilité et
          à votre initiative, révéler des données sensibles comme votre origine
          ethnique, votre nationalité ou votre religion. En fournissant ces
          informations optionnelles, vous assumez l'entière responsabilité de la
          diffusion de ces informations sensibles sur notre plateforme.
        </p>

        <h3>
          <strong>5. Politique des cookies</strong>
        </h3>
        <p>
          La navigation sur la Plateforme peut entraîner l'implantation de
          cookies dans le navigateur de l'Utilisateur. Les cookies sont des
          fichiers textes, susceptibles d'être enregistrés sur un terminal (un
          ordinateur, une tablette, un téléphone...), lors de la consultation de
          la Plateforme avec un logiciel de navigation. Les cookies ont pour
          objectif d'offrir une meilleure expérience d'utilisation aux
          Utilisateurs de la Plateforme. L'Utilisateur accepte expressément
          l'utilisation des cookies en cliquant sur le bouton
          &ldquo;Accepter&rdquo;, comme il peut refuser une partie ou la
          totalité des cookies en cliquant sur le bouton &ldquo;Refuser&rdquo;.
        </p>
        <p>
          Différents types de cookies sont utilisés sur la Plateforme et sont
          répartis comme suit :
        </p>
        <p>
          (a) la mesure d'audience : les statistiques de visites permettant de
          connaître, notamment les pages les plus et les moins populaires, les
          chemins préférés, les niveaux d'activité par jour de la semaine et par
          heure de la journée, les principales erreurs des Utilisateurs ou du
          serveur, afin d'améliorer la configuration, l'intérêt, le design et
          l'ergonomie de la Plateforme ;
        </p>
        <p>
          (b) le marketing et la publicité : l'affichage de messages ciblés, des
          annonces pertinentes et adaptées aux centres d'intérêts de
          l'Utilisateur.
          <br />
          (c) les préférences d'Utilisateur : l'amélioration de son expérience
          de navigation (notamment sélection de la langue, résolution
          d'affichage, système d'exploitation utilisé) ;
        </p>
        <p>
          (d) les cookies anonymes (toujours requis) : nécessaires au bon
          fonctionnement et à la sécurité de service.
        </p>

        <p>
          La Plateforme utilise l'outil statistique (Google Analytics), lequel
          enregistre les activités des Utilisateurs sur la Plateforme qui ne
          sont pas exploitées nominativement.
          <br />
          Adhouse rappelle à l'Utilisateur qu'il peut s'opposer à
          l'enregistrement de cookies à tout moment en les désactivant dans son
          navigateur. Cependant, tout paramétrage est susceptible de modifier
          ses conditions d'accès à certains services nécessitant l'utilisation
          de cookies.
        </p>
        <h3>
          <strong>6. Comment traitons-nous vos données ?</strong>
        </h3>
        <p>Nous utilisons vos données pour les finalités suivantes :</p>
        <ul>
          <li>
            <p>
              La création, la gestion et le suivi de votre compte sur notre
              site. Base légale : exécution d'un contrat ou de mesures
              précontractuelles.
            </p>
          </li>
          <li>
            <p>
              La gestion de vos demandes de contact ou d'information sur nos
              services Base légale: exécution d'un contrat ou de mesures
              précontractuelles ; votre consentement lorsque celui-ci est
              requis.
            </p>
          </li>
          <li>
            <p>
              Assurer le bon fonctionnement, la sécurité et l'amélioration de
              notre site internet, de ses services et de ses fonctionnalités ;
              Elaborer des statistiques commerciales Base légale : notre intérêt
              légitime à garantir la qualité de notre site internet ; votre
              consentement lorsque celui-ci est requis.
            </p>
          </li>
          <li>
            <p>
              Lutter contre la fraude et respecter nos obligations légales Base
              légale : obligations légales
            </p>
            <p>
              Les données sont transmises à notre plateforme de façon sécurisée
              grâce au procédé de cryptage https. Nous faisons appel à diverses
              sociétés qui permettent de fournir nos services (hébergeur web,
              service d'envoi de mail,) qui peuvent recevoir, accueillir et
              traiter ces données. Selon leur sensibilité, différents procédés
              tels que le cryptage peuvent être appliqués aux données pour les
              protéger afin qu'elles ne soient pas accessibles ou lisibles. Par
              exemple, votre mot de passe est crypté à chaque utilisation, ce
              qui vous permet d'être seul à connaitre la combinaison de
              caractères que vous avez choisie.
            </p>
            <h3>
              <strong>
                7. Durées de conservation de vos données personnelles
              </strong>
            </h3>
          </li>
        </ul>

        <p>
          Vos données sont conservées sous une forme permettant votre
          identification uniquement pendant le temps nécessaire à
          l'accomplissement des finalités poursuivies décrites ci- dessus.
        </p>
        <p>
          Cependant, le traitement des données est possible pour fournir la
          preuve d'un droit. Ces données peuvent également être conservées dans
          l'objectif de respecter une obligation légale ou gardées dans des
          fichiers conformément aux règlements et lois applicables.
        </p>
        <p>Par exception :</p>
        <ul>
          <li>
            <p>
              les données sont conservées pendant une durée de 3 ans à compter
              de la fin de la relation commerciale ou de notre dernier contact
              avec vous ;
            </p>
          </li>
          <li>
            <p>
              si vous avez effectué une demande d'exercice de l'un de vos droits
              sur vos données personnelles : vos données seront conservées
              pendant une durée de 1 an après votre demande ;
            </p>
          </li>
          <li>
            <p>
              Les données personnelles issues des cookies assurant l'analyse de
              la navigation sur notre site internet sont conservées pendant une
              durée de 6 mois ;
            </p>
          </li>
        </ul>
        <p>
          Les données personnelles issues des cookies nécessaires au
          fonctionnement et à la mesure d'audience de notre site internet sont
          conservées 25 mois.
        </p>
        <h3>
          <strong>8. Vos droits</strong>
        </h3>
        <p>
          Conformément à la réglementation applicable, vous disposez des droits
          suivants :
        </p>
        <p>
          <strong>Droit d'accès</strong> : Vous pouvez accéder à l'ensemble des
          informations vous concernant, connaître l'origine des informations
          vous concernant, obtenir la copie de vos données (les frais n'excédant
          pas le coût de reproduction peuvent vous être demandés), exiger que
          vos données soient selon les cas, rectifiées, complétées, mises à jour
          ou supprimées.
        </p>
        <p>
          <strong>Droit de rectification</strong> : Ce droit vous permet
          d'obtenir la rectification des données vous concernant lorsqu'elles
          sont inexactes, ou que les données incomplètes vous concernant soient
          complétées.
        </p>

        <p>
          <strong>Droit d'effacement</strong>: Ce droit vous permet d'obtenir
          l'effacement des données vous concernant, sous certaines conditions
          telles que prévues par l'article 17 du RGPD.
        </p>
        <p>
          <strong>Droit à la limitation du traitement</strong> : Vous avez le
          droit d'obtenir la limitation du traitement lorsque l'une des
          conditions prévues à l'article 18 du RGPD s'applique.
        </p>
        <p>
          <strong>Droit d'opposition</strong> : Vous pouvez vous opposer à tout
          moment à l'utilisation de certaines de vos données pour des raisons
          tenant à votre situation particulière.
        </p>
        <p>
          <strong>Droit à la portabilité de vos données</strong> : Vous avez le
          droit de récupérer une copie des données personnelles vous concernant
          ou d'obtenir leur transfert vers un autre organisme.
        </p>
        <p>
          <strong>
            Droit d'organiser le sort de vos données personnelles après la mort
          </strong>{" "}
          : Vous avez le droit de nous indiquer vos directives relatives à la
          conservation, à l'effacement et à la communication de vos données
          après votre décès, notamment le droit de choisir que nous
          communiquions (ou non) vos données à un tiers que vous aurez
          préalablement désigné.
        </p>
        <p>
          En cas de décès et à défaut d'instructions de votre part, nous nous
          engageons à détruire vos données, sauf si leur conservation s'avère
          nécessaire à des fins probatoires ou pour répondre à une obligation
          légale.
        </p>
        <p>
          <strong>
            Droit d'introduire une réclamation auprès de la Commission Nationale
            Informatique et Libertés (CNIL)
          </strong>{" "}
          : Vous pouvez saisir la CNIL si vous estimez que vos droits de ne sont
          pas respectés. https://www.cnil.fr/fr/agir.
        </p>
        <p>
          Si vous souhaitez exercer l'un de vos droits, ou si vous avez une
          question relative à la présente politique vous nous contacter par
          e-mail à l'adresse : contact@adhouse.com ou par courrier à Adhouse, 21
          SQ ANTOINE WATTEAU APT 33, 60100 CREIL, en indiquant vos coordonnées
          (non, prénom, adresse), et un motif légitime lorsque cela est exigé
          par la loi (notamment si vous souhaitez exercer votre droit
          d'opposition au traitement).
        </p>
        <h3>
          <strong>9. Mises à jour de cet avis de confidentialité</strong>
        </h3>
        <p>
          La présente politique de confidentialité peut être amenée à changer.
          Toute diminution de vos droits dans le cadre de cette politique ne
          saurait être appliquée sans votre consentement exprès. Toute
          modification des règles de confidentialité sera indiquée sur cette
          page et dans le cas de modifications notoires, nous publierons un
          avertissement et pourrons vous en notifier par email au besoin.
        </p>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Confidentialite;
