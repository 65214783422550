import * as React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ClientRequests from "../../../Api/ClientRequests";
import AuthService from "../../Login/services";

import { Truncate } from "react-lodash";
import { Container, Grid } from "@mui/material";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
moment.locale("fr");
const Notification = ({ anchorElMessage, openMessage, handleMessageClose }) => {
  const currentUser = AuthService.getCurrentUser();
  const [value, setValue] = React.useState("1");
  const [messages, setMessage] = React.useState(null);
  const [orders, setOrders] = React.useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    ClientRequests.get_token(
      "home/latest_messages/",
      currentUser.auth_token
    ).then((result) => setMessage(result));

    ClientRequests.get_token(
      "home/latest_orders/",
      currentUser.auth_token
    ).then((result) => setOrders(result));
  }, []);

  return (
    <Menu
      anchorEl={anchorElMessage}
      id="account-menu"
      open={openMessage}
      onClose={handleMessageClose}
      //   onClick={handleMessageClose}
      PaperProps={{
        elevation: 0,
        sx: {
          p: 1,
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: { md: 0 },
            bottom: { xs: 0 },
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Messages" value="1" />
              <Tab label="Collaboration" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ p: 1 }}>
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                pb: 4,
                bgcolor: "background.paper",
              }}
            >
              {messages?.map((item) => (
                <>
                  <ListItem key={item?.id} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={
                          item?.senderinfo?.first_name +
                          " " +
                          item?.senderinfo?.last_name
                        }
                        src={
                          global.config.infos.image_url +
                          item?.senderinfo?.avatar
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        item?.senderinfo?.first_name +
                        " " +
                        item?.senderinfo?.last_name
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {moment(item?.created_at).fromNow()}
                          </Typography>
                          {" — "}
                          <Truncate
                            string={item?.message}
                            options={{ length: 15 }}
                          />
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              ))}
            </List>
            <Box
              sx={{
                textAlign: "center",
                background: "#26cfe7",
                padding: "8px",
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              <Link
                to="/messages"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                Voir plus
              </Link>
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ p: 1 }}>
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                pb: 4,
                bgcolor: "background.paper",
              }}
            >
              {orders?.map((item) => (
                <>
                  <ListItem key={item?.id} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={
                          item?.message?.sender?.first_name +
                          " " +
                          item?.message?.sender?.last_name
                        }
                        src={
                          global.config.infos.image_url +
                          item?.message?.sender?.avatar
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        item?.message?.sender?.first_name +
                        " " +
                        item?.message?.sender?.last_name
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {moment(item?.created_at).fromNow()}
                          </Typography>
                          {" — "}
                          <Truncate
                            string={item?.cntr?.title}
                            options={{ length: 10 }}
                          />
                          {" | " + item?.progress}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              ))}
              <Box
                sx={{
                  textAlign: "right",
                  background: "#26cfe7",
                  padding: "8px 2px",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  left: 0,
                }}
              >
                <Container>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Link
                        to="/benefice"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        Gains
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        to="/dashboard"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        Tableau de bord
                      </Link>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </List>
          </TabPanel>
        </TabContext>
      </Box>
    </Menu>
  );
};

export default Notification;
