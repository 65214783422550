import * as React from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import ClientRequests from "../../Api/ClientRequests";
import AuthService from "../../Layouts/Login/services";
const drawerWidth = 360;
function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
const Users = ({ user, username }) => {
  const currentUser = AuthService.getCurrentUser();
  let navigate = useNavigate();
  const [nonlu, setNonlu] = React.useState({});
  React.useEffect(() => {
    const interval = setInterval(() => {
      ClientRequests.get_token(
        "home/message_last/" + user.id + "/",
        currentUser.auth_token
      ).then((res) => {
        setNonlu(res);
      });
    }, 2000);
    return () => clearInterval(interval);
  }, [username, user]);

  return (
    <>
      <ListItem
        button
        className={user.username === username && "ActiveUser"}
        onClick={() => navigate("/messages/" + user.username)}
        sx={{
          padding: "30px 10px",
          borderBottom: "1px solid #fff",
          background: isEmpty(nonlu) ? "#f8f8f8" : "rgba(0, 192, 217,0.2)",
          marginBottom: "1px",
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "unset",
          }}
        >
          <Avatar
            alt={user.first_name}
            src={global.config.infos.image_url + user.avatar}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            user.first_name
              ? user.first_name + " " + user.last_name
              : "John Doe"
          }
          sx={{ display: { md: "inline", xs: "none" }, ml: 1 }}
        />
      </ListItem>
    </>
  );
};

export default Users;
