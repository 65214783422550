import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BeautyStars from "beauty-stars";
import Typography from "@mui/material/Typography";

const Rate = ({ open, handleClose, handleRate, errors, profile }) => {
  const [rating, setRating] = React.useState({
    rating: 0,
    review: "",
  });
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Rédiger un avis</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ mt: 2, mb: 2 }}>
              Évaluez votre expérience
            </Typography>

            <BeautyStars
              value={rating.rating}
              onChange={(value) => setRating({ ...rating, rating: value })}
            />
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            helperText={errors.review}
            onInput={(e) => setRating({ ...rating, review: e.target.value })}
            label="Commentaire"
            fullWidth
            id="filled-basic"
            variant="filled"
          />
          <Typography variant="caption">
            En validant votre avis, vous vous engagez à avoir pris connaissance
            des CGU et à les respecter.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Annuler</Button>
          <Button onClick={() => handleRate(rating)}>Envoyer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Rate;
