import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Container, TextField } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import FormHelperText from "@mui/material/FormHelperText";
import AuthService from "../../Layouts/Login/services";
import Autocomplete from "@mui/material/Autocomplete";
import ClientRequests from "../../Api/ClientRequests";
import { useNavigate } from "react-router-dom";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const Add = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [platforms, setPlatforms] = React.useState({});
  const [villes, setVilles] = React.useState({});
  const handleClose = () => {
    setLoading(false);
  };
  const handleRegister = async (data) => {
    setLoading(true);
    setTimeout(async () => {
      const add = await ClientRequests.post_token(
        "marque/annonce/",
        currentUser.auth_token,
        {
          ...data,
          user: currentUser.profileinfos.id,
        }
      );
      const reponse = await add.json();
      if (add.ok === true) {
        setLoading(false);
        navigate("/annonces");
      } else {
        setLoading(false);
        setErrors(reponse);
      }
    }, 2000);
  };
  const fetchVilles = async (url) => {
    const res = await fetch(url);
    const data = await res.json();
    return data;
  };
  React.useEffect(() => {
    AuthService.fetchData("home/platforms/", currentUser.auth_token).then(
      (res) => {
        setPlatforms(res);
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      maxWidth="lg"
      sx={{
        p: { xs: 0, md: 5 },
        borderRadius: "10px",
        border: "1px solid #0DEDA3",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Item>
            <TextField
              id="standard-basic"
              label="Titre"
              helperText={errors.title}
              onInput={(e) => setValues({ ...values, title: e.target.value })}
              variant="standard"
              fullWidth
            />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <TextField
              id="outlined-multiline-static"
              label="Description"
              helperText={errors.description}
              onInput={(e) =>
                setValues({ ...values, description: e.target.value })
              }
              multiline
              rows={6}
              fullWidth
            />
          </Item>
        </Grid>

        <Grid item md={3} xs={6}>
          <Item>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">
                Budget
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                onInput={(e) =>
                  setValues({ ...values, budget: e.target.value })
                }
                startAdornment={
                  <InputAdornment position="start">€</InputAdornment>
                }
                label="Amount"
              />
              <FormHelperText>{errors?.budget}</FormHelperText>
            </FormControl>
          </Item>
        </Grid>

        <Grid item md={3} xs={6}>
          <Item>
            <TextField
              id="standard-basic"
              label="Ville"
              helperText={errors.ville}
              onInput={(e) => setValues({ ...values, ville: e.target.value })}
              fullWidth
            />
          </Item>
        </Grid>
        <Grid item md={3} xs={6}>
          <Item>
            <TextField
              id="standard-basic"
              label="Nombre d'abonnés"
              helperText={errors.abonnee}
              onInput={(e) => setValues({ ...values, abonnee: e.target.value })}
              fullWidth
            />
          </Item>
        </Grid>

        <Grid item md={3} xs={6}>
          <Item>
            <Autocomplete
              multiple
              id="multiple-limit-tags"
              options={platforms}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Cible" placeholder="Ajouter.." />
              )}
              sx={{ width: "100%" }}
              onChange={(event, value) =>
                setValues({
                  ...values,
                  platforms: value.map((item) => item.id),
                })
              }
            />
            <FormHelperText>{errors?.platforms}</FormHelperText>
          </Item>
        </Grid>

        <Grid item xs={12}>
          <Item
            sx={{
              textAlign: "end",
            }}
          >
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              onClick={() => handleRegister(values)}
              variant="contained"
              sx={{
                background: "#0DEDA3",
              }}
            >
              Enregistrer
            </LoadingButton>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Add;
