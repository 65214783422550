import * as React from "react";
import MenuBar from "./Elements/MenuBar";
import Box from "@mui/material/Box";
import FMB from "./Elements/FMB";
import Menu from "./Elements/Menu";
const Header = ({ wishcounter, onUserChange, setWishcounter }) => {
  return (
    <>
      <Menu
        wishCounter={wishcounter}
        setWishcounter={setWishcounter}
        onUserChange={onUserChange}
      />
      <Box
        sx={{
          display: { md: "none", xs: "block" },
          width: "100%",
          height: "20px",
        }}
      />
    </>
  );
};

export default Header;
