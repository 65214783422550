import React from 'react'
import MenuBar from './Elements/MenuBar'
import Partner from './Elements/Partner';
import Newsletter from './Elements/Newsletter';
import Footermenus from './Elements/Footermenus';
const Footer = ({wishcounter}) => {
  return (
    <React.Fragment>
      <Partner />
      <Newsletter />
      <Footermenus />
      {/* <MenuBar wishCounter={wishcounter} /> */}
    </React.Fragment>
  )
}

export default Footer