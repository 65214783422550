import * as React from "react";
import Container from "@mui/material/Container";
import Category from "./Category";

const Popular = ({
  addwishlist,
  profiles,
  wishlist,
  utilisateur,
  setMore,
  more,
}) => {
  return (
    <Container
      maxWidth="xl"
      sx={{ mt: { xs: 0, md: 5 }, padding: "0px !important" }}
    >
      {profiles.map(
        (categories, k) =>
          categories?.users.length > 0 && (
            <Category
              categories={categories}
              key={k}
              addwishlist={addwishlist}
              wishlist={wishlist}
              utilisateur={utilisateur}
              setMore={setMore}
              more={more}
            />
          )
      )}
    </Container>
  );
};

export default Popular;
