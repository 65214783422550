import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import AuthService from "../../Layouts/Login/services";
import ListItems from "./Elements/ListItems";
import Button from "@mui/material/Button";
import ClientRequests from "../../Api/ClientRequests";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
function isEmpty(obj) {
  if (obj?.platform?.length === 0) {
    delete obj["platform"];
  }
  if (obj?.categories?.length === 0) {
    delete obj["categories"];
  }
  if (obj?.abonnes === null) {
    delete obj["abonnes"];
  }
  return Object.keys(obj).length === 0;
}
const List = ({ filter, AddAnnonceWishlist, annwishcounter }) => {
  const currentUser = AuthService.getCurrentUser();
  const [annonces, setAnnonces] = React.useState(null);
  const [favorix, setFavorix] = React.useState(false);
  React.useEffect(() => {
    if (isEmpty(filter)) {
      if (favorix) {
        ClientRequests.get_token(
          "marque/userwishlists/",
          currentUser.auth_token
        ).then((res) => {
          setAnnonces(res);
        });
      } else {
        AuthService.fetchData("marque/annonce/", currentUser.auth_token).then(
          (res) => {
            setAnnonces(res);
          }
        );
      }
    } else {
      if (favorix) {
        ClientRequests.post_token(
          "marque/userwishlists/",
          currentUser.auth_token,
          filter
        )
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setAnnonces(data);
          });
      } else {
        ClientRequests.post_token(
          "marque/filtredannonce/",
          currentUser.auth_token,
          filter
        )
          .then((data) => data.json())
          .then((res) => {
            setAnnonces(res);
          });
      }
    }
  }, [filter, favorix, annwishcounter]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Container
      maxWidth="xl"
      sx={{
        p: { xs: 2, md: 5 },
        borderRadius: "10px",
      }}
    >
      <Grid container sx={{ p: 2, mt: "-10px" }}>
        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <Button
            variant="contained"
            onClick={() => setFavorix(false)}
            sx={{
              width: { md: "95%", xs: "100%" },
              background: "#0DEDA3",
              color: "#000",
              "&:hover": {
                background: "#d7f4ea",
              },
            }}
            disabled={favorix ? false : true}
          >
            TOUS
          </Button>
        </Grid>
        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <Button
            variant="contained"
            onClick={() => setFavorix(true)}
            disabled={favorix ? true : false}
            sx={{
              width: { md: "95%", xs: "100%" },
              background: "#0DEDA3",
              color: "#000",
              "&:hover": {
                background: "#d7f4ea",
              },
            }}
          >
            Mes favoris
          </Button>
        </Grid>
      </Grid>
      {annonces?.map((annonce) => (
        <ListItems
          annonce={annonce}
          favorix={favorix}
          AddAnnonceWishlist={AddAnnonceWishlist}
        />
      ))}
      {annonces?.length == 0 && (
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography>
            <strong>Aucune annonce enregistrée</strong>
            <br />
            Les annonces que vous enregistrez sont affichées ici.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default List;
