import React from "react";
import "./App.css";
import Routes from "./Routers/Routers";
import "./config";
import { frFR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ConfirmProvider } from "material-ui-confirm";
function App() {
  const theme = createTheme(frFR);
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <Routes />
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
