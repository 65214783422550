import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Paper from "@mui/material/Paper";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Draweror from "../Annonces/Draweror";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import HomeIcon from "@mui/icons-material/Home";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import DescriptionIcon from "@mui/icons-material/Description";
import logowords from "../../../../assets/images/logoword.png";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LoginIcon from "@mui/icons-material/Login";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SettingsIcon from "@mui/icons-material/Settings";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmailIcon from "@mui/icons-material/Email";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { useNavigate } from "react-router-dom";
import FavorisSpace from "../Favoris/FavorisSpace";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import Notification from "./Notification";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EuroIcon from "@mui/icons-material/Euro";
import Affiliate from "./Affiliate";
export default function FMB({
  OpenModal,
  utilisateur,
  messages,
  counter,
  handleLogout,
  wishls,
  setUpd,
  setWishcounter,
  propocount,
  orderCount,
}) {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    left: false,
  });
  const [anchorElMessage, setAnchorElMesaage] = React.useState(null);

  const openMessage = Boolean(anchorElMessage);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMessageClick = (event) => {
    setAnchorElMesaage(event.currentTarget);
  };

  const handleMessageClose = () => {
    setAnchorElMesaage(null);
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ textAlign: "center" }}>
        {(utilisateur === null) | (utilisateur === undefined) ? (
          <Link
            to="#"
            onClick={() => OpenModal(true)}
            style={{ textDecoration: "none", color: "#00C0D9" }}
          >
            Connexion
          </Link>
        ) : (
          <Link
            to="#"
            onClick={handleLogout}
            style={{ textDecoration: "none", color: "#00C0D9" }}
          >
            Déconnexion
          </Link>
        )}
      </List>
      <Divider />
      <List>
        <ListItem button>
          <ListItemIcon>
            <HomeIcon sx={{ color: "#00C0D9" }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link
                to="/"
                style={{ textDecoration: "none", color: "rgb(67 67 67)" }}
              >
                Accueil
              </Link>
            }
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ContactMailIcon sx={{ color: "#00C0D9" }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link
                to="/contact"
                style={{ textDecoration: "none", color: "rgb(67 67 67)" }}
              >
                Contactez Nous
              </Link>
            }
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ContactSupportIcon sx={{ color: "#00C0D9" }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "rgb(67 67 67)" }}
              >
                Qui sommes nous ?
              </Link>
            }
          />
        </ListItem>

        {utilisateur?.profileinfos.role === "Influenceur" && (
          <ListItem button>
            <ListItemIcon>
              <ConnectWithoutContactIcon sx={{ color: "#00C0D9" }} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Link
                  to="/influenceurs"
                  style={{ textDecoration: "none", color: "rgb(67 67 67)" }}
                >
                  Influenceurs
                </Link>
              }
            />
          </ListItem>
        )}
      </List>
      <List
        sx={{
          textAlign: "left",
          p: 5,
          position: "absolute",
          right: "0",
          left: "0",
          bottom: "0",
        }}
      >
        <img src={logowords} style={{ height: "50px" }} alt="logo" />
      </List>
    </Box>
  );
  return (
    <Box sx={{ pb: 7, display: { md: "none", xs: "block" } }}>
      <CssBaseline />
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999 }}
        elevation={3}
      >
        <BottomNavigation showLabels>
          <BottomNavigationAction
            sx={{ color: "#00C0D9" }}
            label="Menu"
            onClick={toggleDrawer("left", true)}
            icon={<MenuOpenIcon sx={{ color: "#00C0D9" }} />}
          />
          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
          <BottomNavigationAction
            label="Favoris"
            sx={{ color: "#00C0D9", p: { xs: "6px 12px 12px" } }}
            icon={
              <FavorisSpace
                counter={counter}
                wishls={wishls}
                setWishcounter={setWishcounter}
                setUpd={setUpd}
              />
              // <Badge badgeContent={counter} max={5} color="secondary">
              //   {counter > 0 ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              // </Badge>
            }
          />

          {(utilisateur === null) | (utilisateur == undefined) ? (
            <></>
          ) : utilisateur.profileinfos.role !== "Influenceur" ? (
            <BottomNavigationAction
              label="Publier"
              sx={{ color: "#00C0D9", p: "6px 12px 9px" }}
              icon={<Draweror propocount={propocount} />}
            />
          ) : (
            <></>
          )}

          {(utilisateur === null) | (utilisateur == undefined) ? (
            <></>
          ) : (
            <>
              <BottomNavigationAction
                label="Messages"
                showLabel={true}
                onClick={() => navigate("/messages")}
                sx={{ color: "#00C0D9" }}
                icon={
                  <Badge
                    badgeContent={messages?.length || 0}
                    max={5}
                    color="secondary"
                  >
                    {messages?.length > 0 ? <EmailIcon /> : <MailOutlineIcon />}
                  </Badge>
                }
              />
              {utilisateur.profileinfos.role === "Influenceur" ? (
                <BottomNavigationAction
                  label="Affiliate"
                  showLabel={true}
                  sx={{ color: "#00C0D9" }}
                  icon={<Affiliate />}
                />
              ) : (
                <></>
              )}
            </>
          )}

          <BottomNavigationAction
            label="Compte"
            sx={{ color: "#00C0D9" }}
            icon={
              <Badge
                sx={{ "& .MuiBadge-badge": { top: "0px", right: "-5px" } }}
                badgeContent={orderCount || 0}
                max={5}
                color="secondary"
              >
                <PersonOutlineIcon />
              </Badge>
            }
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                p: 2,
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  bottom: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(90deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {(utilisateur === null) | (utilisateur === undefined) ? (
              <>
                <MenuItem component={Link} to="/inscription/influencer">
                  <HowToRegIcon sx={{ mr: 1, color: "#00C0D9" }} /> Rejoignez en
                  tant qu'influenceur
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to="/inscription/marque">
                  <BusinessCenterIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                  Rejoignez en tant que marque
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => OpenModal(true)}>
                  <LoginIcon sx={{ mr: 1, color: "#00C0D9" }} /> Connexion
                </MenuItem>
              </>
            ) : (
              <>
                {utilisateur.profileinfos.role === "Influenceur" ? (
                  <>
                    <MenuItem component={Link} to="/inscription/influenceur">
                      <HowToRegIcon sx={{ mr: 1, color: "#00C0D9" }} /> Profil
                    </MenuItem>
                    <MenuItem component={Link} to="/dashboard">
                      <DashboardIcon sx={{ mr: 1, color: "#00C0D9" }} />
                      <Badge
                        badgeContent={orderCount || 0}
                        max={5}
                        sx={{ "& .MuiBadge-badge": { right: "-15px" } }}
                        color="secondary"
                      >
                        Tableau de bord
                      </Badge>
                    </MenuItem>
                    <MenuItem component={Link} to="/benefice">
                      <EuroIcon sx={{ mr: 1, color: "#00C0D9" }} /> Gain
                    </MenuItem>
                    <MenuItem component={Link} to="/profile/edit/influenceur">
                      <SettingsIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                      Paramètres
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem component={Link} to="/inscription/marque">
                      <HowToRegIcon sx={{ mr: 1, color: "#00C0D9" }} /> Profil
                    </MenuItem>
                    <MenuItem component={Link} to="/dashboard">
                      <DashboardIcon sx={{ mr: 1, color: "#00C0D9" }} />
                      <Badge
                        badgeContent={orderCount || 0}
                        max={5}
                        sx={{ "& .MuiBadge-badge": { right: "-15px" } }}
                        color="secondary"
                      >
                        Tableau de bord
                      </Badge>
                    </MenuItem>
                    <MenuItem component={Link} to="/profile/edit/marque">
                      <SettingsIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                      Paramètres
                    </MenuItem>
                  </>
                )}
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <LoginIcon sx={{ mr: 1, color: "#00C0D9" }} /> Déconnexion
                </MenuItem>
              </>
            )}
          </Menu>
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
