import React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ClientRequests from "../../../Api/ClientRequests";
import AuthService from "../../../Layouts/Login/services";
import Alert from "@mui/material/Alert";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
const Infos = ({ profile }) => {
  const currentUser = AuthService.getCurrentUser();
  const [data, setData] = React.useState(null);
  const [passwords, setPasswords] = React.useState(null);
  const [errors, setErrors] = React.useState(null);
  const [message, setMessage] = React.useState({
    password: false,
    email: false,
  });
  const [modifiable, setModifiable] = React.useState(true);
  const handleModif = () => {
    setModifiable(!modifiable);
  };

  const handleUpdateInfo = (data) => {
    console.log(data);
    console.log(currentUser);

    ClientRequests.put_token("user/profile/", currentUser.auth_token, {
      ...data,
      user: currentUser.userinfos.id,
    })
      .then((response) => {
        if (response.ok === true) {
          setModifiable(!modifiable);
          console.log(response);
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePasswordChnage = async () => {
    try {
      const reset = await ClientRequests.post_token(
        "users/set_password/",
        currentUser.auth_token,
        passwords
      );
      console.log(reset);
      if (reset.ok === true) {
        setMessage({ email: false, password: true });
        setErrors(null);
      } else {
        const response = await reset.json();
        setErrors(response);
        setMessage({ email: false, password: false });
      }
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {}, [message]);
  console.log(message);
  return (
    <>
      <Grid item xs={8}>
        <Item>
          <Typography variant="h6">Informations générales</Typography>
        </Item>
      </Grid>
      <Grid item xs={4}>
        <Item sx={{ textAlign: "right" }}>
          {modifiable ? (
            <Button variant="text" onClick={handleModif}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Modifier
              </Typography>
            </Button>
          ) : (
            <Button variant="text" onClick={() => handleUpdateInfo(data)}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Enregistrer
              </Typography>
            </Button>
          )}
        </Item>
      </Grid>

      <Grid item md={12} xs={12} sx={{ p: 4 }}>
        <Item>
          <Typography
            variant="body1"
            sx={{ color: "rgb(9 207 233)", fontWeight: "700" }}
          >
            Profil
            <Divider />
          </Typography>
        </Item>
      </Grid>

      {currentUser.profileinfos.role === "Marque" && (
        <>
          <Grid item xs={6}>
            <Item>
              <TextField
                disabled={modifiable}
                id="filled-disabled"
                variant="filled"
                label="SIRET"
                onInput={(e) => setData({ ...data, siret: e.target.value })}
                defaultValue={profile.siret}
                helperText={errors?.siret}
                style={{ width: "100%" }}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <TextField
                disabled={modifiable}
                id="filled-disabled"
                variant="filled"
                label="Nom de société"
                onInput={(e) => setData({ ...data, company: e.target.value })}
                defaultValue={profile.company}
                helperText={errors?.company}
                style={{ width: "100%" }}
              />
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <TextField
                required
                disabled={modifiable}
                id="filled-disabled"
                variant="filled"
                label="Adresse"
                onInput={(e) =>
                  setData({ ...data, company_adresse1: e.target.value })
                }
                defaultValue={profile.company_adresse1}
                helperText={errors?.company_adresse1}
                style={{ width: "100%" }}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <TextField
                disabled={modifiable}
                id="filled-disabled"
                variant="filled"
                label="Complément d'adresse"
                onInput={(e) =>
                  setData({ ...data, company_adresse2: e.target.value })
                }
                defaultValue={profile.company_adresse2}
                helperText={errors?.company_adresse2}
                style={{ width: "100%" }}
              />
            </Item>
          </Grid>
        </>
      )}

      {currentUser.profileinfos.role === "Particulier" && (
        <>
          <Grid item xs={6}>
            <Item>
              <TextField
                disabled={modifiable}
                id="filled-disabled"
                label="Nom"
                helperText={errors?.first_name}
                onInput={(e) =>
                  setData({ ...data, first_name: e.target.value })
                }
                defaultValue={profile.first_name}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </Item>
          </Grid>

          <Grid item xs={6}>
            <Item>
              <TextField
                disabled={modifiable}
                id="filled-disabled"
                label="Prénom"
                helperText={errors?.last_name}
                onInput={(e) => setData({ ...data, last_name: e.target.value })}
                defaultValue={profile.last_name}
                variant="filled"
                sx={{ width: "100%" }}
              />
            </Item>
          </Grid>
        </>
      )}

      <Grid item xs={6}>
        <Item>
          <TextField
            disabled={modifiable}
            id="filled-disabled"
            label="Ville"
            helperText={errors?.ville}
            onInput={(e) => setData({ ...data, ville: e.target.value })}
            defaultValue={profile.ville}
            variant="filled"
            sx={{ width: "100%" }}
          />
        </Item>
      </Grid>

      <Grid item xs={6}>
        <Item>
          <TextField
            disabled={modifiable}
            id="filled-disabled"
            label="Code postal"
            helperText={errors?.codepostal}
            onInput={(e) => setData({ ...data, codepostal: e.target.value })}
            defaultValue={profile.codepostal}
            variant="filled"
            sx={{ width: "100%" }}
          />
        </Item>
      </Grid>

      <Grid item xs={12}>
        <Item>
          <TextField
            disabled={modifiable}
            id="filled-multiline-static"
            multiline
            label="Description"
            helperText={errors?.introduction}
            onInput={(e) => setData({ ...data, introduction: e.target.value })}
            defaultValue={profile.introduction}
            variant="filled"
            rows={4}
            sx={{ width: "100%", wordBreak: "break-all" }}
          />
        </Item>
      </Grid>

      <Grid item md={6} xs={12} sx={{ p: 4 }}>
        <Item>
          <Typography
            variant="body1"
            sx={{ mb: 4, color: "rgb(9 207 233)", fontWeight: "700" }}
          >
            Changer le mot de passe
            <Divider />
          </Typography>

          {message.password && (
            <Alert severity="success">
              Votre mot de passe à été changé avec succès
            </Alert>
          )}

          <TextField
            id="standard-basic"
            type="password"
            label="Mot de passe actuel"
            helperText={errors?.current_password}
            onInput={(e) =>
              setPasswords({ ...passwords, current_password: e.target.value })
            }
            variant="standard"
            sx={{ width: "100%" }}
          />
          <TextField
            id="standard-basic"
            type="password"
            label="Nouveau mot de passe"
            helperText={errors?.new_password}
            onInput={(e) =>
              setPasswords({ ...passwords, new_password: e.target.value })
            }
            variant="standard"
            sx={{ width: "100%" }}
          />

          <TextField
            id="standard-basic"
            type="password"
            label="Confirmer le nouveau mot de passe"
            helperText={errors?.non_field_errors}
            onInput={(e) =>
              setPasswords({ ...passwords, re_new_password: e.target.value })
            }
            variant="standard"
            sx={{ width: "100%" }}
          />
        </Item>
        <Item>
          <Button
            onClick={handlePasswordChnage}
            variant="contained"
            sx={{ backgroundColor: "rgb(9 207 233)", width: "100%" }}
          >
            Mettre à jour le mot de passe
          </Button>
        </Item>
      </Grid>

      <Grid item md={6} xs={12} sx={{ p: 4 }}>
        <Item>
          <Typography
            variant="body1"
            sx={{ mb: 4, color: "rgb(9 207 233)", fontWeight: "700" }}
          >
            Changer l'adresse email
            <Divider />
          </Typography>

          <TextField
            disabled
            id="standard-basic"
            label="Email"
            helperText={errors?.email}
            variant="standard"
            defaultValue={currentUser.userinfos.email}
            sx={{ width: "100%" }}
          />
        </Item>
        <Item>
          <Button
            variant="contained"
            sx={{ backgroundColor: "rgb(9 207 233)", width: "100%" }}
          >
            Mettre à jour l'email
          </Button>
        </Item>
      </Grid>

      <Grid item xs={6} />
      <Grid item xs={6}></Grid>
    </>
  );
};

export default Infos;
