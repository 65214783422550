import React from "react";
import Grid from "@mui/material/Grid";
import { Divider, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ClientRequests from "../../../Api/ClientRequests";
import Alert from "@mui/material/Alert";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));
const Newsletter = () => {
  const [email, setEmail] = React.useState({
    email: "",
  });
  const [error, setError] = React.useState({
    message: "",
  });
  const [success, setSuccess] = React.useState(false);
  const addContact = async () => {
    try {
      const query = await ClientRequests.post_("home/newsletter/", email);
      console.log(query);
      if (query.ok) {
        setSuccess(true);
      } else {
        const data = await query.json();
        console.log(data);
        setError(data);
      }
    } catch (errors) {
      console.log(errors);
    }
  };
  return (
    <Box sx={{ mt: 15, pb: 15, background: "#ededed" }}>
      <Grid container>
        {/* offset grid with column you want to offset */}
        <Grid item md={2} xs={0} />
        {/* main grid column! */}

        <Grid item md={8} xs={12}>
          <Typography
            variant="h5"
            component="h3"
            sx={{ p: 5, textAlign: "center" }}
          >
            Newsletter
          </Typography>
          <Divider />

          <Box sx={{ padding: "50px 20px" }}>
            {success ? (
              <Alert severity="success">
                Bienvenue ! Votre inscription a bien été prise en compte sur
                l'adresse email {email?.email} et nous vous en remercions.
              </Alert>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Item elevation={0} sx={{ backgroundColor: "transparent" }}>
                    <TextField
                      fullWidth
                      id="demo-helper-text-aligned"
                      type="email"
                      label="Email"
                      helperText={error?.message}
                      onInput={(e) => setEmail({ email: e.target.value })}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Item
                    elevation={0}
                    sx={{ textAlign: "center", mt: { xs: 2, md: 0 } }}
                  >
                    <Button
                      variant="contained"
                      fullWidth={true}
                      sx={{
                        height: "100%",
                        mt: -1,
                        backgroundColor: "#00C0D9",
                      }}
                      onClick={addContact}
                    >
                      Subscribe
                    </Button>
                  </Item>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid item md={2} xs={0} />
      </Grid>
    </Box>
  );
};

export default Newsletter;
