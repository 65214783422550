import React from "react";
import Countdown from "react-countdown";

import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import Contrat from "./ContratElements/Contrat";
import Recap from "./ContratElements/Recap";
import ClientRequests from "../Api/ClientRequests";
import AuthService from "../Layouts/Login/services";
import Paypal from "./ContratElements/Paypal";
import PaymentForm from "./ContratElements/buying/Paymentform";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Rate from "../ProfilePage/Influencer/Parts/Rate";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import StarIcon from "@mui/icons-material/Star";
import { useConfirm } from "material-ui-confirm";
import Avatar from "@mui/material/Avatar";

// when you toggle to live mode, you should add the live publishale key.

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const stripePromise = loadStripe(
  "pk_test_51MFevRLtl3e5LtbL1P0HGKMhBAJ5QodokR46oDClExeJtCe1UzXhjmgubLIGzylIxZNedPmjGwx14xTOPPNecva100O4AxVY3v"
);
const appearance = {
  theme: "night",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "2px solid #0DEDA3",
  background: "#fff",
  overflow: "auto",
}));

const Paiment = () => {
  const confirm = useConfirm();
  let { contratid } = useParams();
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [utilisateur, setUtilisateur] = React.useState(null);
  const [contrat, setContrat] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [terminate, setTerminate] = React.useState(0);
  const [order, setOrder] = React.useState(null);
  const [ratings, setRating] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState({
    rating: "",
    review: "",
  });
  const [snackbaropen, setSnackBarOpen] = React.useState({
    open: false,
    type: "success",
    text: "",
  });
  const handleRate = async (data) => {
    data = { ...data, receiver: contrat?.sender, contrat: contratid };
    try {
      const rat = await ClientRequests.post_token(
        "influencers/rating/",
        currentUser.auth_token,
        data
      );

      if (rat.ok) {
        const re = await rat.json();
        setSnackBarOpen({
          open: true,
          type: "success",
          text: "le review a bien été ajouter",
        });
        setRating([...ratings, re]);
      } else {
        const response = await rat.json();
        if ("validity" in response) {
          setSnackBarOpen({
            open: true,
            type: "error",
            text: response.validity,
          });
        } else {
          setSnackBarOpen({
            open: true,
            type: "error",
            text: "Un erreur est survenu, vous pouvez repeter ultérieurement",
          });
        }
      }
    } catch (err) {
      console.log(err);
    }

    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSneackbarClose = () => {
    setSnackBarOpen(false);
  };

  const handleCompleted = (progress, completion, description) => {
    confirm({
      description: description,
      title: "Êtes-vous sûr ?",
      confirmationText: "OUI",
      cancellationText: "NON",
    })
      .then(() => {
        ClientRequests.patch_token(
          "home/contrat_terminate/" + contratid + "/",
          currentUser.auth_token,
          {
            progress: progress,
            completion: completion,
          }
        ).then((res) => {
          setTerminate(0);
        });
      })
      .catch(() => {
        console.log("Annuler");
      });
  };
  React.useEffect(() => {
    if ("user" in localStorage) {
      setUtilisateur(JSON.parse(localStorage.getItem("user")));
    } else {
      navigate("/");
    }
    ClientRequests.get_token(
      "home/message_single_contrat/" + contratid + "/",
      currentUser.auth_token
    ).then((result) => {
      console.log(result);
      setContrat(result);
    });
    ClientRequests.post_token("influencers/hasrated/", currentUser.auth_token, {
      contrat: contratid,
    }).then((response) => {
      console.log(response);
      if (response.ok === false) {
        setOpen(true);
      }
    });

    ClientRequests.post_token(
      "influencers/contrat_rating/",
      currentUser.auth_token,
      {
        contrat: contratid,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setRating(data);
      });
    ClientRequests.get_token(
      "home/orderhistory/" + contratid + "/",
      currentUser.auth_token
    ).then((res) => {
      setOrder(res);
      console.log(res);
      if (res?.progress === "Cancelled") {
        setTerminate(3);
      }
      if (res?.progress === "Completed") {
        setTerminate(2);
      }
      if (res?.progress === "Pending") {
        setTerminate(1);
      }
    });

    ClientRequests.post_token(
      "home/paid_validation/" + contratid + "/",
      currentUser.auth_token
    ).then((res) => {
      if (res.ok) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    });
  }, [success, terminate]); // eslint-disable-line react-hooks/exhaustive-deps
  const Completionist = () => <span>Le temps est terminé</span>;

  // Renderer callback with condition
  const renderRating = (mduim, small) => {
    return (
      <>
        {success && (
          <>
            {terminate === 2 && (
              <>
                {ratings.map((rating) => (
                  <Item sx={{ mt: 1, display: { md: mduim, xs: small } }}>
                    <Typography>Avis - {rating?.created_at}</Typography>
                    <Divider />
                    <Box sx={{ p: 2, borderBottom: "1px solid #eee" }}>
                      <Grid container>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            borderRight: "1px solid #eee",
                            textAlign: "center",
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            sx={{ m: "auto" }}
                            src={
                              global.config.infos.image_url +
                              rating?.sender_details?.avatar
                            }
                          />
                          <Typography>
                            {rating?.sender_details?.first_name ||
                              rating?.sender_details?.company}{" "}
                            {" " + rating?.sender_details?.last_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ paddingLeft: 2 }}>
                          <Typography>
                            {[...Array(rating?.rating)].map((e, i) => (
                              <StarIcon
                                sx={{
                                  fontSize: "1rem",
                                  color: "rgb(203 201 3)",
                                }}
                              />
                            ))}
                          </Typography>
                          <Typography>{rating?.review} </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Item>
                ))}
              </>
            )}
          </>
        )}
      </>
    );
  };
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown

      const stilin = {
        background: "#eee",
        height: 50,
        lineHeight: "30px",
        textAlign: "center",
        border: "2px solid #fff",
        fontSize: "20px",
        fontWeight: "900",
      };

      return (
        <Grid container>
          <Grid item sx={stilin} xs={3}>
            {days}
            <Typography sx={{ fontSize: "10px" }}>Jours</Typography>
          </Grid>
          <Grid item sx={stilin} xs={3}>
            {hours}
            <Typography sx={{ fontSize: "10px" }}>Heures</Typography>
          </Grid>
          <Grid item sx={stilin} xs={3}>
            {minutes}
            <Typography sx={{ fontSize: "10px" }}>Minutes</Typography>
          </Grid>
          <Grid item sx={stilin} xs={3}>
            {seconds}
            <Typography sx={{ fontSize: "10px" }}>Secondes</Typography>
          </Grid>
        </Grid>
      );
    }
  };
  return (
    <React.Fragment>
      <Snackbar
        open={snackbaropen.open}
        autoHideDuration={3000}
        onClose={handleSneackbarClose}
      >
        <Alert severity={snackbaropen.type} sx={{ width: "100%" }}>
          {snackbaropen.text}
        </Alert>
      </Snackbar>
      <Header onUserChange={setUtilisateur} />
      <Box
        sx={{
          marginTop: { xs: "50px", md: "150px" },
        }}
      />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item md={8} xs={12} order={{ md: 1, xs: 2 }}>
            <Contrat data={contrat?.messagecontrat[0] || {}} />
            {renderRating("none", "block")}
          </Grid>
          {contrat !== null && (
            <Grid item md={4} xs={12} order={{ md: 2, xs: 1 }}>
              {success && (
                <>
                  {(order?.is_completed === true) &
                  (order?.completed === "Completed") ? (
                    <Item sx={{ mb: 2 }}>
                      {contrat?.messagecontrat[0]?.date_fin != undefined && (
                        <Countdown
                          date={contrat?.messagecontrat[0]?.date_fin}
                          renderer={renderer}
                        />
                      )}
                    </Item>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {contrat?.receiver === currentUser?.profileinfos?.id ? (
                <Item>
                  <Recap data={contrat?.messagecontrat[0]} success={success} />
                  {success ? (
                    <>
                      {terminate !== 3 ? (
                        <>
                          <Typography
                            sx={{ p: 2, mt: 1, fontSize: 11, color: "red" }}
                          >
                            En cas d'insatisfaction, demander à l'influenceur
                            d'annuler la collaboration, ou contacter l'équipe
                            d'assistance Adhouse.
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          sx={{ color: "red", m: 2, fontWeight: 900 }}
                        >
                          La collaboration a été annulée. Les fonds versés
                          apparaîtront sur votre compte bancaire dans quelques
                          jours.
                        </Typography>
                      )}

                      {order === null ? (
                        <></>
                      ) : terminate == 0 ? (
                        <></>
                      ) : (
                        <>
                          {terminate === 1 ? (
                            <>
                              {order?.progress == "Pending" &&
                              order?.is_completed === false ? (
                                <></>
                              ) : (
                                <>
                                  <Typography>Action</Typography>
                                  <Divider />
                                  <Button
                                    onClick={() =>
                                      handleCompleted(
                                        "Completed",
                                        true,
                                        "En cliquant sur 'Valider', vous confirmez que la prestation de l'influenceur a été réalisée conformément à vos attentes et que vous êtes satisfait(e) du travail effectué. Veuillez noter que le paiement sera débloqué et transféré à l'influenceur une fois que vous aurez validé la prestation."
                                      )
                                    }
                                    sx={{
                                      width: "100%",
                                      mt: 2,
                                      mb: 2,
                                      background: "#00eaa5",
                                    }}
                                    variant="contained"
                                  >
                                    Valider la prestation
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      handleCompleted(
                                        "Pending",
                                        false,
                                        "Vous êtes sur le point de cliquer sur le bouton 'Rejeter' pour indiquer que la prestation fournie par l'influenceur ne correspond pas à vos attentes. "
                                      )
                                    }
                                    sx={{
                                      width: "100%",
                                    }}
                                    variant="contained"
                                    color="error"
                                  >
                                    Rejeter la prestation
                                  </Button>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {terminate === 2 && (
                            <>
                              <Typography
                                sx={{ color: "green", m: 2, fontWeight: 900 }}
                              >
                                Prestation validée
                              </Typography>
                              <Rate
                                handleRate={handleRate}
                                profile={contrat?.receiver}
                                errors={errors}
                                handleClose={handleClose}
                                open={open}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Elements stripe={stripePromise} options={{ locale: "fr" }}>
                      <PaymentForm
                        success={success}
                        setSuccess={setSuccess}
                        contrat={contrat?.messagecontrat[0] || {}}
                      />
                    </Elements>
                  )}
                </Item>
              ) : (
                <Item>
                  {success && (
                    <>
                      <Typography>Action</Typography>
                      <Divider />

                      {order === null ? (
                        <></>
                      ) : (
                        <>
                          {(terminate != 2) & (terminate != 3) ? (
                            <>
                              {order?.progress == "Pending" &&
                              order?.is_completed == true ? (
                                <Typography
                                  sx={{
                                    mt: 2,
                                    fontWeight: 900,
                                    color: "green",
                                  }}
                                >
                                  En attente de l'acceptation
                                </Typography>
                              ) : (
                                <>
                                  <Button
                                    onClick={() =>
                                      handleCompleted(
                                        "Pending",
                                        true,
                                        "Veuillez noter que cela signifie que vous avez accompli toutes les tâches qui vous ont été assignées."
                                      )
                                    }
                                    sx={{
                                      width: "100%",
                                      mt: 2,
                                      mb: 2,
                                      background: "#00eaa5",
                                    }}
                                    variant="contained"
                                  >
                                    Terminer
                                  </Button>
                                  <Button
                                    sx={{
                                      width: "100%",
                                    }}
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      handleCompleted(
                                        "Cancelled",
                                        true,
                                        'Vous êtes sur le point de cliquer sur "Annuler la collaboration". Veuillez noter que cette action mettra fin à la collaboration et que vous ne pourrez pas revenir en arrière une fois que vous aurez cliqué sur ce bouton.'
                                      )
                                    }
                                  >
                                    Annuler la collaboration
                                  </Button>
                                  <Typography
                                    sx={{ color: "red", fontSize: 12, p: 1 }}
                                  >
                                    En cas de demande d'annulation par la
                                    marque, sans arrangement possible, contacter
                                    le service d'assistance Adhouse pour
                                    résolution du litige.
                                  </Typography>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {terminate === 2 && (
                                <>
                                  <Typography
                                    sx={{
                                      color: "green",
                                      mt: 2,
                                      fontWeight: 900,
                                    }}
                                  >
                                    Prestation validée
                                  </Typography>
                                  <Rate
                                    handleRate={handleRate}
                                    profile={contrat?.sender}
                                    errors={errors}
                                    handleClose={handleClose}
                                    open={open}
                                  />
                                </>
                              )}

                              {terminate === 3 && (
                                <Typography
                                  sx={{
                                    color: "red",
                                    mt: 2,
                                    fontWeight: 900,
                                  }}
                                >
                                  Nous avons bien enregistré votre Annulation.
                                </Typography>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Item>
              )}
              {renderRating("block", "none")}
            </Grid>
          )}
        </Grid>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Paiment;
