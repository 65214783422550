import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import Home from "../Components/HomePage/Home";
import Contact from "../Components/ContactPage/Contact";
import About from "../Components/AboutPage/About";
import Unsubscribe from "../Components/UnsubscribePage/Unsubscribe";
import Legale from "../Components/LegalePage/Legale";
import Confidentialite from "../Components/LegalePage/Confidentialite";
import Cgu from "../Components/LegalePage/Cgu";
import Inscription from "../Components/InscriptionPage/Inscription";
import AuthService from "../Components/Layouts/Login/services";
import Profile from "../Components/ProfilePage/Profile";
import Profilepre from "../Components/ProfilePage/Profilepre";
import UserProfile from "../Components/ProfilePage/UserProfile";
import Messages from "../Components/MessagePage/Messages";
import EdituserProfile from "../Components/ProfilePage/EdituserProfile";
import Annonces from "../Components/AnnoncePage/Annonces";
import Influencers from "../Components/InfluencerPage/Influencers";
import UserMarqueProfile from "../Components/ProfilePage/Marque/UserMarqueProfile";
import { Helmet } from "react-helmet-async";
import Paiment from "../Components/ContratPage/Paiment";
import Benefice from "../Components/Benefice/Benefice";
import Refresh from "../Components/Stripe/Refresh";
import Dashboard from "../Components/Dashboard/Dashboard";
import PaimentStatus from "../Components/ContratPage/PaimentStatus";
const Routers = () => {
  const currentAffiliate = AuthService.getCurrentAffiliate();
  console.log(currentAffiliate);
  const ProtectedRoute = ({ children }) => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      return <Navigate to="/" replace />;
    }

    return children;
  };

  const ProtectedRoleRoute = ({ children }) => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      return <Navigate to="/" replace />;
    } else {
      if (
        currentUser.profileinfos.role === "Marque" ||
        currentUser.profileinfos.role === "Particulier"
      ) {
        return children;
      }
      return <Navigate to="/" replace />;
    }
  };

  const ProtectedRoleInfRoute = ({ children }) => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      return <Navigate to="/" replace />;
    } else {
      if (currentUser.profileinfos.role === "Influenceur") {
        return children;
      }
      return <Navigate to="/" replace />;
    }
  };

  const Authenticated = ({ children }) => {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      return <Navigate to="/profile" replace />;
    }

    return children;
  };
  const Affiliating = ({ children }) => {
    let { username } = useParams();
    localStorage.setItem("affiliate", JSON.stringify({ username: username }));
    return <Navigate to="/inscription/marque" replace />;
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Helmet>
                <title>Adhouse - Accueil</title>
                <meta
                  name="description"
                  content="Ad house est une plate-forme specialisée dans la mise en lien des influenceurs, créateurs de contenu et les entreprises ou à des particuliers"
                />
                <meta
                  name="keywords"
                  content="adhouse, influenceurs, marketing"
                />
                <link rel="canonical" href="https://adhouse.fr" />
              </Helmet>
              <Home />
            </>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <>
              <Helmet>
                <title>Adhouse - Contactez nous</title>
                <meta
                  name="description"
                  content="Contact Ad house équipe pour plus d'informations sur les influenceurs, créateurs de contenu et les entreprises ou les particuliers"
                />
                <meta
                  name="keywords"
                  content="adhouse, influenceurs, marketing"
                />
                <link rel="canonical" href="https://adhouse.fr/contact" />
              </Helmet>
              <Contact />
            </>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <>
              <Helmet>
                <title>Adhouse - Qui sommes nous?</title>
                <meta
                  name="description"
                  content="Ad house est une plate-forme specialisée dans la mise en lien des influenceurs, créateurs de contenu et les entreprises ou à des particuliers"
                />
                <meta
                  name="keywords"
                  content="adhouse, influenceurs, marketing"
                />
                <link rel="canonical" href="https://adhouse.fr/about" />
              </Helmet>
              <About />
            </>
          }
        />
        <Route exact path="/unsubscribe" element={<Unsubscribe />} />
        <Route
          exact
          path="/legale"
          element={
            <>
              <Helmet>
                <title>Adhouse - Légale</title>
                <meta
                  name="description"
                  content="Ad house est une plate-forme specialisée dans la mise en lien des influenceurs, créateurs de contenu et les entreprises ou à des particuliers"
                />
                <meta
                  name="keywords"
                  content="adhouse, influenceurs, marketing"
                />
                <link rel="canonical" href="https://adhouse.fr/legale" />
              </Helmet>
              <Legale />
            </>
          }
        />
        <Route
          exact
          path="/confidentialite"
          element={
            <>
              <Helmet>
                <title>Adhouse - Confidentialité</title>
                <meta
                  name="description"
                  content="Ad house est une plate-forme specialisée dans la mise en lien des influenceurs, créateurs de contenu et les entreprises ou à des particuliers"
                />
                <meta
                  name="keywords"
                  content="adhouse, influenceurs, marketing"
                />
                <link
                  rel="canonical"
                  href="https://adhouse.fr/confidentialite"
                />
              </Helmet>
              <Confidentialite />
            </>
          }
        />
        <Route
          exact
          path="/cgu"
          element={
            <>
              <Helmet>
                <title>Adhouse - Conditions générales d'utilisation</title>
                <meta
                  name="description"
                  content="Ad house est une plate-forme specialisée dans la mise en lien des influenceurs, créateurs de contenu et les entreprises ou à des particuliers"
                />
                <meta
                  name="keywords"
                  content="adhouse, influenceurs, marketing"
                />
                <link rel="canonical" href="https://adhouse.fr/cgu" />
              </Helmet>
              <Cgu />
            </>
          }
        />

        <Route
          exact
          path="/influenceurs"
          element={
            <ProtectedRoleInfRoute>
              <Influencers />
            </ProtectedRoleInfRoute>
          }
        />
        <Route
          exact
          path="/inscription/:pagename"
          element={
            <Authenticated>
              <Inscription />
            </Authenticated>
          }
        />
        <Route
          exact
          path="/follow/:username"
          element={
            <Authenticated>
              <Affiliating />
            </Authenticated>
          }
        />

        <Route
          exact
          path="/inscription/:pagename/:uid/:token"
          element={<Inscription />}
        />

        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoute>
              <Profilepre />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/profile/:pagename"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/profile/influenceur/:username"
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/profile/marque/:username"
          element={
            <ProtectedRoute>
              <UserMarqueProfile />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/profile/edit/:pagename"
          element={
            <ProtectedRoute>
              <EdituserProfile />
            </ProtectedRoute>
          }
        />

        <Route exact path="/messages">
          <Route
            index
            element={
              <ProtectedRoute>
                <Messages />
              </ProtectedRoute>
            }
          />
          <Route
            index
            path=":username"
            element={
              <ProtectedRoute>
                <Messages />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route exact path="/contrat">
          <Route
            index
            path=":contratid"
            element={
              <ProtectedRoute>
                <Paiment />
              </ProtectedRoute>
            }
          />
          <Route
            index
            path=":contratid/:status"
            element={
              <ProtectedRoute>
                <PaimentStatus />
              </ProtectedRoute>
            }
          />
          <Route
            index
            path=":contratid/:status/:checkoutid"
            element={
              <ProtectedRoute>
                <PaimentStatus />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route exact path="/annonces">
          <Route
            index
            element={
              <ProtectedRoleInfRoute>
                <Annonces />
              </ProtectedRoleInfRoute>
            }
          />
        </Route>

        <Route exact path="/stripe/refresh">
          <Route
            index
            element={
              <ProtectedRoleInfRoute>
                <Refresh />
              </ProtectedRoleInfRoute>
            }
          />
        </Route>

        <Route exact path="/benefice">
          <Route
            index
            element={
              <ProtectedRoleInfRoute>
                <Benefice />
              </ProtectedRoleInfRoute>
            }
          />
        </Route>
        <Route exact path="/dashboard">
          <Route
            index
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
