import React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import AuthService from "../../../Layouts/Login/services";
import ClientRequests from "../../../Api/ClientRequests";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Autocomplete from "@mui/material/Autocomplete";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Info = ({ handleAddInfos, errors, loading }) => {
  const [infform, setInfForm] = React.useState({
    first_name: "",
    last_name: "",
    pays: "France",
    ville: "",
    introduction: "",
  });

  const [codepostal, setCodepostal] = React.useState(null);
  const handleCodePostalverification = async () => {
    //
    if (infform.codepostal.length == 5) {
      const res = await fetch(
        "https://geo.api.gouv.fr/communes?codePostal=" + infform.codepostal,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (res.ok === true) {
        const result = await res.json();
        setInfForm({ ...infform, ville: result[0].nom });
        setCodepostal(null);
      } else {
        setCodepostal("Le code postal n'est pas valid");
      }
    } else {
      setInfForm({ ...infform, ville: "" });
      setCodepostal("Le code postal n'est pas valid");
    }
  };
  return (
    <>
      <Grid container sx={{ mt: 5 }} spacing={2}>
        <Grid container item xs={12} md={12} columnSpacing={15} rowSpacing={2}>
          <Grid item xs={12} sm={6}>
            <Item>
              <TextField
                required
                id="standard-basic"
                label="Nom"
                helperText={errors?.first_name}
                onInput={(e) =>
                  setInfForm({ ...infform, first_name: e.target.value })
                }
                style={{ width: "100%" }}
                variant="standard"
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              <TextField
                required
                id="standard-basic"
                label="Prenom"
                helperText={errors?.last_name}
                onInput={(e) =>
                  setInfForm({ ...infform, last_name: e.target.value })
                }
                style={{ width: "100%" }}
                variant="standard"
              />
            </Item>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Item>
              <Autocomplete
                id="country-select-demo"
                options={ClientRequests?.countries}
                autoHighlight
                defaultValue={{
                  code: "FR",
                  label: "France",
                  phone: "33",
                  suggested: true,
                }}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  setInfForm({ ...infform, pays: newValue?.label });
                }}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Pays"
                    helperText={errors?.pays}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Item>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Item>
              <TextField
                value={infform.ville != "" ? infform.ville : ""}
                required
                id="standard-basic"
                label="Ville"
                helperText={errors?.ville}
                onInput={(e) =>
                  setInfForm({ ...infform, ville: e.target.value })
                }
                style={{ width: "100%" }}
                variant="standard"
              />
            </Item>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Item>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                helperText={errors?.introduction}
                multiline
                onInput={(e) =>
                  setInfForm({ ...infform, introduction: e.target.value })
                }
                style={{ width: "100%" }}
                rows={6}
              />
            </Item>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <LoadingButton
          onClick={() => handleAddInfos(infform)}
          endIcon={<SendIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
          sx={{
            background: "#00C0D9",
          }}
        >
          Suivant
        </LoadingButton>
      </Box>
    </>
  );
};

export default Info;
