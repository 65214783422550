import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Influencer = () => {
  const [success, setsuccess] = new React.useState(false);
  const [backdropopen, setBackdropopen] = React.useState(false);

  const [errors, setError] = new React.useState({
    username: "",
    email: "",
    password: "",
    re_password: "",
    non_field_errors: "",
    confidentiality: "",
  });

  const [form, setForm] = new React.useState({
    username: "",
    email: "",
    password: "",
    repassword: "",
    confidentiality: false,
  });

  const registerHandle = async (e) => {
    if (form.confidentiality == false) {
      setError({ ...errors, confidentiality: "Ce champ est obligatoire" });
      return false;
    }
    setBackdropopen(true);
    const res = await fetch(global.config.infos.server_url + "users/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: form.email,
        username: form.username,
        password: form.password,
        re_password: form.repassword,
      }),
    });

    const data = await res.json();
    if (res.ok === true) {
      const profileres = await fetch(
        global.config.infos.server_url + "user/addprofile/",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            user: data.id,
            role: "Influenceur",
          }),
        }
      );

      if (profileres.ok === true) {
        setsuccess(true);
        window.scrollTo(0, 0);

        setBackdropopen(false);
      }
    } else {
      setBackdropopen(false);
      setError(data);
    }
  };
  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <Box sx={{ marginTop: "150px", display: { md: "block", xs: "none" } }} />
      <Box sx={{ marginTop: "50px", display: { md: "none", xs: "block" } }} />
      <Container maxWidth="xl">
        <Box sx={{ minHeight: "75vh" }}>
          <Grid container>
            {/* offset grid with column you want to offset */}
            <Grid item md={2} sm={0} />
            {/* main grid column! */}

            <Grid item md={8} sm={12}>
              <Typography variant="h5" sx={{ p: 5, textAlign: "center" }}>
                Rejoignez nous
              </Typography>
              <Divider />

              <Grid container item md={12} xs={12} spacing={2} sx={{ mt: 4 }}>
                {!success ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Item>
                        <TextField
                          required
                          id="username"
                          onInput={(e) =>
                            setForm({ ...form, username: e.target.value })
                          }
                          helperText={errors.username}
                          label="Username"
                          style={{ width: "100%" }}
                          variant="outlined"
                        />
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Item>
                        <TextField
                          required
                          id="email"
                          onInput={(e) =>
                            setForm({ ...form, email: e.target.value })
                          }
                          helperText={errors.email}
                          type="email"
                          label="Email"
                          style={{ width: "100%" }}
                          variant="outlined"
                        />
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Item>
                        <TextField
                          required
                          id="password"
                          onInput={(e) =>
                            setForm({ ...form, password: e.target.value })
                          }
                          helperText={errors.password}
                          type="password"
                          label="Mot de passe"
                          style={{ width: "100%" }}
                          variant="outlined"
                        />
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Item>
                        <TextField
                          required
                          id="repassword"
                          onInput={(e) =>
                            setForm({ ...form, repassword: e.target.value })
                          }
                          helperText={
                            errors.re_password || errors.non_field_errors
                          }
                          type="password"
                          label="Mot de passe confirmation"
                          style={{ width: "100%" }}
                          variant="outlined"
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Item>
                        <FormGroup>
                          <FormControlLabel
                            checked={form.confidentiality}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                confidentiality: e.target.checked,
                              })
                            }
                            control={
                              <Checkbox
                                sx={{
                                  color: "rgb(0, 192, 217)",
                                  "&.Mui-checked": {
                                    color: "rgb(0, 192, 217)",
                                  },
                                }}
                              />
                            }
                            label="* J'ai lu et accepté les conditions générales d'utilisation et la politique de confidentialité de Adhouse."
                          />
                          <FormHelperText
                            children={errors.confidentiality}
                            error={true}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  color: "rgb(0, 192, 217)",
                                  "&.Mui-checked": {
                                    color: "rgb(0, 192, 217)",
                                  },
                                }}
                              />
                            }
                            label="Je souhaite recevoir la newsletter par e-mail."
                          />
                        </FormGroup>
                      </Item>
                    </Grid>

                    <Grid item xs={12}>
                      <Item sx={{ textAlign: "right" }}>
                        <Button
                          variant="contained"
                          onClick={registerHandle}
                          style={{ backgroundColor: "#00C0D9" }}
                          endIcon={<SendIcon />}
                          size="large"
                        >
                          Inscrivez
                        </Button>
                      </Item>
                    </Grid>
                  </>
                ) : (
                  <Typography
                    sx={{ p: 2, textAlign: "center", margin: "auto" }}
                  >
                    <strong>Félicitation </strong>&#128522;
                    <br />
                    Votre profil a été créé avec succès, un message a été envoyé
                    à votre adresse e-mail.
                    <br />
                    <strong style={{ color: "red" }}>
                      Pensez à vérifier vos spams (courrier indésirable)
                    </strong>
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item md={2} sm={0} />
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Influencer;
