import React from "react";
import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import contactImage from "../../assets/images/header/blog-title-img-03.jpeg";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Unsubscribe = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [utilisateur, setUtilisateur] = React.useState(null);

  return (
    <React.Fragment>
      <CssBaseline />
      <Header wishcounter={0} onUserChange={setUtilisateur} />
      <Box sx={{ height: { xs: "50px", md: "150px" } }} />
      <Container maxWidth="md">
        <h3>Vous ne recevrez plus d'e-mail à partir de maintenant. </h3>
        <p>
          Cela inclura les e-mails concernant les nouvelles propositions et les
          messages reçu.
        </p>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Unsubscribe;
