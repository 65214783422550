import React from "react";
import Countdown from "react-countdown";

import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";

import ClientRequests from "../Api/ClientRequests";
import AuthService from "../Layouts/Login/services";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useConfirm } from "material-ui-confirm";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
// when you toggle to live mode, you should add the live publishale key.
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import Recap from "./Recap";
const stripePromise = loadStripe(
  "pk_test_51MFevRLtl3e5LtbL1P0HGKMhBAJ5QodokR46oDClExeJtCe1UzXhjmgubLIGzylIxZNedPmjGwx14xTOPPNecva100O4AxVY3v"
);
const appearance = {
  theme: "night",
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "2px solid #0DEDA3",
  background: "#fff",
  overflow: "auto",
}));
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
  },
});
const Benefice = () => {
  let { contratid } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [utilisateur, setUtilisateur] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [balance, setBalance] = React.useState(null);
  const [contrats, setContrats] = React.useState(null);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    ClientRequests.get_token(
      "home/orderhistorybytpe/completed/",
      currentUser.auth_token
    ).then((result) => {
      setContrats(result);
    });
    ClientRequests.get_token(
      "home/stripe/balance/",
      currentUser.auth_token
    ).then((result) => {
      console.log(result);
      setBalance(result);
    });
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log(balance);
  const handlTranserMoney = () => {
    if (balance?.available[0]?.amount / 100 > 0) {
      ClientRequests.post_token(
        "home/stripe/transfer/",
        currentUser.auth_token
      ).then((result) => {
        console.log("resultos", result);
        setSuccess((prev) => !prev);
      });
    }
  };

  const handlCreateLogin = () => {
    ClientRequests.post_token("home/stripe/login/", currentUser.auth_token)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        window.location = result?.url + "#/account";
      });
  };
  console.log(balance);
  return (
    <React.Fragment>
      <Header onUserChange={setUtilisateur} />
      <Box
        sx={{
          marginTop: { xs: "50px", md: "150px" },
        }}
      />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Item sx={{ mb: 2 }}>
              <Typography variant="h4">Contrats</Typography>
              <Divider />
              <Recap contrats={contrats} />
            </Item>
          </Grid>
          <Grid item md={4} xs={12}>
            <Item sx={{ mb: 2 }}>
              <Typography>
                {" "}
                Crédité sur votre compte:{" "}
                <span style={{ fontSize: "24px", fontWeight: "900" }}>
                  {(balance?.available[0]?.amount / 100 || 0)?.toFixed(2)}€
                </span>
              </Typography>
              <Typography>
                Paiement en attente
                <CustomWidthTooltip title="Le délai de disponibilité des virements peut varier en fonction de votre pays et de votre secteur d’activité. Dans la plupart des cas, une fois que vous aurez commencé à traiter des paiements en mode production sur Stripe, vous recevrez votre premier virement 7 à 14 jours après réception effective de votre premier paiement.">
                  <InfoIcon sx={{ fontSize: "17px" }} />
                </CustomWidthTooltip>
                :{" "}
                <span style={{ fontSize: "24px", fontWeight: "900" }}>
                  {(balance?.pending[0]?.amount / 100 || 0).toFixed(2)}€
                </span>
              </Typography>
              <Button
                variant="contained"
                disabled={balance?.available[0]?.amount === 0 ? true : false}
                sx={{
                  backgroundColor: "rgb(0, 192, 217)",
                  borderTop: "1px solid #747474",
                  mt: 3,
                  padding: "4px",
                  minWidth: "100px",
                  width: "100%",
                  fontSize: "12px",
                }}
                onClick={handlTranserMoney}
              >
                Transfert d'argent
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "rgb(0, 192, 217)",
                  borderTop: "1px solid #747474",
                  mt: 3,
                  padding: "4px",
                  minWidth: "100px",
                  width: "100%",
                  fontSize: "12px",
                }}
                onClick={handlCreateLogin}
              >
                Mon compte stripe
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Benefice;
