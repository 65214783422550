import * as React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import logowords from "../../../../assets/images/logoword.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ButtonGroup from "@mui/material/ButtonGroup";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import LoginIcon from "@mui/icons-material/Login";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import Draweror from "../Annonces/Draweror";
import FavorisSpace from "../Favoris/FavorisSpace";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Notification from "./Notification";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EuroIcon from "@mui/icons-material/Euro";
import Affiliate from "./Affiliate";
const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
}));

const pages = [
  { id: 0, name: "Accueil", slug: "/" },
  { id: 1, name: "Contactez Nous", slug: "/contact" },
  { id: 2, name: "Qui sommes nous", slug: "/about" },
];

const MenuBar = ({
  wishCounter,
  OpenModal,
  utilisateur,
  messages,
  counter,
  handleLogout,
  wishls,
  setWishcounter,
  setUpd,
  propocount,
  orderCount,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMessage, setAnchorElMesaage] = React.useState(null);

  const open = Boolean(anchorEl);
  const openMessage = Boolean(anchorElMessage);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMessageClick = (event) => {
    setAnchorElMesaage(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMessageClose = () => {
    setAnchorElMesaage(null);
  };
  return (
    <AppBar
      position="fixed"
      style={{ background: "#fff" }}
      sx={{ boxShadow: "2" }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={4} md={5} elevation={0}>
            <Item
              elevation={0}
              sx={{
                mt: 3,
                textAlign: "left",
                display: { md: "block", xs: "none" },
              }}
            >
              {pages.map((page) => (
                <Link
                  to={page.slug}
                  underline="none"
                  key={page.id}
                  style={{
                    marginRight: "20px",
                    textDecoration: "none",
                    color: "#00C0D9",
                  }}
                >
                  {page.name}
                </Link>
              ))}
              {utilisateur?.profileinfos.role === "Influenceur" && (
                <Link
                  to="/influenceurs"
                  underline="none"
                  style={{
                    marginRight: "20px",
                    textDecoration: "none",
                    color: "#00C0D9",
                  }}
                >
                  Influenceurs
                </Link>
              )}
            </Item>
          </Grid>
          <Grid item xs={3} md={2}>
            <Item elevation={0}>
              <img
                onClick={() => navigate("/")}
                className="logoitem"
                src={logowords}
                alt="logo"
                style={{ height: 60, marginTop: 10, cursor: "pointer" }}
              />
            </Item>
          </Grid>
          <Grid item xs={5}>
            <Item
              elevation={0}
              sx={{
                mt: 2,
                textAlign: "right",
                display: { md: "block", xs: "none" },
              }}
            >
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                {(utilisateur !== null) & (utilisateur !== undefined) ? (
                  <>
                    {utilisateur?.profileinfos.role !== "Influenceur" && (
                      <Draweror propocount={propocount} />
                    )}

                    <IconButton
                      aria-label="messages"
                      onClick={() => navigate("/messages")}
                      sx={{ color: "#00C0D9", p: "15px" }}
                    >
                      <Badge
                        badgeContent={messages?.length || 0}
                        max={5}
                        color="secondary"
                      >
                        <MailOutlineIcon />
                      </Badge>
                    </IconButton>
                    <FavorisSpace
                      counter={counter}
                      setWishcounter={setWishcounter}
                      setUpd={setUpd}
                      wishls={wishls}
                    />
                    {utilisateur.profileinfos.role === "Influenceur" ? (
                      <Affiliate />
                    ) : (
                      <></>
                    )}
                    {/* <IconButton aria-label="wishlist" sx={{ color: "#00C0D9" }}>
                      <Badge badgeContent={counter} max={5} color="secondary">
                        <FavoriteBorderIcon />
                      </Badge>
                    </IconButton> */}
                  </>
                ) : (
                  <></>
                )}
                <Badge
                  sx={{ "& .MuiBadge-badge": { top: "15px", right: "10px" } }}
                  badgeContent={orderCount || 0}
                  max={5}
                  color="secondary"
                >
                  <IconButton
                    aria-label="utilisateur"
                    sx={{ color: "#00C0D9", p: "15px" }}
                    onClick={handleClick}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <PermIdentityIcon />
                  </IconButton>
                </Badge>

                {(utilisateur === null) | (utilisateur === undefined) ? (
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        p: 2,
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem component={Link} to="/inscription/influencer">
                      <HowToRegIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                      Rejoignez en tant qu'influenceur
                    </MenuItem>
                    <Divider />
                    <MenuItem component={Link} to="/inscription/marque">
                      <BusinessCenterIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                      Rejoignez en tant que marque
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => OpenModal(true)}>
                      <LoginIcon sx={{ mr: 1, color: "#00C0D9" }} /> Connexion
                    </MenuItem>
                  </Menu>
                ) : (
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        p: 2,
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {utilisateur.profileinfos.role === "Influenceur" ? (
                      <>
                        <MenuItem
                          component={Link}
                          to="/inscription/influenceur"
                        >
                          <HowToRegIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                          Profil
                        </MenuItem>
                        <MenuItem component={Link} to="/dashboard">
                          <DashboardIcon sx={{ mr: 1, color: "#00C0D9" }} />
                          <Badge
                            badgeContent={orderCount || 0}
                            max={5}
                            sx={{ "& .MuiBadge-badge": { right: "-15px" } }}
                            color="secondary"
                          >
                            Tableau de bord
                          </Badge>
                        </MenuItem>
                        <MenuItem component={Link} to="/benefice">
                          <EuroIcon sx={{ mr: 1, color: "#00C0D9" }} /> Gain
                        </MenuItem>

                        <MenuItem
                          component={Link}
                          to="/profile/edit/influenceur"
                        >
                          <SettingsIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                          Paramètres
                        </MenuItem>
                      </>
                    ) : (
                      <>
                        <MenuItem component={Link} to="/inscription/marque">
                          <HowToRegIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                          Profil
                        </MenuItem>
                        <MenuItem component={Link} to="/dashboard">
                          <DashboardIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                          <Badge
                            badgeContent={orderCount || 0}
                            max={5}
                            sx={{ "& .MuiBadge-badge": { right: "-15px" } }}
                            color="secondary"
                          >
                            Tableau de bord
                          </Badge>
                        </MenuItem>
                        <MenuItem component={Link} to="/profile/edit/marque">
                          <SettingsIcon sx={{ mr: 1, color: "#00C0D9" }} />{" "}
                          Paramètres
                        </MenuItem>
                      </>
                    )}

                    <Divider />
                    <MenuItem onClick={handleLogout}>
                      <LoginIcon sx={{ mr: 1, color: "#00C0D9" }} /> Déconnexion
                    </MenuItem>
                  </Menu>
                )}
              </ButtonGroup>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};
export default MenuBar;
