import React from 'react'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Login from '../../Layouts/Login/Login'
import { useNavigate } from "react-router-dom";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };



const Success = () => {
  const navigate = useNavigate()
 const [progress, setProgress] = React.useState(10);
 const [isend, setIsend] = React.useState(false);
 const [utilisateur, setUtilisateur] = React.useState(null);

 React.useEffect(()=>{

  if (utilisateur !== null){
    navigate('/profile')
  }

},[utilisateur]) // eslint-disable-line react-hooks/exhaustive-deps


  React.useEffect(()=>{
    const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? setIsend(true) : prevProgress + 10));
      }, 300);

      return () => {
        clearInterval(timer);
      };

 },[]) // eslint-disable-line react-hooks/exhaustive-deps

  return (

    <Stack  sx={{inlineSize: 'fit-content', margin:'auto' }} spacing={2}>
    {isend === true ? <Login onUserChange={setUtilisateur} isopen={true}/> : <Login onUserChange={setUtilisateur} isopen={false}/>}
    <Alert variant="filled" icon={false} severity='success' sx={{

        textAlign:'center',
        p:5
    }}>
       {isend !== true ?
      <>
       <CircularProgressWithLabel color="inherit" value={progress} />
       <Typography variant='subtitle1'>Votre email est en verification</Typography>
       </>

       :<><Typography variant='h4'>Success</Typography><br />
        <Typography variant='subtitle1'>Votre email a bien été verifier — <strong> Vous puvez connectez maintenant</strong></Typography>
        </>
       }

      </Alert>
      </Stack>

  )
}

export default Success
