import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Avatars = ({ profile }) => {
  return (
    <Stack
      sx={{ display: "inline-block", textAlign: "center", width: "100%" }}
      spacing={2}
    >
      <Avatar
        sx={{ width: 80, height: 80, margin: "auto" }}
        alt={profile?.first_name + "" + profile?.last_name}
        src={global.config.infos.image_url + profile?.avatar}
      />

      <Box sx={{ mt: "5px !important" }}>
        <Typography variant="body1" sx={{ fontWeight: "900" }}>
          {profile?.first_name} {profile?.last_name}
        </Typography>
        <Typography variant="body1">{profile?.ville}, France</Typography>
      </Box>
    </Stack>
  );
};
export default Avatars;
