import React from "react";
import Countdown from "react-countdown";

import Header from "../Layouts/Header/Header";
import Footer from "../Layouts/Footer/Footer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import TuneIcon from "@mui/icons-material/Tune";
import ClientRequests from "../Api/ClientRequests";
import AuthService from "../Layouts/Login/services";
import Recap from "./Recap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "left",
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: "2px solid #0DEDA3",
  background: "#fff",
  overflow: "auto",
  height: "fit-content",
}));

const Dashboard = () => {
  const currentUser = AuthService.getCurrentUser();
  const [utilisateur, setUtilisateur] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [balance, setBalance] = React.useState(null);
  const [contrats, setContrats] = React.useState(null);
  const [clicked, setClicked] = React.useState("");
  const [type, setType] = React.useState("all");
  const [counter, setCounter] = React.useState({
    all: 0,
    actif: 0,
    completed: 0,
  });
  React.useEffect(() => {
    ClientRequests.get_token(
      "home/orderhistorybytpe/" + type + "/",
      currentUser.auth_token
    ).then((result) => {
      setContrats(result);
    });
  }, [clicked]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    window.scrollTo(0, 0);
    ClientRequests.get_token(
      "home/orderhistorybytpecounting/",
      currentUser.auth_token
    ).then((result) => {
      setCounter(result);
    });
  }, []);
  const handleChange = (event) => {
    setClicked(event.target.value);
    setType(event.target.value);
  };
  console.log(contrats);
  return (
    <React.Fragment>
      <Header onUserChange={setUtilisateur} />
      <Box
        sx={{
          marginTop: { xs: "50px", md: "150px" },
        }}
      />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Tableau de bord</Typography>
            <Item sx={{ mb: 2 }}>
              <Grid container rowSpacing={1}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small">Filter</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={type}
                    label="Filter"
                    onChange={handleChange}
                  >
                    <MenuItem value="" selected={true} disabled={true}>
                      <em>Default</em>
                    </MenuItem>
                    <MenuItem value="actif">
                      Contrats actifs ({counter?.actif || 0})
                    </MenuItem>
                    <MenuItem value="completed">
                      Contrats validés ({counter?.completed || 0})
                    </MenuItem>
                    <MenuItem value="cancelled">
                      Contrats annulés ({counter?.cancelled || 0})
                    </MenuItem>
                    <MenuItem value="all">
                      Tous les contrats ({counter?.all || 0})
                    </MenuItem>
                  </Select>
                </FormControl>

                <Grid item md={3}>
                  {/* <Button
                    variant="text"
                    startIcon={
                      <TuneIcon
                        sx={{
                          color: "#00C0D9",
                        }}
                      />
                    }
                  >
                    Filter
                  </Button> */}
                </Grid>
              </Grid>

              <Recap profile={currentUser} contrats={contrats} />
            </Item>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default Dashboard;
