import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Profile from "./Profile";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ClearIcon from "@mui/icons-material/Clear";
function isEmpty(obj) {
  if (obj === undefined) {
    return true;
  }
  if (obj?.platform?.length === 0) {
    delete obj["platform"];
  }
  if (obj?.categories?.length === 0) {
    delete obj["categories"];
  }
  if (obj?.abonnes === null) {
    delete obj["abonnes"];
  }
  return Object.keys(obj).length === 0;
}
const Category = ({
  categories,
  addwishlist,
  wishlist,
  utilisateur,
  setMore,
  more,
  setFilter,
  filter,
  setIndex,
}) => {
  const first = {
    overflow: "auto",
    whiteSpace: "nowrap",
    flex: "row",
    flexWrap: { xs: "nowrap !important", md: "wrap !important" },
  };
  const second = {
    overflow: "auto",
    whiteSpace: "nowrap",
    flex: "row",
    flexWrap: "wrap !important",
  };
  const handleClosing = () => {
    setIndex(1);
    setMore(null);
    setFilter({});
  };
  return (
    <>
      <Grid container lg={12} sx={{ mt: { xs: 1, ms: 5 }, padding: 2 }}>
        <Grid item xs={10} md={10}>
          <Box>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.5rem", fontWeight: 600 }}
            >
              {categories?.platform} ({categories?.counter})
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontSize: { xs: "0.5rem", md: "0.75rem" } }}
            >
              {categories?.description}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} md={2}>
          <Box sx={{ textAlign: "right" }}>
            {isEmpty(filter) ? (
              more === null ? (
                <IconButton
                  onClick={() => setMore(categories?.platform)}
                  sx={{ color: "#00C0D9" }}
                >
                  <DoubleArrowIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleClosing} sx={{ color: "#00C0D9" }}>
                  <ClearIcon />
                </IconButton>
              )
            ) : (
              <IconButton
                onClick={() => setFilter({})}
                sx={{ color: "#00C0D9" }}
              >
                <ClearIcon />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        lg={12}
        className="profilecontain"
        sx={(more === null) & isEmpty(filter) ? first : second}
      >
        {categories?.users?.map((user, key) => (
          <Profile
            key={key}
            wishlist={wishlist}
            AddWishlist={addwishlist}
            utilisateur={utilisateur}
            user={user}
            platform={categories?.platform}
            more={more}
            filter={isEmpty(filter)}
          />
        ))}
      </Grid>
    </>
  );
};

export default Category;
