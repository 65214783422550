import React from "react";
import Social from "../Social/Social";
import AuthService from "../../../Layouts/Login/services";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";

import SendIcon from "@mui/icons-material/Send";
import ClientRequests from "../../../Api/ClientRequests";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox social media" } };
function isEmpty(obj) {
  let counter = 0;
  if (Object.keys(obj).length > 0) {
    Object.keys(obj).map((item) => {
      if (obj[item] === true) {
        counter = 1;
      }
    });
  }
  if (counter === 0) {
    return true;
  } else {
    return false;
  }
}
const Reseau = ({ handleAddPlatform, loading, errors }) => {
  const currentUser = AuthService.getCurrentUser();
  const [platforms, setPlatform] = React.useState([]);
  const [plform, setPlForm] = React.useState({});
  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => {
    AuthService.fetchData("home/platforms/", currentUser.auth_token).then(
      (res) => {
        setPlatform(res);
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [selectedPlatforms, setSelectedPlatfoms] = React.useState({});
  const handleSelectedPlatforms = (event) => {
    setSelectedPlatfoms({
      ...selectedPlatforms,
      [event.target.name]: event.target.checked,
    });
  };
  const NextToPlatformAdd = () => {
    if (!isEmpty(selectedPlatforms)) {
      setSelected(true);
      window.scrollTo(0, 0);
    }
  };
  const PrecedentFromPlatformAdd = () => {
    setSelected(false);
    setSelectedPlatfoms({});
    window.scrollTo(0, 0);
  };
  return (
    <>
      {selected ? (
        <>
          {!isEmpty(selectedPlatforms) &&
            Object.keys(selectedPlatforms)?.map((selections) => (
              <>
                {selectedPlatforms[selections] === true && (
                  <>
                    {platforms.map((platform) => (
                      <>
                        {platform?.name === selections && (
                          <Social
                            key={platform.id}
                            platform={platform}
                            errors={errors}
                            setFormChanger={setPlForm}
                            form={plform}
                          />
                        )}
                      </>
                    ))}
                  </>
                )}
              </>
            ))}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              onClick={PrecedentFromPlatformAdd}
              variant="contained"
              sx={{
                background: "#00C0D9",
              }}
            >
              Precedent
            </Button>

            <Box sx={{ flex: "1 1 auto" }} />
            <LoadingButton
              onClick={() => handleAddPlatform(plform)}
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
              sx={{
                background: "#00C0D9",
              }}
            >
              Suivant
            </LoadingButton>
          </Box>
        </>
      ) : (
        <>
          <Typography
            sx={{ textAlign: "center", mt: 4, color: "red", fontWeight: 900 }}
          >
            Sélectionnez un ou plusieurs réseaux sociaux ci dessous
          </Typography>
          {!isEmpty(selectedPlatforms) && (
            <Box sx={{ padding: "20px", wordBreak: "break-all" }}>
              Vous avez sélectionné:
              <br />
              {Object.keys(selectedPlatforms)?.map((name) => (
                <>
                  {selectedPlatforms[name] === true && (
                    <span
                      className="tipol"
                      style={{
                        border: "1px solid #eee",
                        background: "#ddd",
                        padding: "3px",
                        marginRight: "5px",
                        marginBottom: "10px",
                        borderRadius: "20px",
                        wordBreak: "break-word",
                        lineHeight: "20px",
                      }}
                    >
                      {name}{" "}
                    </span>
                  )}
                </>
              ))}
            </Box>
          )}
          <br />
          <Box sx={{ margin: "auto", textAlign: "center" }}>
            {platforms?.map((item) => (
              <Checkbox
                {...label}
                onChange={handleSelectedPlatforms}
                name={item.name}
                sx={{
                  width: "95px",
                  height: "95px",
                  border: "1px solid #ddd",
                  borderRadius: "0px",
                  margin: "5px",
                }}
                icon={
                  <>
                    <img
                      style={{ opacity: ".3" }}
                      src={global.config.infos.image_url + item?.logo}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                      }}
                    >
                      {item.name}
                    </Box>
                  </>
                }
                checkedIcon={
                  <>
                    <img src={global.config.infos.image_url + item?.logo} />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        left: 0,
                        background: "#7474",
                      }}
                    >
                      {item.name}
                    </Box>
                  </>
                }
              />
            ))}
            {!isEmpty(selectedPlatforms) && (
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mt: 5 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  onClick={NextToPlatformAdd}
                  variant="contained"
                  sx={{
                    display: "block",
                    margin: "auto",

                    background: "#00C0D9",
                  }}
                >
                  Suivant
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Reseau;
