import * as React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SellIcon from "@mui/icons-material/Sell";
import PeopleIcon from "@mui/icons-material/People";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import ClientRequests from "../../../Api/ClientRequests";
import AuthService from "../../Login/services";
import { useConfirm } from "material-ui-confirm";
import EmailIcon from "@mui/icons-material/Email";
import moment from "moment";
import "moment/locale/fr";
const ListItems = ({ annonce, setUpdated, setSection }) => {
  const confirm = useConfirm();
  const currentUser = AuthService.getCurrentUser();
  function shorten(str, maxLen, separator = " ") {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen));
  }
  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  const [propositions, setPropositions] = React.useState([]);

  React.useEffect(() => {
    ClientRequests.get_token(
      "marque/proposition/" + annonce.id + "/",
      currentUser.auth_token
    ).then((res) => {
      setPropositions(res);
    });
  }, []);

  const handleDelete = (item) => {
    confirm({
      title: "Êtes vous sûres?",
      description: `Cet action supprimera définitivement l'annonce: ${item.title}.`,
      confirmationText: "Oui",
      cancellationText: "Annuler",
    })
      .then(() => {
        ClientRequests.delete_token(
          "marque/annonce/" + item.id + "/",
          currentUser.auth_token
        ).then((res) => {
          if (res.ok) {
            setUpdated((prev) => !prev);
          }
        });
      })
      .catch(() => console.log("Deletion cancelled."));
  };
  const handlePropositionSection = (sec, annon) => {
    setSection({ part: sec, annonce: annon.id });
    ClientRequests.patch_token(
      "marque/proposition_nonvu/",
      currentUser.auth_token,
      {
        annonce: annon.id,
      }
    );
  };
  return (
    <Grid
      container
      xs={12}
      sx={{
        border: "1px solid #0DEDA3",
        background: "#fcfafa",
        borderRadius: "15px",
        p: 2,
        mb: 1,
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={10}>
            <Typography>{annonce.title}</Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "right" }}>
            <IconButton
              sx={{
                color: "#00C0D9",
              }}
              aria-label="upload picture"
              component="span"
              onClick={() => handleDelete(annonce)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid container xs={12}>
        <Grid
          item
          md={8}
          xs={12}
          sx={{
            borderRight: { xs: "none", md: "3px solid #ddd" },
            p: 1,
          }}
        >
          <Grid container>
            <Grid item md={3} xs={6}>
              <Box>
                <SellIcon
                  sx={{
                    color: "#00C0D9",
                    fontSize: "1.2em",
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.7em",
                    position: "relative",
                    top: "-6px",
                    left: "5px",
                  }}
                >
                  Budget
                </Typography>
              </Box>
              <strong>{annonce.budget} €</strong>
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ textAlign: { md: "left", xs: "right" } }}
            >
              <Box>
                <PeopleIcon
                  sx={{
                    color: "#00C0D9",
                    fontSize: "1.2em",
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.7em",
                    position: "relative",
                    top: "-6px",
                    left: "5px",
                  }}
                >
                  Abonnés
                </Typography>
              </Box>
              <strong>+ {kFormatter(annonce.abonnee)}</strong>
            </Grid>
            <Box
              sx={{
                display: { md: "none", xs: "block" },
                height: "1px",
                mt: 1,
                mb: 1,
                background: "#dfdfdf",
                width: "100%",
              }}
            />
            <Grid item md={3} xs={6}>
              <Box>
                <LocationOnIcon
                  sx={{
                    color: "#00C0D9",
                    fontSize: "1.2em",
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.7em",
                    position: "relative",
                    top: "-6px",
                    left: "5px",
                  }}
                >
                  Lieu
                </Typography>
              </Box>
              <strong>{annonce.ville}</strong>
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
              sx={{ textAlign: { md: "left", xs: "right" } }}
            >
              <Box>
                <LanguageIcon
                  sx={{
                    color: "#00C0D9",
                    fontSize: "1.2em",
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "0.7em",
                    position: "relative",
                    top: "-6px",
                    left: "5px",
                  }}
                >
                  Cible
                </Typography>
              </Box>
              {annonce.pls.map((im) => (
                <img
                  src={global.config.infos.image_url + im.logo}
                  style={{ width: "20px", height: "20px", marginRight: "5px" }}
                />
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="body2">
              <strong>Date de publication:</strong>{" "}
              {moment(annonce.created_at).format("LL")}
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: { md: "none", xs: "block" },
            height: "1px",
            mt: 1,
            mb: 1,
            background: "#000",
            width: "100%",
          }}
        />
        <Grid item md={4} xs={12} sx={{ p: 1 }}>
          <Grid container>
            <Grid xs={12}>
              <Typography>
                <Button
                  onClick={() =>
                    handlePropositionSection("proposition", annonce)
                  }
                  variant="text"
                  startIcon={<EmailIcon sx={{ mr: 4, color: "#00C0D9" }} />}
                  size="large"
                >
                  {propositions.length} Propositions
                </Button>
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography variant="caption">
                <span>
                  <strong>Description:</strong>{" "}
                  {shorten(annonce.description, 120)}...
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListItems;
