import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Container, TextField, Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import AuthService from "../../Layouts/Login/services";
import ClientRequests from "../../Api/ClientRequests";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fr } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const Contrat = ({
  openContrat,
  handleAddContrat,
  handleCloseContrat,
  setUpdated,
  setSection,
  loading,
  setLoading,
  values,
  errors,
  setValues,
}) => {
  console.log(errors);
  const currentUser = AuthService.getCurrentUser();

  const [platforms, setPlatforms] = React.useState({});
  const handleClose = () => {
    setLoading(false);
  };

  React.useEffect(() => {
    AuthService.fetchData("home/platforms/", currentUser.auth_token).then(
      (res) => {
        setPlatforms(res);
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [date, setDate] = React.useState(
    moment(dayjs(Date.now()).toISOString()).format("YYYY-MM-DD")
  );

  const handleDateChange = (newValue) => {
    setDate(moment(newValue.toISOString()).format("YYYY-MM-DD"));
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [status, setStatus] = React.useState(null);

  const handStatusleChange = (event) => {
    let elem = false;
    if (event.target.value === "entreprise") {
      elem = true;
    }
    setValues({ ...values, tva: elem });
    setStatus(event.target.value);
  };
  console.log(status);
  return (
    <div>
      <Dialog
        open={openContrat}
        onClose={handleCloseContrat}
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Soumettre un contrat
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            sx={{
              borderRadius: "10px",
              border: "3px solid #0DEDA3",
              p: { xs: 0, md: 3 },
            }}
          >
            <Grid item xs={12}>
              <Item>
                <TextField
                  id="standard-basic"
                  label="Titre"
                  helperText={errors?.contrat?.response?.title}
                  onInput={(e) =>
                    setValues({ ...values, title: e.target.value })
                  }
                  variant="standard"
                  fullWidth
                />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <TextField
                  id="outlined-multiline-static"
                  label="Détail du contrat"
                  helperText={errors?.contrat?.response.description}
                  onInput={(e) =>
                    setValues({ ...values, description: e.target.value })
                  }
                  multiline
                  rows={4}
                  fullWidth
                />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Veuillez Préciser votre statut s'il vous plaît
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handStatusleChange}
                  >
                    <FormControlLabel
                      value="entreprise"
                      control={<Radio />}
                      label="Entreprise"
                    />
                    <FormControlLabel
                      value="particulier"
                      control={<Radio />}
                      label="Particulier/ Indépendant"
                    />
                  </RadioGroup>
                </FormControl>
              </Item>
              {status === "particulier" && (
                <Typography
                  variant="subtitle1"
                  sx={{ pl: 2, mb: 1, fontSize: "10px", color: "red" }}
                >
                  Facture sans TVA (franchise de TVA) La mention : “TVA non
                  applicable, art. 293 B du CGI”.
                </Typography>
              )}
            </Grid>

            {status !== null && (
              <Grid item md={6} xs={12}>
                <Item>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Budget{" "}
                      {status === "particulier" ? <> (TTC)</> : <>(HT)</>}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      onInput={(e) =>
                        setValues({ ...values, budget: e.target.value })
                      }
                      startAdornment={
                        <InputAdornment position="start">€</InputAdornment>
                      }
                      label="Amount"
                    />
                    <FormHelperText>
                      {errors?.contrat?.response?.budget}
                    </FormHelperText>
                  </FormControl>
                </Item>
              </Grid>
            )}

            <Grid item md={status === null ? 12 : 6} xs={12}>
              <Item>
                <TextField
                  id="standard-basic"
                  label="Lieu"
                  helperText={errors?.contrat?.response?.ville}
                  onInput={(e) =>
                    setValues({ ...values, ville: e.target.value })
                  }
                  fullWidth
                  focused
                />
              </Item>
            </Grid>
            <Grid item md={6} xs={12}>
              <Item>
                <LocalizationProvider
                  adapterLocale={fr}
                  dateAdapter={AdapterDateFns}
                >
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      label="Échéance"
                      inputFormat="dd-MM-yyyy"
                      value={date}
                      minDate={new Date()}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Item>
            </Grid>

            <Grid item md={6} xs={12}>
              <Item>
                <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  options={platforms}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cible"
                      placeholder="Ajouter.."
                      focused
                    />
                  )}
                  sx={{ width: "100%" }}
                  onChange={(event, value) =>
                    setValues({
                      ...values,
                      platforms: value.map((item) => item.id),
                    })
                  }
                />
                <FormHelperText>
                  {errors?.contrat?.response?.platforms}
                </FormHelperText>
              </Item>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseContrat()}
            sx={{
              background: "#1e92e9",
            }}
            variant="contained"
          >
            Annuler
          </Button>
          <Item
            sx={{
              textAlign: "end",
            }}
          >
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              onClick={() => handleAddContrat({ ...values, date_fin: date })}
              variant="contained"
              sx={{
                background: "#0DEDA3",
              }}
            >
              Enregistrer
            </LoadingButton>
          </Item>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Contrat;
